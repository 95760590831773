/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';

import Button from 'components/base/Button';
import paymentMethodService from 'service/paymentMethod';

interface UpdatePaymentProps {
  setOpenPayment: (show: boolean) => void;
  refetch?: any;
  selectedPaymentId?: any;
  setSelectedPaymnetId?: any;
}

const options = [
  {
    value: true,
    label: 'Активный'
  },
  {
    value: false,
    label: 'Заблокирован'
  }
];

const UpdatePayment = ({
  setOpenPayment,
  refetch,
  selectedPaymentId,
  setSelectedPaymnetId
}: UpdatePaymentProps) => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, reset } = useForm();
  const hasFetchedData = useRef(false);
  const inputRef = useRef<HTMLInputElement | any>();

  //fetch with id
  const fetchData = () => {
    if (selectedPaymentId === '') return setLoading(false);

    paymentMethodService
      .getPaymentMethodById(selectedPaymentId)
      .then((res: any) => {
        const computed = {
          status: res?.status ? res?.status : false,
          legal_entity_id: res?.legal_entity_id,
          payment_method_id: res?.payment_method_id
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedPaymentId !== '') return update(data);
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      // prettier-ignore
      status:
        data?.status === 'true' || data?.status === true
          ? true
          : data?.status === 'false' || data?.status === false
            ? false
            : false,
      legal_entity_id: data?.legal_entity_id,
      payment_method_id: data?.payment_method_id,
      id: selectedPaymentId
    };
    paymentMethodService
      .updatePaymentMethod(selectedPaymentId, updateData)
      .then((res: any) => {
        if (res) {
          refetch();
          setLoading(false);
          setOpenPayment(false);
          setSelectedPaymnetId('');
        }
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedPaymentId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedPaymentId]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Состояние</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          Изменить
        </Button>
      </Form>
    </>
  );
};

export default UpdatePayment;

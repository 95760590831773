/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const unitService = {
  getList: (params: any) => request.get('/guidebook/measure-unit', { params }),
  getUnitById: (id: any) => request.get(`/guidebook/measure-unit/${id}`),
  createUnit: (data: any) => request.post('/guidebook/measure-unit', data),
  deleteUnit: (id: any) => request.delete(`/guidebook/measure-unit/${id}`),
  updateUnit: (id: any, data: any) =>
    request.put(`/guidebook/measure-unit/${id}`, data)
};

export default unitService;

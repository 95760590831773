/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const dashboardService = {
  getCashflow: (params: any) => request.get('/dashboard/cashflow', { params }),
  getRating: (params: any) => request.get('/dashboard/rating', { params }),
  getSale: (params: any) => request.get('/dashboard/sale', { params })
};

export default dashboardService;

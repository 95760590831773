/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const activationBonusService = {
  getList: (params: any) =>
    request.get('/guidebook/bonus-activation', { params }),
  getActivaitonBonusById: (id: any) =>
    request.get(`/guidebook/bonus-activation/${id}`),
  createActivationBonus: (data: any) =>
    request.post('/guidebook/bonus-activation', data),
  deleteAcativationBonus: (id: any) =>
    request.delete(`/guidebook/bonus-activation/${id}`),
  updateAcativationBonus: (id: any, data: any) =>
    request.put(`/guidebook/bonus-activation/${id}`, data)
};

export default activationBonusService;

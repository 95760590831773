import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import RegisterSecondForm from './RegisterSecondForm';

const RegisterNextStep = () => {
  return (
    <AuthSimpleLayout>
      <RegisterSecondForm />
    </AuthSimpleLayout>
  );
};

export default RegisterNextStep;

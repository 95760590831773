/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returnItemService = {
  getList: (params: any) => request.get('/return-item', { params }),
  getBySenderId: (id: any, params: any) =>
    request.get(`product/order-item/${id}`, { params }),
  getReturnItemOutById: (id: any, params: any) =>
    request.get(`/return-item/out/${id}`, { params }),
  createReturnItem: (data: any) => request.post('/return-item', data),
  getReturnItemInById: (id: any, params: any) =>
    request.get(`/return-item/in/${id}`, { params })
};

export default returnItemService;

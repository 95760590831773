/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const movingOutService = {
  getList: (params: any) => request.get('/out-moving', { params }),
  getMovingOutById: (id: any) => request.get(`/out-moving/${id}`),
  createMovingOut: (data: any) => request.post('/out-moving', data),
  deletemovingOut: (id: any) => request.delete(`/out-moving/${id}`),
  updateMovingOut: (id: any, data: any) =>
    request.put(`/out-moving/${id}`, data)
};

export default movingOutService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntityCurrencyService = {
  getList: (params: any) => request.get('/legal-entity-currency', { params }),
  getById: (id: any) => request.get(`/legal-entity-currency/${id}`),
  update: (id: any, data: any) =>
    request.put(`/legal-entity-currency/${id}`, data)
};

export default legalEntityCurrencyService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import reportInvoiceService from 'service/reportInvoice';

export type PurchaseData = {
  product_name: string;
  barcode: string;
  category: string;
  unit: string;
  quantity: string;
  incoming_price: string;
  wholesale_price: string;
  retail_price: string;
  apply: string;
  total_price_received: string;
  total_price: string;
};

const PurchaseByInvoiceTableResult = () => {
  const { pathname } = useLocation();
  const invoiceId = pathname.split('/').pop();

  const { data } = useQuery(
    ['GET_PRODUCTS', invoiceId],
    () => {
      if (invoiceId) {
        const resData = reportInvoiceService
          .getReportProduct({
            offset: 0,
            limit: 10,
            invoice_id: invoiceId
          })
          .then((res: any) => {
            return res;
          });
        return resData;
      }
    },
    { enabled: !!invoiceId }
  );

  const productData: PurchaseData[] = useMemo(() => {
    const resultData =
      data?.products?.map((items: any) => {
        const packageQuantity = items?.package_quantity || 1;
        const quantity = String(items?.quantity ?? 0) || '0';

        let integerPart = 0;
        let numerator = 0;
        let denominator = 1;

        if (quantity?.includes('.')) {
          [numerator, denominator] = quantity.split('.').map(Number);
          integerPart = numerator; // Whole number part
          denominator = +`0.${denominator}`; // Remainder of the division
        } else {
          integerPart = +quantity;
        }
        const quantityDisplay = quantity?.includes('.')
          ? `${integerPart}/${Math?.round(+denominator * packageQuantity)}`
          : integerPart;
        return {
          product_name:
            data?.is_product === true ? items?.name : items?.nomenclature?.name,
          barcode: items?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          /* prettier-ignore */
          quantity:
                  items?.nomenclature?.measure_unit_id ===
                  '443bfff1-61e0-4057-8583-d040dc5a0454'
                    ? quantityDisplay
                    : items?.quantity ?? 0,
          quantity_in: items?.quantity_in,
          package_quantity: items?.package_quantity,
          measure_unit_id: items?.nomenclature?.measure_unit_id,
          incoming_price: items?.price_in ?? 0,
          wholesale_price: items?.price_whosale ?? 0,
          retail_price: items?.price ?? 0,
          apply: items?.is_vat === true ? 'Да' : 'Нет',
          /* prettier-ignore */
          total_price_received:
            items?.price_in && items?.quantity_in
              ? +items.price_in * +items.quantity_in
                : 0,
          /* prettier-ignore */
          total_price: items?.price && items?.quantity_in ? (+items.price * +items.quantity_in) : 0
        };
      }) ?? [];
    return resultData;
  }, [data]);

  const purchaseDataTableColumns: ColumnDef<PurchaseData>[] = [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'category',
      header: 'Категория',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Кол.во',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'incoming_price',
      header: 'Цена поступ.',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'wholesale_price',
      header: 'Цена оптовая',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'retail_price',
      header: 'Цена розничная',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'apply',
      header: 'Применить НДС',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'total_price_received',
      header: 'Итоговая цена поступл.',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'total_price',
      header: 'Итоговая цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const reprotsBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Отчеты',
      url: '/reports/purchase/invoice'
    },
    {
      label: 'Закуп',
      url: '/reports/purchase/invoice'
    },

    {
      label: 'Закуп по накладной',
      url: '/reports/purchase/invoice'
    },
    {
      label: '№ ЗАЯВКИ',
      active: true
    }
  ];
  const table = useAdvanceTable({
    data: productData,
    columns: purchaseDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sumOfAllCost = productData.reduce((acc: any, item: any) => {
    const price = +item?.retail_price || 0;
    const quantity = String(item?.quantity) || '0';

    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    // eslint-disable-next-line
    if (quantity?.includes('/')) {
      // eslint-disable-next-line
      [numerator, denominator] = quantity?.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.package_quantity || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${Math?.round(fractionalProportion)}`
      : integerPart;

    const allCost =
      item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
        ? price * +adjustedQuantity
        : price * item?.quantity;
    return acc + allCost;
  }, 0);

  const sumOfPackageAmount = productData.reduce((acc: any, item: any) => {
    const priceIn = +item?.incoming_price || 0;
    const quantity = String(item?.quantity ?? 0) || '0';
    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    if (quantity?.includes('/')) {
      [numerator, denominator] = quantity.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.package_quantity || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${Math?.round(fractionalProportion)}`
      : integerPart;

    const allCost =
      item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
        ? priceIn * +adjustedQuantity
        : priceIn * item?.quantity ?? 0;
    return acc + allCost;
  }, 0);
  return (
    <div>
      <PageBreadcrumb items={reprotsBreadcrumbItems} />

      <div>
        <h2 className="mb-5">№ ЗАЯВКИ</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <div className="footer-info">
          <div className="box">
            <p className="mb-0 text-bold">Итого сумма поступлений</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfPackageAmount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfPackageAmount)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">Итого сумма продажи</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfAllCost
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfAllCost)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">Итого разница</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfAllCost && sumOfPackageAmount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfAllCost - sumOfPackageAmount)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseByInvoiceTableResult;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AddCustomer from './create/AddCustomer';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';

export const customerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/provider'
  },
  {
    label: 'Заказчик',
    active: true
  }
];

export type CustomerData = {
  individual: string;
  activity: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  status: string;
};

export const customerDataTableColumns: ColumnDef<CustomerData>[] = [
  {
    accessorKey: 'individual',
    header: 'Физическое лицо',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'activity',
    header: 'Вид деятельности',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'country',
    header: 'Страна',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'region',
    header: 'Регион',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'area',
    header: 'Район',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'address',
    header: 'Адрес',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'phone',
    header: 'Телефон',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояние',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

const CustomerPage = () => {
  const [openCustomer, setOpenCustomer] = useState(false);

  //Get Customer
  const { data, refetch } = useQuery(['GET_CUSTOMER'], () => {
    const res = customerEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  const customerEntityData: CustomerData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          individual: items?.name,
          activity: items?.activity_kind?.name?.ru,
          country: items?.country?.name?.ru,
          region: items?.region?.name?.ru,
          area: items?.district?.name?.ru,
          address: items?.address,
          phone: items?.phone_number,
          status: items?.legal_entity_status?.name?.ru,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const table = useAdvanceTable({
    data: customerEntityData,
    columns: customerDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={customerBreadcrumbItems} />
      <div className="d-flex justify-content-between align-items-center">
        <h2>Заказчик</h2>
        <Button
          variant="primary"
          style={{ height: '48px' }}
          onClick={() => setOpenCustomer(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Новый
        </Button>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <Offcanvas
        show={openCustomer}
        onHide={() => setOpenCustomer(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Новый
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddCustomer setOpenCustomer={setOpenCustomer} refetch={refetch} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default CustomerPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import orderItemService from 'service/orderItem';
import Button from 'components/base/Button';

export type SearchData = {
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
};

interface NewAppSearchTableProps {
  setOpenSearch: (show: boolean) => void;
  orderItemData?: any;
  setOrderItemData?: any;
  senderId?: any;
  focusRef?: any;
  isAutoTab?: any;
}

const NewAppSearchTable = ({
  setOpenSearch,
  orderItemData,
  setOrderItemData,
  senderId,
  isAutoTab,
  focusRef
}: NewAppSearchTableProps) => {
  const [getedSearchData, setGetedSearchData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');

  //Get by id
  const fetchData = useMemo(() => {
    return () => {
      orderItemService
        .getByLegalEntityId(senderId, {
          offset: 0,
          limit: 20,
          name: searchValue
        })
        .then((res: any) => {
          const resCurrency = res?.currency?.name?.ru;
          const computed = res?.products?.map((items: any) => ({
            product_name: items?.nomenclature?.name,
            variation:
              items?.nomenclature?.is_variation === true ? 'Да' : 'Нет',
            barcode: items?.nomenclature?.barcode,
            units: items?.nomenclature?.measure_unit?.name?.ru,

            // this dataes need for static rendering dataes
            unit: items?.nomenclature?.measure_unit?.name?.ru,
            measure_unit_id: items?.nomenclature?.measure_unit?.id,
            amount: 0,
            full_cost: items?.price_whosale ?? 0,
            nomenclature_id: items?.nomenclature_id,
            product_id: items?.id,
            product_parent_id: items?.id,
            price: items?.price ?? 0,
            price_in: items?.price_in ?? 0,
            price_whosale: items?.price_whosale ?? 0,
            is_vat: items?.is_vat ? items?.is_vat : false,
            currency: resCurrency
          }));
          setGetedSearchData(computed ?? []);
        });
    };
  }, [searchValue]);

  const handleSelections = () => {
    const selectedDates = table
      .getSelectedRowModel()
      .rows.map(row => row.original);

    const updatedProductInvoiceData = [...orderItemData]; // Make a copy of the current state

    selectedDates.forEach(selectedDate => {
      const foundProductIndex = updatedProductInvoiceData.findIndex(
        item => item?.barcode === selectedDate.barcode
      );
      if (foundProductIndex !== -1) {
        updatedProductInvoiceData[foundProductIndex].amount += 1;
      } else {
        updatedProductInvoiceData.unshift(selectedDate);
      }
    });

    setOrderItemData(updatedProductInvoiceData);

    if (isAutoTab) {
      // when auto tab true focus to quantity
      setTimeout(() => {
        const firstProductIndex = 0;
        focusRef(`amount_${firstProductIndex}`);
      }, 500);
    }
    setOpenSearch(false);
  };

  const searchTableColumns: ColumnDef<SearchData>[] = [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'variation',
      header: 'Вариация',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: getedSearchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  useEffect(() => {
    if (!searchValue || searchValue.length > 2) {
      fetchData();
    }
  }, [searchValue]);

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <SearchBox
          placeholder="Выведите наименования товара для поиска"
          onChange={e => setSearchValue(e?.target?.value)}
          className="mb-4"
        />
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />

        <div className="d-flex justify-content-end">
          <Button
            className="mt-4"
            variant="primary"
            onClick={() => handleSelections()}
          >
            Выбрать
          </Button>
        </div>
      </div>
    </AdvanceTableProvider>
  );
};

export default NewAppSearchTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import categoryService from 'service/category';
import Button from 'components/base/Button';
import './styles.scss';

interface LostProfitCategoryProps {
  setOpenCategoryModal: (show: boolean) => void;
  setSelectedCategoryData?: any;
}

const LostProfitCategory = ({
  setOpenCategoryModal,
  setSelectedCategoryData
}: LostProfitCategoryProps) => {
  //GET Category to options
  const { data: categoryData } = useQuery(['GET_CATEGORY'], async () => {
    const res = await categoryService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.categories);

    return res;
  });

  const buildMenu = (data: any, parentId = undefined) => {
    const menu: any[] = [];
    data.forEach((item: any) => {
      if (item?.parent_id === parentId) {
        const children = buildMenu(data, item?.id);
        const menuItem = {
          id: item?.id,
          label: item?.name?.ru || '', // assuming you want the Russian names
          submenu: children?.length > 0 ? children : undefined,
          showSubmenu: false,
          checked: false
        };

        menu.push(menuItem);
      }
    });

    return menu;
  };

  const [menuData, setMenuData] = useState<any[]>([]);
  const [selectedData, setSelectedData] = useState('');

  useEffect(() => {
    if (categoryData) {
      setMenuData(buildMenu(categoryData));
    }
  }, [categoryData]);

  const toggleSubMenu = (e: any, categoryId: any) => {
    e.stopPropagation();

    const submenu = e.target.querySelector('ul');

    if (!submenu) return;

    if (submenu.style.display === 'none' || !submenu.style.display) {
      submenu.style.display = 'block';
    } else {
      submenu.style.display = 'none';
    }

    const updatedMenuData = menuData.map(item => {
      if (item.id === categoryId && item.submenu) {
        item.showSubmenu = !item.showSubmenu;
      }
      return item;
    });

    setMenuData(updatedMenuData);
  };

  const handleCheckboxChange = (id: any) => {
    const updateMenuDataRecursively = (menuData: any[]) => {
      return menuData.map(item => {
        if (item.id === id) {
          item.checked = true;
        } else {
          item.checked = false;
        }

        if (item.submenu) {
          item.submenu = updateMenuDataRecursively(item.submenu);
        }

        return item;
      });
    };

    const updatedMenuData = updateMenuDataRecursively(menuData);

    setMenuData(updatedMenuData);
    setSelectedData(id);
  };

  const renderSubMenu = (subMenu: any) => {
    return (
      <ul className="submenu">
        {subMenu.map((subItem: any, index: any) => (
          <li key={index} onClick={e => toggleSubMenu(e, subItem.id)}>
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={subItem.checked}
                onChange={() => handleCheckboxChange(subItem.id)}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              />
              {subItem.label}
            </label>
            {subItem.submenu ? (
              <>
                {subItem.showSubmenu ? (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                )}
                {renderSubMenu(subItem.submenu)}
              </>
            ) : null}
          </li>
        ))}
      </ul>
    );
  };

  const handleSubmit = () => {
    if (!selectedData) return;

    const findSelectedItem = (items: any[]): any => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === selectedData) {
          return items[i];
        }
        if (items[i].submenu) {
          const foundItem = findSelectedItem(items[i].submenu);
          if (foundItem) return foundItem;
        }
      }
      return null;
    };

    const selectedCategory = findSelectedItem(menuData);

    if (selectedCategory) {
      const { id, label } = selectedCategory;
      const selectedObject = { id, label };
      setSelectedCategoryData(selectedObject);
      setOpenCategoryModal(false);
      // Do something with selectedObject
    } else {
      console.log('Selected category not found');
    }
  };

  return (
    <div>
      <ul className="main-cat">
        {menuData.map((item, index) => (
          <li key={index} onClick={e => toggleSubMenu(e, item.id)}>
            <label>
              <input
                className="form-check-input"
                type="checkbox"
                checked={item.checked}
                onChange={() => handleCheckboxChange(item.id)}
                style={{ marginRight: '10px', cursor: 'pointer' }}
              />
              {item.label}
            </label>
            {item.submenu && (
              <>
                {item.showSubmenu ? (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    transform={{ size: 14 }}
                    style={{ marginLeft: '5px' }}
                  />
                )}
                {renderSubMenu(item.submenu)}
              </>
            )}
          </li>
        ))}
      </ul>

      <Button
        onClick={() => handleSubmit()}
        className="w-100 mt-2"
        variant="primary"
      >
        Применить
      </Button>
    </div>
  );
};

export default LostProfitCategory;

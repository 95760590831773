/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

// import UpdateAmount from './UpdateAmount';
import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import orderItemService from 'service/orderItem';

export type NewApplicationsInfo = {
  product_name: string;
  barcode: string;
  unit: string;
  currency: string;
  current_balance: string;
  amount: string;
  full_cost: string;
};

interface NewApplicationsTableProps {
  orderItemData?: any;
  setOrderItemData?: any;
  newOrderId?: any;
  setRef?: any;
}

const NewApplicationsTable = ({
  orderItemData,
  setOrderItemData,
  newOrderId,
  setRef
}: NewApplicationsTableProps) => {
  // const [openAmount, setOpenAmount] = useState(false);

  const { data } = useQuery(
    ['GET_ORDER_ITEMS', newOrderId],
    () => {
      if (newOrderId) {
        const res = orderItemService
          .getList({
            offset: 0,
            limit: 100,
            order_id: newOrderId
          })
          .then((res: any) => {
            return res?.order_items;
          });
        return res;
      }
    },
    { enabled: !!newOrderId }
  );

  const generateData: NewApplicationsInfo[] = useMemo(() => {
    const newData =
      data?.map((items: any) => {
        return {
          product_name: items?.nomenclature?.name,
          barcode: items?.nomenclature?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit.id,
          amount: items?.quantity_asked ?? 1,
          full_cost: items?.price_wholesale ?? 0,
          nomenclature_id: items?.nomenclature_id,
          product_id: items?.product_id,
          product_parent_id: items?.product_id,
          price: items?.price ?? 0,
          price_in: items?.price_in ?? 0,
          price_whosale: items?.price_whosale ?? 0,
          is_vat: items?.is_vat ? items?.is_vat : false
        };
      }) ?? [];

    setOrderItemData([...orderItemData, ...newData]);

    return newData;
  }, [data]);

  console.log('generateData', generateData);

  const handleRemoveProductItem = (indexToRemove: number) => {
    setOrderItemData(
      orderItemData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const newApplicationsDataTableColumns: ColumnDef<NewApplicationsInfo>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    },
    {
      accessorKey: 'product_name',
      header: 'Наименования товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    // {
    //   accessorKey: 'current_balance',
    //   header: 'Текущий остаток',
    //   meta: {
    //     cellProps: { className: 'text-900' }
    //   }
    // },

    {
      accessorKey: 'amount',
      header: 'Количество',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const updatedItems = orderItemData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });

          setOrderItemData(updatedItems);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="amount"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                ref={setRef(`amount_${rowData.row.id}`)}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'full_cost',
      header: 'Цена оптовая',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: orderItemData,
    columns: newApplicationsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default NewApplicationsTable;

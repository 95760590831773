import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const clientsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Пользователи',
    active: true
  }
];

// export const clients: ClientsType[] = [
//   {
//     phone: '+998932222222',
//     legal_name: 'Dealer Free',
//     access_type: 'Администратор',
//     second_name: 'Admin',
//     first_name: 'Dealer',
//     patronymic: 'A',
//     country: "O'zbekiston Respublikasi",
//     region: 'Toshkent',
//     area: 'Sergili',
//     gender: 'Мужской',
//     date_birth: '23.01.1983',
//     status: 'Активный'
//   },
//   {
//     phone: '+998932222222',
//     legal_name: 'Dealer Free',
//     access_type: 'Администратор',
//     second_name: 'Admin',
//     first_name: 'Dealer',
//     patronymic: 'A',
//     country: "O'zbekiston Respublikasi",
//     region: 'Toshkent',
//     area: 'Sergili',
//     gender: 'Мужской',
//     date_birth: '23.01.1983',
//     status: 'Активный'
//   },
//   {
//     phone: '+998932222222',
//     legal_name: 'Dealer Free',
//     access_type: 'Администратор',
//     second_name: 'Admin',
//     first_name: 'Dealer',
//     patronymic: 'A',
//     country: "O'zbekiston Respublikasi",
//     region: 'Toshkent',
//     area: 'Sergili',
//     gender: 'Мужской',
//     date_birth: '23.01.1983',
//     status: 'Активный'
//   }
// ];

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useEffect } from 'react';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import providerService from 'service/provider';
import SuppliersMaturityDateHistory from './SuppliersMaturityDateHistory';

export type SuppliersMaturityData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  maturity_date: string;
  status: string;
  guid: string;
  color: string;
};

interface SuppliersMaturityDateTableProps {
  dateFrom?: any;
  dateTo?: any;
  setDateFrom?: any;
  setDateTo?: any;
}

const SuppliersMaturityDateTable = ({
  dateFrom,
  dateTo,
  setDateFrom,
  setDateTo
}: SuppliersMaturityDateTableProps) => {
  const [openSettelementHistory, setOpenSettelementHistory] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState('');

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_PROVIDER_CHECK'],
    () => {
      const res = providerService
        .getListCheck({
          offset: 0,
          limit: 100,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.debit_credit_list);
      return res;
    },
    {
      enabled: !!dateFrom || !!adjustedDateTo
    }
  );

  const resultData: SuppliersMaturityData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.ru,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          currency: items?.currency?.name?.ru,
          maturity_date:
            items?.maturity_date === 0 || !items?.maturity_date
              ? ''
              : new Date(items?.maturity_date)
                  .toLocaleDateString('en-GB')
                  .replace(/\//g, '-'),
          color: items?.color,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const settelementDataTableColumns: ColumnDef<SuppliersMaturityData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              setOpenSettelementHistory(true);
              setSelectedProviderId(rowData?.row?.original?.guid);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faClockRotateLeft}
              transform={{ size: 20 }}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_provider',
      header: 'Наименование поставщика ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: 'Дебет',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: 'Кредит',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: 'Остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'maturity_date',
      header: 'Дата погашения',
      cell: rowData => (
        <div>
          {/* prettier-ignore  */}
          <p
            style={{marginBottom: '0',
              backgroundColor: `${rowData?.row?.original?.maturity_date && rowData?.row?.original?.color}`,
              borderRadius: '6px',
              padding: '8px 12px',
              fontWeight: '600'
            }}
          >
            {rowData?.row?.original?.maturity_date}
          </p>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900 text-center' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: resultData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo]);

  return (
    <div>
      <div className="d-flex gap-2">
        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder="Дата с"
            />
            <label htmlFor="floatingInputCustom">Дата с</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder="Дата по"
            />
            <label htmlFor="floatingInputCustom">Дата по</label>
          </Form.Floating>
        </Form.Group>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>

        <Modal
          show={openSettelementHistory}
          onHide={() => {
            setOpenSettelementHistory(false);
            setSelectedProviderId('');
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              История взаиморасчет
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SuppliersMaturityDateHistory
              selectedProviderId={selectedProviderId}
            />
          </Modal.Body>
        </Modal>
      </AdvanceTableProvider>
    </div>
  );
};

export default SuppliersMaturityDateTable;

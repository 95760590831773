/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import Button from 'components/base/Button';
import AddAmountTable from './AddAmountTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { addamountBreadcrumbItems } from 'data/addamount';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { addAmountTableColumns } from './AddAmountTable';
import walletService from 'service/wallet';
import CreateAddAmount from './CreateAddAmount';

export type AddAmountType = {
  amount: string;
  note: string;
  date: string;
  currency_id: string;
  wallet_id: string;
};

const AddAmount = () => {
  const { pathname } = useLocation();
  const addId = pathname.split('/').pop();

  const [modalShow, setModalShow] = useState(false);

  //Get NDS
  const { data, refetch } = useQuery(
    ['GET_WALLET_SETTLEMENT'],
    () => {
      if (addId) {
        const res = walletService
          .getWalletSettlementById(addId)
          .then((res: any) => res?.wallet_settlements);
        return res;
      }
    },
    { enabled: !!addId }
  );

  const walletSettlementData: AddAmountType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          amount: items?.balance,
          note: items?.note,
          currency_id: items?.currency?.id,
          wallet_id: items?.wallet_id,
          /* prettier-ignore */
          date: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', '')
        };
      }) ?? []
    );
  }, [data, addId]);

  const table = useAdvanceTable({
    data: walletSettlementData,
    columns: addAmountTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={addamountBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Добавить сумму</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder="Поиск Добавить сумму"
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setModalShow(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Добавить
                </Button>
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AddAmountTable />
          </div>
        </AdvanceTableProvider>
      </div>

      <Offcanvas
        show={modalShow}
        onHide={() => setModalShow(false)}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Добавить сумму
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateAddAmount
            refetch={refetch}
            setModalShow={setModalShow}
            addId={addId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default AddAmount;

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const usePreventBackOnRoute = (targetRoute: string) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      if (location.pathname === targetRoute) {
        event.preventDefault();
        navigate(targetRoute, { replace: true });
      }
    };

    if (location.pathname === targetRoute) {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [location.pathname, targetRoute, navigate]);
};

export default usePreventBackOnRoute;

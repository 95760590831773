/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const paymentBonusService = {
  getList: (params: any) => request.get('/guidebook/bonus-payment', { params }),
  getPaymentBonusById: (id: any) =>
    request.get(`/guidebook/bonus-payment/${id}`),
  createPaymentBonus: (data: any) =>
    request.post('/guidebook/bonus-payment', data),
  deletePaymentBonus: (id: any) =>
    request.delete(`/guidebook/bonus-payment/${id}`),
  updatePaymentBonus: (id: any, data: any) =>
    request.put(`/guidebook/bonus-payment/${id}`, data)
};

export default paymentBonusService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const applicationBonusService = {
  getList: (params: any) =>
    request.get('/guidebook/bonus-application', { params }),
  getApplicationBonusById: (id: any) =>
    request.get(`/guidebook/bonus-application/${id}`),
  createApplicationBonus: (data: any) =>
    request.post('/guidebook/bonus-application', data),
  deleteApplicationBonus: (id: any) =>
    request.delete(`/guidebook/bonus-application/${id}`),
  updateApplicationBonus: (id: any, data: any) =>
    request.put(`/guidebook/bonus-application/${id}`, data)
};

export default applicationBonusService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const cashboxService = {
  getList: (params: any) => request.get('/cashbox', { params }),
  getReceipt: (params: any) => request.get('/receipt', { params }),
  getReceiptById: (id: any) => request.get(`/receipt/${id}`),
  getXList: (id: any, currency_id: any, data: any) =>
    request.get(`/report/x/${id}/${currency_id}`, data),
  getListBalance: (id: any, params: any) =>
    request.get(`/cashbox/${id}/balance`, { params }),
  getCashboxById: (id: any) => request.get(`/cashbox/${id}`),
  createCashbox: (data: any) => request.post('/cashbox', data),
  deleteCashbox: (id: any) => request.delete(`/cashbox/${id}`),
  updateCashbox: (id: any, data: any) => request.put(`/cashbox/${id}`, data)
};

export default cashboxService;

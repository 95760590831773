import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';
import { CSSProperties, forwardRef } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';

export interface SearchBoxProps extends FormControlProps {
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  formClassName?: string;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
}

const SearchBox = forwardRef<HTMLInputElement, SearchBoxProps>(
  (
    {
      placeholder = 'Search',
      size,
      className,
      inputClassName,
      formClassName,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={classNames('search-box', className)} style={style}>
        <form className={classNames('position-relative', formClassName)}>
          <Form.Floating>
            <Form.Control
              ref={ref}
              type="search"
              placeholder={placeholder}
              className={classNames('search-input search', inputClassName)}
              style={{ paddingLeft: '2.5rem' }}
              size={size}
              {...rest}
            />
            <FontAwesomeIcon icon={faSearch} className="search-box-icon" />
            <label
              htmlFor="floatingInputCustom"
              style={{ paddingLeft: '2.5rem' }}
            >
              {placeholder}
            </label>
          </Form.Floating>
        </form>
      </div>
    );
  }
);

export default SearchBox;

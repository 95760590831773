import { useRef } from 'react';

const useFocusRefs = () => {
  const refs = useRef<{ [key: string]: React.RefObject<HTMLInputElement> }>(
    {}
  ).current;

  const setRef = (key: string) => {
    if (!refs[key]) {
      refs[key] = useRef<HTMLInputElement>(null);
    }
    return refs[key];
  };

  const focusRef = (key: string) => {
    refs[key]?.current?.focus();
    refs[key]?.current?.select();
  };

  console.log('refs', refs);
  return { setRef, focusRef };
};

export default useFocusRefs;

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const addamountBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/directories/stock'
  },
  {
    label: 'Кошелек',
    url: '/directories/wallet'
  },
  {
    label: 'Добавить сумму',
    active: true
  }
];

export type AddAmount = {
  amount: string;
  note: string;
  date: string;
};

export const addAmount: AddAmount[] = [
  {
    amount: '5 000 000',
    note: 'Test',
    date: '07.02.2024'
  }
];

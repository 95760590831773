/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const categoryService = {
  getList: (params: any) => request.get('/guidebook/category', { params }),
  getCategoryById: (id: any) => request.get(`/guidebook/category/${id}`),
  createCategory: (data: any) => request.post('/guidebook/category', data),
  deleteCategory: (id: any) => request.delete(`/guidebook/category/${id}`),
  updateCategory: (id: any, data: any) =>
    request.put(`/guidebook/category/${id}`, data)
};

export default categoryService;

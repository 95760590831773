/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState } from 'react';
import { useQuery } from 'react-query';

import legalEntityService from 'service/legalEntity';
import warehouseService from 'service/warehouse';
import legalEntityCurrencyService from 'service/legalEntityCurrency';
import Button from 'components/base/Button';
import FeatherIcon from 'feather-icons-react';

import styles from './styles.module.scss';

interface ABCAnalysisFilterProps {
  setSelectedAnalysis: (data: string) => void;
  setDateFrom?: any;
  setDateTo?: any;
  dateFrom?: any;
  dateTo?: any;
  setOptionSenderId?: any;
  setWarehouseId?: any;
  setCurrencyId?: any;
}

const analysisOptions = [
  {
    value: 'sales_amount',
    label: 'По сумме продаж'
  },
  {
    value: 'quantity_goods',
    label: 'По количество товара'
  }
];

const ABCAnalysisFilter = ({
  setSelectedAnalysis,
  setDateFrom,
  setDateTo,
  dateFrom,
  dateTo,
  setOptionSenderId,
  setWarehouseId,
  setCurrencyId
}: ABCAnalysisFilterProps) => {
  const { control, setValue, watch } = useForm();

  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);
  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET Warehouse Entity to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Currency Entity to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.ru
        }));
        setCurrencyOption(options);
      });
  });

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDate = new Date(date[0]);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    } else {
      const startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0); // Set time to 00:00
      const startDateInMillis = startDate.getTime();
      setDateFrom(startDateInMillis);
    }
  };

  const handleEndDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const endDateInMillis = date[0]?.getTime();
      setDateTo(endDateInMillis);
    } else {
      const endDateInMillis = date.getTime();
      setDateTo(endDateInMillis);
    }
  };
  return (
    <>
      <Form className={styles.abcRows}>
        <Form.Group>
          <div className="position-relative">
            <Controller
              name="provider"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = legalEntityOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setOptionSenderId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Поставщик</label>
                  {watch('provider') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        right: '25%',
                        top: '32%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setOptionSenderId('');
                        setValue('provider', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="position-relative">
            <Controller
              name="stock"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = warehouseOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setWarehouseId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {warehouseOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Склад</label>
                  {watch('stock') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        right: '25%',
                        top: '32%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setWarehouseId('');
                        setValue('stock', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="position-relative">
            <Controller
              name="analysis_type"
              control={control}
              defaultValue="sales_amount"
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = analysisOptions.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setSelectedAnalysis(selectedOption?.value || '');
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {analysisOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Тип анализа</label>
                  {/* {watch('analysis_type') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        right: '25%',
                        top: '32%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setSelectedAnalysis('sales_amount');
                        setValue('analysis_type', 'sales_amount');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )} */}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group>
          <div className="position-relative">
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select
                    {...field}
                    onChange={(e: any) => {
                      const selectedOption = currencyOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        setCurrencyId(selectedOption?.value);
                      }
                      field.onChange(e);
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {currencyOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Валюта</label>
                  {watch('currency') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        right: '25%',
                        top: '32%',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setCurrencyId('');
                        setValue('currency', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateFrom}
              onChange={handleStartDateChange}
              placeholder="Дата с"
            />
            <label htmlFor="floatingInputCustom">Дата с</label>
          </Form.Floating>
        </Form.Group>

        <Form.Group className="md-4">
          <Form.Floating>
            <Flatpickr
              className={classNames('form-control')}
              options={{
                nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                locale: {
                  firstDayOfWeek: 1
                },
                monthSelectorType: 'static',
                onDayCreate: (...[, , , dayElem]) => {
                  if (
                    dayElem.dateObj.getDay() === 5 ||
                    dayElem.dateObj.getDay() === 6
                  ) {
                    dayElem.className += ' weekend-days';
                  }
                },
                dateFormat: 'M j, Y',
                disableMobile: true
              }}
              value={dateTo}
              onChange={handleEndDateChange}
              placeholder="Дата по"
            />
            <label htmlFor="floatingInputCustom">Дата по</label>
          </Form.Floating>
        </Form.Group>

        {/* <Form.Group className={styles.abcBtnGroup}>
          <Button variant="primary">
            <ExcelIcon />
          </Button>

          <Button variant="primary">Применить</Button>
        </Form.Group> */}
      </Form>
    </>
  );
};

export default ABCAnalysisFilter;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useRef } from 'react';

import Button from 'components/base/Button';
import bonusService from 'service/bonus';
import { showAlert } from 'store/alert/alert.thunk';

interface AddBonusModalProps {
  setOpenBonus: (show: boolean) => void;
  selectedBonusId?: any;
  refetch?: any;
  setSelectedBonusId?: any;
}

const statusOption = [
  {
    value: true,
    label: 'Активный'
  },
  {
    value: false,
    label: 'Заблокирован'
  }
];

const AddBonusModal = ({
  setOpenBonus,
  selectedBonusId,
  refetch,
  setSelectedBonusId
}: AddBonusModalProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const hasFetchedData = useRef(false);
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    if (selectedBonusId === '') return setLoading(false);

    bonusService
      .getBonusById(selectedBonusId)
      .then((res: any) => {
        const computed = {
          bonus_name: res?.name,
          date_start: res?.date_from,
          date_finish: res?.date_to,
          status: res?.status,
          is_system_approved: res?.is_system_approved
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedBonusId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createData = {
      name: data?.bonus_name,
      date_from: Date.parse(data?.date_start),
      date_to: Date.parse(data?.date_finish),
      status: data?.status === 'true' || data?.status === true ? true : false
    };

    bonusService.createBonus(createData).then((res: any) => {
      if (res) {
        setOpenBonus(false);
        setLoading(false);
        dispatch(
          showAlert({
            title: 'Бонусы успешно добавлен!',
            type: 'success'
          })
        );
        refetch();
        setSelectedBonusId('');
      }
    });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.bonus_name,
      date_from:
        typeof data?.date_start === 'number'
          ? data?.date_start
          : Date.parse(data?.date_start),
      date_to:
        typeof data?.date_finish === 'number'
          ? data?.date_finish
          : Date.parse(data?.date_finish),
      status: data?.status === 'true' || data?.status === true ? true : false,
      is_system_approved: data?.is_system_approved
        ? data?.is_system_approved
        : false,
      id: selectedBonusId
    };

    bonusService.updateBonus(selectedBonusId, updateData).finally(() => {
      setLoading(false);
      setOpenBonus(false);
      refetch();
      setSelectedBonusId('');
    });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  useEffect(() => {
    if (selectedBonusId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [selectedBonusId]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col>
            <Controller
              name="bonus_name"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    disabled={selectedBonusId ? true : false}
                    ref={inputRef}
                    type="text"
                    placeholder="Наименования бонуса"
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.bonus_name}
                  />
                  <label htmlFor="floatingInputCustom">
                    Наименования бонуса
                  </label>

                  {errors?.bonus_name && (
                    <Form.Control.Feedback type="invalid">
                      Обязательное поле
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="flatpickr-input-container">
              <Controller
                name="date_start"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: {
                          firstDayOfWeek: 1
                        },
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'd-m-Y',
                        enableTime: false,
                        disableMobile: true
                      }}
                      placeholder="Дата начала"
                      {...field}
                    />
                    <label htmlFor="startDatepicker" className="ps-6">
                      Дата начала
                    </label>
                  </Form.Floating>
                )}
              />
              <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="flatpickr-input-container">
              <Controller
                name="date_finish"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Flatpickr
                      className={classNames('form-control')}
                      options={{
                        nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                        prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                        locale: {
                          firstDayOfWeek: 1
                        },
                        monthSelectorType: 'static',
                        onDayCreate: (...[, , , dayElem]) => {
                          if (
                            dayElem.dateObj.getDay() === 5 ||
                            dayElem.dateObj.getDay() === 6
                          ) {
                            dayElem.className += ' weekend-days';
                          }
                        },
                        dateFormat: 'd-m-Y',
                        enableTime: false,
                        disableMobile: true
                      }}
                      placeholder="Дата окончания"
                      {...field}
                    />
                    <label htmlFor="startDatepicker" className="ps-6">
                      Дата окончания
                    </label>
                  </Form.Floating>
                )}
              />
              <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {statusOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Состояние</label>
                  </Form.Floating>
                )}
              />
              {errors?.status && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  Обязательное поле
                </span>
              )}
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
          loadingPosition="start"
        >
          Сохранить
        </Button>
      </Form>
    </>
  );
};

export default AddBonusModal;

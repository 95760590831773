/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const expenseSourceService = {
  getList: (params: any) =>
    request.get('/guidebook/expense-source', { params }),
  getExpenseSourceById: (id: any) =>
    request.get(`/guidebook/expense-source/${id}`),
  createExpenseSource: (data: any) =>
    request.post('/guidebook/expense-source', data),
  deleteExpenseSource: (id: any) =>
    request.delete(`/guidebook/expense-source/${id}`),
  updateExpenseSource: (id: any, data: any) =>
    request.put(`/guidebook/expense-source/${id}`, data)
};

export default expenseSourceService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilCalendarAlt } from '@iconscout/react-unicons';

import legalEntityService from 'service/legalEntity';
import warehouseService from 'service/warehouse';
import paymentOrderService from 'service/paymentOrder';
import invoiceService from 'service/invoice';
import { showAlert } from 'store/alert/alert.thunk';
import Button from 'components/base/Button';
import legalEntityCurrencyService from 'service/legalEntityCurrency';

const CreateNewPurchase = () => {
  const [disabledDate, setDisabledDate] = useState(false);
  const schema = yup.object().shape({
    provider: yup.string().required('Обязательное поле'),
    contract_number: yup.string().required('Обязательное поле'),
    date_agreement: yup?.date().required('Обязательное поле'),
    stock: yup.string().required('Обязательное поле'),
    currency: yup.string().required('Обязательное поле'),
    payment_order: yup.string().required('Обязательное поле'),
    date_maturity: !disabledDate
      ? yup?.date()
      : yup?.date().required('Обязательное поле')
  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const inputRef = useRef<HTMLInputElement | any>();

  const [legalEntityOption, setLegalEntityOption] = useState<any>([
    { label: '', value: '', is_vat_payer: false, currency_id: '' }
  ]);
  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [currencyOption, setCurrencyOption] = useState<any>([]);
  const [paymentOrderOption, setPaymentOrderOption] = useState<any>([
    { label: '', value: '', is_count_debit_credit: false }
  ]);
  const [loading, setLoading] = useState(false);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name,
          is_vat_payer: option?.is_vat_payer ?? false,
          currency_id: option?.currency_id
        }));
        setLegalEntityOption(options);
      });
  });
  //GET Warehouse Entity to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });
  //GET Currency to options
  useQuery(['GET_CURRENCY'], async () => {
    await legalEntityCurrencyService
      .getList({ offset: 0, limit: 100, status: true })
      .then((res: any) => {
        const options = res?.legal_entity_currencies?.map((option: any) => ({
          value: option?.currency?.id,
          label: option?.currency?.name?.ru
        }));
        setCurrencyOption(options);
      });
  });
  //GET Payment order to options
  useQuery(['GET_PAYMENT_ORDER'], async () => {
    await paymentOrderService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.payment_orders?.map((option: any) => ({
          value: option.id,
          label: option?.name?.ru,
          is_count_debit_credit: option?.is_count_debit_credit
        }));
        setPaymentOrderOption(options);
      });
  });
  const onSubmit: SubmitHandler<FieldValues> = data => {
    const createdData = {
      cashbox_id: cashboxId,
      currency_id: data?.currency,
      invoice_date: Date.parse(data?.date_agreement),
      invoice_number: data?.contract_number,
      invoice_type_id: 'aadeb1b2-b23c-4aca-9b89-5dc4229ffa11',
      maturity_date: data?.date_maturity ? Date.parse(data?.date_maturity) : 0,
      payment_order_id: data?.payment_order,
      supplier_id: data?.provider,
      warehouse_id: data?.stock
    };
    if (cashboxId !== '') {
      setLoading(true);
      invoiceService.createInvoice(createdData).then((res: any) => {
        setLoading(false);

        dispatch(
          showAlert({
            title: 'Закуп успешно добавлено!',
            type: 'success'
          })
        );
        navigate(
          `/purchase/create/create-add/${res?.id}?currency_id=${res?.currency_id}`
        );
      });
    } else {
      dispatch(showAlert({ title: 'Смена еще не открыта' }));
    }
  };

  function formatDate(date: any) {
    return date
      .toISOString()
      .replace(/[-:]/g, '_')
      .slice(0, 16)
      .replace('T', '_');
  }

  useEffect(() => {
    const today = new Date();
    setValue('date_agreement', today);
  }, [setValue]);

  useEffect(() => {
    if (warehouseOption?.length === 1) {
      setValue('stock', warehouseOption[0].value);
    }
    if (currencyOption?.length === 1) {
      setValue('currency', currencyOption[0].value);
    }

    if (legalEntityOption?.length === 1) {
      setValue('provider', legalEntityOption[0].value);
    }
  }, [warehouseOption, currencyOption, legalEntityOption, setValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    const date = new Date();
    const formattedDate = formatDate(date);

    setValue('contract_number', formattedDate);
  }, []);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <div className="d-flex flex-column gap-2">
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="provider"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      ref={inputRef}
                      isInvalid={!!errors.provider}
                      onChange={(e: any) => {
                        const selectedOption = legalEntityOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue('currency', selectedOption.currency_id);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {legalEntityOption?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">Поставщик</label>
                  </Form.Floating>
                )}
              />
              {errors?.provider?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.provider?.message}
                </span>
              )}
            </div>
          </Form.Group>
          <Form.Floating>
            <Form.Control
              {...register('contract_number')}
              type="text"
              placeholder="Номер договора"
              onFocus={e => e.target.select()}
              isInvalid={!!errors.contract_number}
              autoComplete="off"
            />
            <label htmlFor="floatingInputCustom">Номер договора</label>
            {errors.contract_number && (
              <Form.Control.Feedback type="invalid">
                {errors.contract_number.message}
              </Form.Control.Feedback>
            )}
          </Form.Floating>
          <Form.Group className="flatpickr-input-container">
            <Controller
              name="date_agreement"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Flatpickr
                    className={classNames('form-control', {
                      error: errors.date_agreement?.message
                    })}
                    options={{
                      nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                      prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                      locale: {
                        firstDayOfWeek: 1
                      },
                      monthSelectorType: 'static',
                      onDayCreate: (...[, , , dayElem]) => {
                        if (
                          dayElem.dateObj.getDay() === 5 ||
                          dayElem.dateObj.getDay() === 6
                        ) {
                          dayElem.className += ' weekend-days';
                        }
                      },
                      dateFormat: 'M j, Y',
                      disableMobile: true
                    }}
                    placeholder="Дата договора"
                    {...field}
                  />
                  <label htmlFor="startDatepicker" className="ps-6">
                    Дата договора
                  </label>
                  <UilCalendarAlt
                    className="flatpickr-icon text-700"
                    size={16}
                  />
                </Form.Floating>
              )}
            />
            {errors?.date_agreement?.message && (
              <span
                style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
              >
                Обязательное поле
              </span>
            )}
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="stock"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.stock}>
                      <option className="d-none" value=""></option>
                      {warehouseOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">Склад</label>
                  </Form.Floating>
                )}
              />
              {errors?.stock?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.stock?.message}
                </span>
              )}
            </div>
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.currency}>
                      <option className="d-none" value=""></option>
                      {currencyOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">Валюта</label>
                  </Form.Floating>
                )}
              />
              {errors?.currency?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.currency?.message}
                </span>
              )}
            </div>
          </Form.Group>
          <Form.Group>
            <div className="react-select-container">
              <Controller
                name="payment_order"
                control={control}
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.currency}
                      onChange={(e: any) => {
                        const selectedOption = paymentOrderOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          selectedOption?.is_count_debit_credit
                            ? setDisabledDate(true)
                            : setDisabledDate(false);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {paymentOrderOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">Порядок оплаты</label>
                  </Form.Floating>
                )}
              />
              {errors?.payment_order?.message && (
                <span
                  style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
                >
                  {errors?.payment_order?.message}
                </span>
              )}
            </div>
          </Form.Group>
          <Form.Group className="flatpickr-input-container">
            <Controller
              name="date_maturity"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Flatpickr
                    disabled={!disabledDate}
                    className={classNames('form-control', {
                      error: errors.date_maturity?.message
                    })}
                    options={{
                      nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                      prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                      locale: {
                        firstDayOfWeek: 1
                      },
                      monthSelectorType: 'static',
                      onDayCreate: (...[, , , dayElem]) => {
                        if (
                          dayElem.dateObj.getDay() === 5 ||
                          dayElem.dateObj.getDay() === 6
                        ) {
                          dayElem.className += ' weekend-days';
                        }
                      },
                      dateFormat: 'M j, Y',
                      disableMobile: true
                    }}
                    placeholder="Дата погашения"
                    {...field}
                  />
                  <label htmlFor="startDatepicker" className="ps-6">
                    Дата погашения
                  </label>
                  <UilCalendarAlt
                    className="flatpickr-icon text-700"
                    size={16}
                  />
                </Form.Floating>
              )}
            />
            {errors?.date_maturity?.message && (
              <span
                style={{ marginTop: '2px', color: 'red', fontSize: '12px' }}
              >
                Обязательное поле
              </span>
            )}
          </Form.Group>
        </div>
        <div style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
          <Button
            style={{ height: '48px', width: '100%' }}
            variant="primary"
            type="submit"
            loading={loading}
            loadingPosition="start"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Создать
          </Button>
        </div>
      </Form>
    </>
  );
};

export default CreateNewPurchase;

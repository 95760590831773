/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';
const authService = {
  loginFn: (data: any) => requestAuth.post('auth/login', data),
  refreshTokenFn: (data: { data: any }) =>
    requestAuth.put('auth/refresh', data),
  sendOtpFn: (data: any) => requestAuth.post('auth/send-otp', data),
  verifyOtpFn: (data: any) => requestAuth.post('auth/verify-otp', data),
  registerFn: (data: any) => requestAuth.post('auth/register', data)
};

export default authService;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import SuppliersMaturityDateTable from './SuppliersMaturityDateTable';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/provider'
  },
  {
    label: 'Поставщик сроки погашения',
    active: true
  }
];

const SuppliersMaturityDate = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  return (
    <div>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Поставщик сроки погашения</h2>

        <SuppliersMaturityDateTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
        />
      </div>
    </div>
  );
};

export default SuppliersMaturityDate;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const providerEntityService = {
  getList: (params: any) => request.get('/dc/provider-entity', { params }),
  getById: (id: string) => request.get(`/legal-entity/${id}`),
  create: (data: any) => request.post('/dc/provider-entity', data),
  update: (id: any, data: any) => request.put(`/legal-entity/${id}`, data),
  delete: (id: any) => request.delete(`/legal-entity/${id}`)
};

export default providerEntityService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const variationService = {
  getList: (params: any) => request.get('/guidebook/variation', { params }),
  getVariationById: (id: any) => request.get(`/guidebook/variation/${id}`),
  createVariation: (data: any) => request.post('/guidebook/variation', data),
  deleteVariation: (id: any) => request.delete(`/guidebook/variation/${id}`),
  updateVariation: (id: any, data: any) =>
    request.put(`/guidebook/variation/${id}`, data)
};

export default variationService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const customersService = {
  getList: (params: any) => requestAuth.get('/customer', { params }),
  getById: (id: string) => requestAuth.get(`/customer/${id}`),
  create: (data: any) => requestAuth.post('/customer', data),
  update: (data: any) => requestAuth.put(`/customer`, data)
};

export default customersService;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PurchaseByInvoiceTable from './PurchaseByInvoiceTable';
import Filter from './filter';

import './styles.scss';

export const invoiceBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Отчеты',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'Закуп',
    url: '/reports/purchase/invoice'
  },

  {
    label: 'Закуп по накладной',
    active: true
  }
];

const PurchaseByInvoice = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [warehouseId, setWarehouseId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [typeId, setTypeId] = useState('');
  const [paymentId, setPaymentId] = useState('');

  return (
    <div>
      <PageBreadcrumb items={invoiceBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Закуп по накладной</h2>

        <Filter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setWarehouseId={setWarehouseId}
          setCurrencyId={setCurrencyId}
          setTypeId={setTypeId}
          setPaymentId={setPaymentId}
        />
        <PurchaseByInvoiceTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          warehouseId={warehouseId}
          currencyId={currencyId}
          typeId={typeId}
          paymentId={paymentId}
        />
      </div>
    </div>
  );
};

export default PurchaseByInvoice;

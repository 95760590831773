/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const currencyService = {
  getList: (params: any) => request.get('/guidebook/currency', { params }),
  getSignCurrency: (params: any) => request.get('/currency', { params }),
  getCurrencyById: (id: any) => request.get(`/guidebook/currency/${id}`),
  createCurrency: (data: any) => request.post('/guidebook/currency', data),
  deleteCurrency: (id: any) => request.delete(`/guidebook/currency/${id}`),
  updateCurrency: (id: any, data: any) =>
    request.put(`/guidebook/currency/${id}`, data)
};

export default currencyService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const productSearchService = {
  getList: (params: any) => request.get('/product/search', { params }),
  getListProduct: (params: any) => request.get('/product', { params }),
  getListProductLast: (params: any) => request.get('/product/last', { params }),
  getListProductHistory: (params: any) =>
    request.get('/product/history', { params }),
  getProductWithAccessByIds: (
    legal_entity_id: any,
    nomenclature_id: any,
    params: any
  ) =>
    request.get(`/product/with-access/${legal_entity_id}/${nomenclature_id}`, {
      params
    }),

  createProduct: (data: any) => request.post('/product', data),
  createProductOrder: (data: any) => request.post('/product/order', data),
  updateProduct: (id: any, data: any) => request.put(`/product/${id}`, data)
};

export default productSearchService;

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const rateBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/directories/stock'
  },
  {
    label: 'Курс валюты',
    active: true
  }
];

export type RateType = {
  base: string;
  main_currency: string;
  convertible: string;
  units: string;
  create_date: string;
  guid: string;
};

import { Modal } from 'react-bootstrap';

import Button from 'components/base/Button';

interface SupperModalProps {
  show?: boolean;
  bodyText: string;
  title: string;
  setConfirm: (confirm: boolean) => void;
  setShow: (show: boolean) => void;
}

const SupperModal = ({
  show,
  setConfirm,
  bodyText,
  title,
  setShow
}: SupperModalProps) => {
  const handleClose = () => setShow(false);
  const handleConfirm = () => {
    setConfirm(true);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Нет
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Да
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SupperModal;

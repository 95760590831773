/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const scalesService = {
  getList: (params: any) => request.get('/product/weight', { params }),
  getScalesById: (id: any) => request.get(`/product/weight/${id}`),
  createScales: (data: any) => request.post('/product/weight', data),
  deleteScales: (id: any) => request.delete(`/product/weight/${id}`),
  updateScales: (id: any, data: any) =>
    request.put(`/product/weight/${id}`, data)
};

export default scalesService;

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import WriteOffInventoryTable from './WriteOffInventoryTable';

export const infoInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Списание',
    url: '/write-off/write-off-inventory'
  },
  {
    label: 'По итог инвентаризацие',
    active: true
  }
];

const WriteOffInventory = () => {
  return (
    <div>
      <PageBreadcrumb items={infoInventoryBreadcrumbItems} />

      <div>
        <h2 className="mb-5">По итог инвентаризацие</h2>

        <WriteOffInventoryTable />
      </div>
    </div>
  );
};

export default WriteOffInventory;

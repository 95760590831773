/* eslint-disable @typescript-eslint/no-explicit-any */
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './styles.module.scss';

interface CInputMaskProps {
  control: any;
  defaultValue?: any;
  name?: string;
  mask: string;
  placeholder?: string;
  disabled?: boolean;
  maskChar?: any;
  errors?: any;
  icon?: any;
  isInvalid?: any;
  focusInput?: any;
}

export default function CInputMask({
  control,
  defaultValue,
  name = '',
  mask,
  placeholder,
  disabled = false,
  maskChar = false,
  icon,
  isInvalid,
  errors,
  focusInput,
  ...props
}: CInputMaskProps) {
  return (
    <div>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ field: { value, onChange } }) => {
          return (
            <div className={styles.maskedInput}>
              <FontAwesomeIcon
                icon={icon}
                className="text-900 fs-9 form-icon"
              />
              <InputMask
                inputRef={el => {
                  if (focusInput && el) el.focus();
                }}
                mask={`${mask}`}
                autoComplete="off"
                maskChar={maskChar}
                alwaysShowMask={false}
                autoFocus={false}
                placeholder={placeholder}
                className={`${styles.input} ${isInvalid ? styles.invalid : ''}`}
                value={value}
                disabled={disabled}
                onChange={onChange}
                {...props}
              />
            </div>
          );
        }}
      />
      {isInvalid && (
        <span className={styles.errMsg}>{errors?.[name]?.message}</span>
      )}
    </div>
  );
}

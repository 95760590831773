import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const paymentTypeBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/directories/stock'
  },
  {
    label: 'Тип оплаты',
    active: true
  }
];

export type PaymentMethodType = {
  name: string;
  type: string;
  country: string;
  currency: string;
  status: string;
  guid: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col, Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useState } from 'react';

import variationService from 'service/variation';
import Button from 'components/base/Button';

interface CreateNonVariationProps {
  setFormData: any;
  formData: any;
}

const CreateNonVariation = ({
  setFormData,
  formData
}: CreateNonVariationProps) => {
  const { register, control, setValue, getValues } = useForm();
  const [variationOption, setVariationOption] = useState<any>([]);

  //GET Variation to options
  useQuery(['GET_VARIATION'], async () => {
    await variationService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.variations?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setVariationOption(options);
      });
  });

  const onSubmit = () => {
    const data = getValues();
    const formDataItem = {
      var_value: data.name,
      variation: data?.label,
      variation_id: data?.variation
    };
    setFormData([...formData, formDataItem]);
  };

  return (
    <div>
      <Form noValidate>
        <Row className="mb-3 gx-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="variation"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = variationOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setValue('name', '');
                          setValue('label', selectedOption?.label);
                        }

                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {variationOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Категория</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>

          <Col className="d-flex gap-3">
            <Form.Floating className="md-4 flex-grow-1 bd-highlight">
              <Form.Control
                {...register('name')}
                type="text"
                placeholder="Значения"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Значения</label>
            </Form.Floating>
            <Button
              className="bd-highlight"
              variant="primary"
              type="button"
              onClick={onSubmit}
            >
              Новый
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateNonVariation;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal } from 'react-bootstrap';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import Button from 'components/base/Button';
import orderItemService from 'service/orderItem';
import { showAlert } from 'store/alert/alert.thunk';
import { isValidGtin } from 'utils/isValidGtin';
import NewAppSearchTable from './NewSearchTable';
import './styles.scss';

export type SearchData = {
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
};

interface NewApplicationsTableSearchProps {
  senderId?: any;
  orderItemData?: any;
  setOrderItemData?: any;
  focusRef?: any;
  isAutoTab?: any;
}

const NewApplicationsTableSearch = ({
  senderId,
  orderItemData,
  setOrderItemData,
  focusRef,
  isAutoTab
}: NewApplicationsTableSearchProps) => {
  const dispatch: Dispatch<any> = useDispatch();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');

  const handleGetProduct = useCallback(
    async (e: any) => {
      if (e.key !== 'Enter') return;

      if (senderId && isValidGtin(searchedValue)) {
        try {
          const res = await orderItemService
            .getByLegalEntityId(senderId, {
              offset: 0,
              limit: 20,
              barcode: searchedValue ? searchedValue : undefined
            })
            .then((res: any) => res);

          const resCurrency = res?.currency?.name?.ru;
          const resData = res?.products ?? [];

          const createdData = resData.map((items: any) => ({
            product_name: items?.nomenclature?.name,
            variation: items?.nomenclature?.is_variation ? 'Да' : 'Нет',
            barcode: items?.nomenclature?.barcode,
            units: items?.nomenclature?.measure_unit?.name?.ru,

            // This data is needed for static rendering
            unit: items?.nomenclature?.measure_unit?.name?.ru,
            measure_unit_id: items?.nomenclature?.measure_unit?.id,
            amount: 0,
            full_cost: items?.price_whosale ?? 0,
            nomenclature_id: items?.nomenclature_id,
            product_id: items?.id,
            product_parent_id: items?.id,
            price: items?.price ?? 0,
            price_in: items?.price_in ?? 0,
            price_whosale: items?.price_whosale ?? 0,
            guid: items?.nomenclature?.id,
            is_vat: items?.is_vat ? items?.is_vat : false,
            currency: resCurrency // Adding the currency to the data
          }));

          if (createdData.length === 0) {
            dispatch(
              showAlert({
                title: 'Штрих-код не найден!'
              })
            );
            return;
          }

          if (orderItemData?.length > 0) {
            const foundProductIndex = orderItemData.findIndex(
              (item: any) => item?.barcode === searchedValue
            );

            if (foundProductIndex !== -1) {
              const updatedProductInvoiceData = [...orderItemData];
              updatedProductInvoiceData[foundProductIndex].amount += 1;
              setOrderItemData(updatedProductInvoiceData);
            } else {
              setOrderItemData((prevData: any) => {
                const newData = [...createdData, ...prevData];

                if (isAutoTab) {
                  // when auto tab true focus to quantity
                  setTimeout(() => {
                    const firstProductIndex = 0;
                    focusRef(`amount_${firstProductIndex}`);
                  }, 500);
                }
                return newData;
              });
            }
          } else {
            setOrderItemData((prevData: any) => {
              const newData = [...createdData, ...prevData];

              if (isAutoTab) {
                // when auto tab true focus to quantity
                setTimeout(() => {
                  const firstProductIndex = 0;
                  focusRef(`amount_${firstProductIndex}`);
                }, 500);
              }
              return newData;
            });
          }
        } catch (error) {
          dispatch(
            showAlert({
              title: 'Штрих-код не найден!'
            })
          );
        }
      }
    },
    [senderId, searchedValue, orderItemData, dispatch, isAutoTab, focusRef]
  );

  return (
    <div className="mt-4">
      <div className="app-rows">
        <Form.Floating className="md-4">
          <Form.Control
            value={searchedValue}
            type="text"
            placeholder="Выведите или отсканируйте штрих-код"
            onChange={e => setSearchedValue(e?.target?.value)}
            onKeyDown={e => handleGetProduct(e)}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">
            Выведите или отсканируйте штрих-код
          </label>
        </Form.Floating>

        <Form.Group className="app-btn">
          <Button
            type="button"
            variant="primary"
            onClick={() => setOpenSearch(true)}
          >
            Поиск
          </Button>
        </Form.Group>
      </div>

      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Поиск</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewAppSearchTable
            setOpenSearch={setOpenSearch}
            orderItemData={orderItemData}
            setOrderItemData={setOrderItemData}
            senderId={senderId}
            isAutoTab={isAutoTab}
            focusRef={focusRef}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewApplicationsTableSearch;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const reportInvoiceService = {
  getList: (params: any) => request.get('/report/invoice', { params }),
  getReportProduct: (params: any) => request.get('/product', { params }),
  getReportProductList: (params: any) =>
    request.get('/report/product', { params }),
  getReportSaleList: (params: any) => request.get('/report/sale', { params }),
  getReportRemainingList: (params: any) =>
    request.get('/report/remaining-product', { params }),
  getReportIncomeList: (params: any) =>
    request.get('/report/gross-income', { params })
};

export default reportInvoiceService;

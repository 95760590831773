/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AccordingToProductFilter from './filter/AccordingToProductFilter';
import AccordingToProductTable from './table/AccordingToProductTable';

import styles from './styles.module.scss';

export const incomeBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Аналитика',
    url: '/analytics/revaluation/revaluation-invoice'
  },

  {
    label: 'Переуценка товаров',
    url: '/analytics/revaluation/revaluation-invoice'
  },

  {
    label: 'Переуценка по товарам',
    active: true
  }
];

const AccordingToProduct = () => {
  const [generateTableData, setGeneratedTableData] = useState([]);
  const [savedValues, setSavedValues] = useState([]);
  const [getName, setGetName] = useState('');
  const [getBarcode, setGetBarcode] = useState('');

  const sumOfAllCost = generateTableData.reduce((acc: any, item: any) => {
    const allCost =
      item?.revaluation_amount === undefined ? 0 : +item.revaluation_amount;
    return acc + allCost;
  }, 0);

  return (
    <div>
      <PageBreadcrumb items={incomeBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Переуценка по товарам</h2>

        <AccordingToProductFilter
          setGeneratedTableData={setGeneratedTableData}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          setGetName={setGetName}
          setGetBarcode={setGetBarcode}
        />
        <AccordingToProductTable
          generateTableData={generateTableData}
          setGeneratedTableData={setGeneratedTableData}
          getName={getName}
          getBarcode={getBarcode}
        />

        <div className={styles.revProdFooter}>
          <div className={styles.box}>
            <p className="mb-0 text-bold">Итого сумма продажи</p>
            <span>{sumOfAllCost ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordingToProduct;

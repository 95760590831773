/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import CashFilter from './CashFilter';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import Button from 'components/base/Button';
import CashInfoCheck from './CashInfoCheck';
import { pageToOffset } from 'utils/pageToOffset';
import cashboxService from 'service/cashbox';

export type cashInfoType = {
  employee: string;
  cash_name: string;
  date_open: string;
  date_close: string;
  guid: string;
};

export const cashInfoBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Отчеты',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'Касса',
    active: true
  }
];

const CashInfo = () => {
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState('');
  const [currencyId, setCurrencyId] = useState('');
  const [userId, setUserId] = useState('');
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [selectedDateOpen, setSelectedDateOpen] = useState('');
  const [selectedDateClose, setSelectedDateClose] = useState('');
  const [activeItemId, setActiveItemId] = useState<any>(null);
  const navigate = useNavigate();

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  // Use the provided dates or default to today's date
  const adjustedDateFrom = dateFrom || todayStart;
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? todayEnd + 86400000
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get Product Search
  const { data, isFetching, isLoading } = useQuery(
    [
      'GET_CASHBOX',
      currentPage,
      adjustedDateFrom,
      adjustedDateTo,
      currencyId,
      userId
    ],
    async () => {
      const res = await cashboxService
        .getList({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          date_from: adjustedDateFrom,
          date_to: adjustedDateTo,
          currency_id: currencyId,
          employee_id: userId
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!currentPage ||
        !!adjustedDateFrom ||
        !!adjustedDateTo ||
        !!currencyId ||
        !!userId
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const cashInfoData: cashInfoType[] = useMemo(() => {
    return (
      data?.cashboxes?.map((items: any) => {
        return {
          employee: `${items?.employee?.first_name} ${items?.employee?.last_name}`,
          cash_name: items?.legal_entity_cash?.name,
          date_open: new Date(items?.date_open)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          date_close: items?.date_close
            ? new Date(items?.date_close)
                .toLocaleString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })
                .replace(/\//g, '-')
                .replace(',', '')
            : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const productsDataTableColumns: ColumnDef<cashInfoType>[] = [
    {
      id: 'action',
      cell: rowData => {
        const itemId = rowData?.row?.original?.guid;
        return (
          <div className="d-flex gap-2">
            <Button
              variant={activeItemId === itemId ? 'active' : 'hover'}
              className="p-1"
              onClick={() => {
                setSelectedId(itemId);
                setSelectedDateOpen(rowData?.row?.original?.date_open);
                setSelectedDateClose(rowData?.row?.original?.date_close);
                setActiveItemId(itemId);
              }}
            >
              <FeatherIcon icon="info" size={24} className="text-warning" />
            </Button>
            <Button
              variant="hover"
              className="p-1"
              onClick={() => {
                navigate(
                  `/reports/cash-info/cash-info-check/${rowData?.row?.original?.guid}`
                );
              }}
            >
              <FeatherIcon icon="file-text" size={22} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },

    {
      accessorKey: 'employee',
      header: 'Сотрудники',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cash_name',
      header: 'Названия кассы',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_open',
      header: 'Дата открытия',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_close',
      header: 'Дата закрытия',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: cashInfoData,
    columns: productsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={cashInfoBreadcrumbItems} />

      <h2 className="mb-5">Касса</h2>
      <CashFilter
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        setCurrencyId={setCurrencyId}
        setUserId={setUserId}
      />

      <div className="d-flex gap-3">
        <AdvanceTableProvider {...table}>
          <div
            style={{ width: '70%' }}
            className="mt-4 px-4 mx-lg-n2 px-lg-4 bg-white border-top border-300 position-relative top-1"
          >
            <AdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              isLoading={loading}
            />
            <AdvanceTableCPagination
              count={pageCount}
              page={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </AdvanceTableProvider>

        <CashInfoCheck
          selectedId={selectedId}
          currencyId={currencyId}
          selectedDateOpen={selectedDateOpen}
          selectedDateClose={selectedDateClose}
        />
      </div>
    </div>
  );
};

export default CashInfo;

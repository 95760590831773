/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { rgbaColor } from 'helpers/utils';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import EChartsReactCore from 'echarts-for-react/lib/core';

echarts.use([TooltipComponent, LineChart]);

const getDefaultOptions = (
  dates: string[],
  data: number[],
  getThemeColor: (name: string) => string
) => ({
  color: [getThemeColor('primary-200'), getThemeColor('info-300')],
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getThemeColor('gray-100'),
    borderColor: getThemeColor('gray-300'),
    textStyle: { color: getThemeColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    },
    formatter: (params: any[]) => {
      // Extract the date from the axis value
      const date = params[0]?.axisValue as string;

      // Format the numeric value
      const formattedValue = params
        .map(param => {
          const value = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 4,
            useGrouping: true
          })
            .format(param.value as number)
            .replace(/,/g, ' ');
          return `${param.marker} ${param.seriesName}: ${value}`;
        })
        .join('<br/>');

      // Return just the date and formatted value
      return `${date}<br/>${formattedValue}`;
    }
  },
  xAxis: [
    {
      type: 'category',
      data: dates,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      },
      axisLabel: {
        color: getThemeColor('gray-900'),
        // interval: 1,
        showMaxLabel: dates?.length === 1 ? true : false,
        showMinLabel: true,
        align: 'center',
        formatter: (value: string) => value,
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 12.8,
        margin: 16
      },
      axisLine: {
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      },
      axisTick: false
    },
    {
      type: 'category',
      data: dates,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      },
      axisLabel: {
        color: getThemeColor('gray-900'),
        interval: 130,
        showMaxLabel: true,
        showMinLabel: false,
        align: 'right',
        formatter: (value: string) => value,
        fontFamily: 'Nunito Sans',
        fontWeight: 400,
        fontSize: 12.8,
        margin: 16
      },
      position: 'bottom',
      axisLine: {
        lineStyle: {
          color: getThemeColor('gray-200')
        }
      },
      axisTick: false
    }
  ],
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    splitLine: {
      lineStyle: {
        color: getThemeColor('gray-200')
      }
    },
    axisLabel: {
      color: getThemeColor('gray-900'),
      fontFamily: 'Nunito Sans',
      fontWeight: 700,
      fontSize: 12.8,
      margin: 16,
      formatter: (value: number) => `${value / 1000}M`
    }
    // interval: 150,
  },
  series: [
    {
      name: 'Продажа',
      type: 'line',
      smooth: 0.4,
      symbolSize: 11,
      itemStyle: {
        color: getThemeColor('primary')
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getThemeColor('primary-300'), 0.2)
            },
            {
              offset: 1,
              color: rgbaColor(getThemeColor('primary-300'), 0.2)
            }
          ]
        }
      },
      data
    }
  ],
  grid: {
    right: '8',
    left: 6,
    bottom: '-10',
    top: 10,
    containLabel: true
  },
  animation: false
});

const AnalyticsCallCampaignChart = ({
  data,
  style
}: {
  data?: any;
  style: CSSProperties;
}) => {
  const chartRef = useRef<null | EChartsReactCore>(null);

  const dates = data?.map((item: any) => item?.range ?? '');
  const data1 = data?.map((item: any) => item?.amount ?? 0);

  const updateDimensions = () => {
    if (window.innerWidth < 576) {
      chartRef.current?.getEchartsInstance().setOption({
        xAxis: [
          {},
          {
            axisLabel: {
              showMaxLabel: false
            }
          }
        ]
      });
    } else if (window.innerWidth > 576) {
      chartRef.current?.getEchartsInstance().setOption({
        xAxis: [
          {},
          {
            axisLabel: {
              showMaxLabel: true
            }
          }
        ]
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    updateDimensions();
  }, [chartRef.current]);

  const { getThemeColor } = useAppContext();

  return (
    <ReactEChartsCore
      ref={chartRef}
      echarts={echarts}
      option={getDefaultOptions(dates, data1, getThemeColor)}
      style={style}
    />
  );
};

export default AnalyticsCallCampaignChart;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const expensesService = {
  getList: (params: any) => request.get('/expense', { params }),
  getExpenseById: (id: any) => request.get(`/expense/${id}`),
  createExpense: (data: any) => request.post('/expense', data),
  deleteExpense: (id: any) => request.delete(`/expense/${id}`),
  updateExpense: (id: any, data: any) => request.put(`/expense/${id}`, data)
};

export default expensesService;

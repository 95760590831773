import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import SignUpPhone from './SignUpPhone';

const SignUp = () => {
  return (
    <AuthSimpleLayout>
      <SignUpPhone />
    </AuthSimpleLayout>
  );
};

export default SignUp;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const specificationService = {
  getList: (params: any) => request.get('/guidebook/specification', { params }),
  getSpecificationById: (id: any) =>
    request.get(`/guidebook/specification/${id}`),
  createSpecification: (data: any) =>
    request.post('/guidebook/specification', data),
  deleteSpecification: (id: any) =>
    request.delete(`/guidebook/specification/${id}`),
  updateSpecification: (id: any, data: any) =>
    request.put(`/guidebook/specification/${id}`, data)
};

export default specificationService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const regionService = {
  getList: (params: any) => request.get('/guidebook/region', { params }),
  getRegionById: (id: any) => request.get(`/guidebook/region/${id}`),
  createRegion: (data: any) => request.post('/guidebook/region', data),
  deleteRegion: (id: any) => request.delete(`/guidebook/region/${id}`),
  updateRegion: (id: any, data: any) =>
    request.put(`/guidebook/region/${id}`, data)
};

export default regionService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import PhoneInput from 'react-phone-number-input';
import { forwardRef } from 'react';

import 'react-phone-number-input/style.css';

import styles from './styles.module.scss';

interface PhoneNumberInputProps {
  unicode?: string;
  placeholder?: string;
  isInvalid?: any;
  field?: any;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  ({ unicode, placeholder, field, isInvalid }, ref) => {
    return (
      <PhoneInput
        {...field}
        ref={ref}
        international
        defaultCountry={unicode}
        placeholder={placeholder}
        className={`${styles.phoneInput} ${isInvalid ? styles.invalid : ''}`}
      />
    );
  }
);

export default PhoneNumberInput;

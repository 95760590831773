/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const orderInService = {
  getList: (params: any) => request.get('/in-order', { params }),
  getOrderOutById: (id: any) => request.get(`/in-order/${id}`),
  createOrderOut: (data: any) => request.post('/in-order', data),
  deleteOrderOut: (id: any) => request.delete(`/in-order/${id}`),
  updateOrderOut: (id: any, data: any) => request.put(`/in-order/${id}`, data)
};

export default orderInService;

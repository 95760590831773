/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import analyticRevaluationService from 'service/analyticRevaluation';

export type Invoice = {
  supplier: string;
  warehouse: string;
  invoice: string;
  invoice_date: string;
  currency: string;
  revaluation_amount: string;
  create_date: string;
};

export const invoiceTableColumns: ColumnDef<Invoice>[] = [
  {
    accessorKey: 'supplier',
    header: 'Поставщик',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'warehouse',
    header: 'Склад',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'invoice',
    header: '№ Накладной',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'invoice_date',
    header: 'Дата Накладной',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'revaluation_amount',
    header: 'Сумма Переуценке',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'create_date',
    header: 'Дата Создания',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface AccordingToInvoiceTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionWarehouseId?: any;
}

const AccordingToInvoiceTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionWarehouseId
}: AccordingToInvoiceTableProps) => {
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/analytics/revaluation/revaluation-invoice/invoice-result/${row?.original?.guid}`
    );
  };

  console.log('dateTO', typeof dateTo);
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_REVALUATION'],
    async () => {
      const res = await analyticRevaluationService
        .getList({
          offset: 0,
          limit: 100,
          warehouse_id: optionWarehouseId ? optionWarehouseId : undefined,
          supplier_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.items);
      return res;
    },
    {
      enabled:
        !!dateFrom ||
        !!adjustedDateTo ||
        !!optionSenderId ||
        !!optionWarehouseId
    }
  );

  const invoiceData: Invoice[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        return {
          supplier: items?.legal_entity?.name,
          warehouse: items?.wareHouse?.name,
          invoice: items?.invoice_number,
          /* prettier-ignore */
          invoice_date: new Date(items?.invoice_date)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          currency: items?.currency?.name?.ru,
          revaluation_amount: items?.amount ?? 0,
          /* prettier-ignore */
          create_date: new Date(items?.date_created)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data]);

  const table = useAdvanceTable({
    data: invoiceData,
    columns: invoiceTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionWarehouseId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default AccordingToInvoiceTable;

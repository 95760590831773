import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const newApplicationsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Заявки',
    url: '/applications/outgoing'
  },
  {
    label: 'Исходящий',
    url: '/applications/outgoing'
  },
  {
    label: 'Новая заявка',
    active: true
  }
];

export type NewApplicationsData = {
  orders: string;
  recipient: string;
  currency: string;
  date_create: string;
  sender: string;
  sender_id: string;
  guid: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import classNames from 'classnames';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { Table, TableProps } from 'react-bootstrap';
import { flexRender } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import './styles.scss';

interface NestedRow {
  name: string;
  children?: NestedRow[];
  [key: string]: any;
}

interface NestedAdvanceTableProps<T> {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
  data: T[];
  columns: any;
}

const NestedAdvanceTable = <T extends NestedRow>({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  hasFooter,
  data,
  columns
}: NestedAdvanceTableProps<T>) => {
  const { getFooterGroups } = useAdvanceTableContext();

  const [expandedRows, setExpandedRows] = React.useState<
    Record<string, boolean>
  >({});

  const toggleRowExpansion = (rowId: string) => {
    setExpandedRows(prev => ({ ...prev, [rowId]: !prev[rowId] }));
  };

  const renderRows = (rows: T[], level: number = 0) => {
    return rows.map((row: any, index) => {
      const rowId = `${row.name}-${index}`;
      const isExpanded = expandedRows[rowId];

      return (
        <React.Fragment key={rowId}>
          <tr
            className={classNames(rowClassName, { [`level-${level}`]: true })}
          >
            <td style={{ paddingLeft: `${level * 20}px` }}>
              {row.children && row.children.length > 0 ? (
                <span
                  onClick={() => toggleRowExpansion(rowId)}
                  className="icons"
                >
                  {isExpanded ? (
                    <FeatherIcon icon="chevron-up" size={16} />
                  ) : (
                    <FeatherIcon icon="chevron-down" size={16} />
                  )}
                </span>
              ) : null}
              {row.name || '\u00A0'} {/* Ensure empty cells render correctly */}
            </td>
            {columns.slice(1).map((column: any) => (
              <td key={column.accessorKey}>
                {row[column.accessorKey] !== undefined
                  ? row[column.accessorKey]
                  : ''}
              </td>
            ))}
          </tr>
          {isExpanded && row.children && renderRows(row.children, level + 1)}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="scrollbar ms-n1 ps-1">
      <Table {...tableProps}>
        <thead className={headerClassName}>
          <tr>
            {columns.map((header: any) => (
              <th
                key={header.accessorKey}
                {...header.meta?.headerProps}
                className={header.meta?.headerProps?.className}
              >
                {header.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>{renderRows(data)}</tbody>
        {hasFooter && (
          <tfoot>
            {getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id} className="border-0">
                {footerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.footerProps}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </Table>
    </div>
  );
};

export default NestedAdvanceTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const roleService = {
  getList: (params: any) => requestAuth.get('/role', { params }),
  getById: (id: string) => requestAuth.get(`/role/${id}`),
  getRolePermisionById: (id: string, data: any) =>
    requestAuth.get(`/role-permission/menu/web/${id}`, data),
  getPosRolePermisionById: (id: string, data: any) =>
    requestAuth.get(`/role-permission/menu/pos/${id}`, data),
  create: (data: any) => requestAuth.post('/role', data),
  update: (id: any, data: any) =>
    requestAuth.put(`/role-permission/menu/web/${id}`, data),
  updatePos: (id: any, data: any) =>
    requestAuth.put(`/role-permission/menu/pos/${id}`, data)
};

export default roleService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const nomenclatureService = {
  getList: (params: any) => request.get('/nomenclature', { params }),
  getNomenclatureById: (id: any) => request.get(`/nomenclature/${id}`),
  createNomenclature: (data: any) => request.post('/nomenclature', data),
  deleteNomenclature: (id: any) => request.delete(`/nomenclature/${id}`),
  updateNomenclature: (id: any, data: any) =>
    request.put(`/nomenclature/${id}`, data)
};

export default nomenclatureService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import './component.scss';

interface ComponentToPrintProps {
  data?: any;
  selectedDateClose?: any;
  selectedDateOpen?: any;
}

const ComponentToPrint = ({
  data,
  selectedDateOpen,
  selectedDateClose
}: ComponentToPrintProps) => {
  return (
    <div className="print-container">
      <p className="p-0 text-center text-bold">Дубликат</p>
      <div className="cash-check">
        <ul className="check-list">
          <li>Наличие (ОПЛАТА)</li>
          <li>Наличие (Вз/р с Конт.)</li>
          <li>Пластиковая карта (ОПЛАТА)</li>
          <li>Продажа в долг (ОПЛАТА)</li>
          <li>Затраты (ОПЛАТА)</li>
          <li style={{ fontWeight: '700' }}>Скидка</li>
          <li style={{ fontWeight: '700' }}>Итого сумма продажи</li>
          <li style={{ fontWeight: '700' }}>Остаток налички в кассе</li>
          <li style={{ fontWeight: '700' }}>Дата открытия</li>
          <li style={{ fontWeight: '700' }}>Дата закрытия</li>
        </ul>

        <ul className="check-cost">
          <li>
            {data?.x_sale_report?.payment_types?.[0]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[0]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_income_report?.other_income?.payment_types?.[0]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(
                    data?.x_income_report?.other_income?.payment_types?.[0]
                      ?.amount
                  )
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.payment_types?.[1]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[1]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.payment_types?.[2]?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.payment_types?.[2]?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_other_report?.expense_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_other_report?.expense_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_other_report?.discount_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_other_report?.discount_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.x_sale_report?.amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.x_sale_report?.amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>
            {data?.cash_amount
              ? new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 4,
                  useGrouping: true
                })
                  .format(data?.cash_amount)
                  .replace(/,/g, ' ')
              : 0}
          </li>
          <li>{selectedDateOpen ?? 0}</li>
          <li>{selectedDateClose ?? 0}</li>
        </ul>
      </div>
    </div>
  );
};

export default ComponentToPrint;

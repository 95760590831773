/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { Form } from 'react-bootstrap';

import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import returnItemService from 'service/returnItemService';
import returnReasonService from 'service/returnReason';

export type NewReturningInfo = {
  product_name: string;
  barcode: string;
  unit: string;
  currency: string;
  amount: string;
  return_reason_id: string;
  current_balance: string;
};

interface NewReturningTableProps {
  returnItemData?: any;
  newReturningId?: any;
  setReturnItemData?: any;
  setRef?: any;
}

const NewReturningTable = ({
  returnItemData,
  newReturningId,
  setReturnItemData,
  setRef
}: NewReturningTableProps) => {
  const [returnReasonOption, setReturnReasonOption] = useState<any>([]);
  //GET return Reason  to options
  useQuery(['GET_RETURN_REASON'], async () => {
    await returnReasonService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.product_return_reasons?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));

        setReturnReasonOption(options);
      });
  });

  const { data } = useQuery(
    ['GET_RETURN_ITEMS', newReturningId],
    async () => {
      if (newReturningId) {
        const res = await returnItemService
          .getList({
            offset: 0,
            limit: 100,
            return_id: newReturningId
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!newReturningId }
  );

  const generateData: NewReturningInfo[] = useMemo(() => {
    const newData =
      data?.map((items: any) => {
        return {
          product_name: items?.product_short_name,
          barcode: items?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit.id,
          amount: items?.quantity_asked ?? 1,
          full_cost: items?.price_wholesale,
          currency: items?.currency?.name?.ru,
          return_reason_id: items?.return_reason_id,
          nomenclature_id: items?.nomenclature_id,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          price: items?.price,
          is_vat: items?.is_vat ? items?.is_vat : false,
          currency_id: items?.currency_id,
          package_quantity: items?.package_quantity ?? 0,
          current_balance: items?.current_balance ?? 0
        };
      }) ?? [];

    setReturnItemData([...returnItemData, ...newData]);

    return newData;
  }, [data]);

  console.log(generateData);

  const handleRemoveProductItem = (indexToRemove: number) => {
    setReturnItemData(
      returnItemData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const newReturningDataTableColumns: ColumnDef<NewReturningInfo>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    },

    {
      accessorKey: 'product_name',
      header: 'Наименования товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: 'Текущий остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'amount',
      header: 'Количество',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const currentBalance = rowData?.row?.original?.current_balance;

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          let newValue = +value;

          const currentBalanceNumber = +currentBalance; // Convert currentBalance to a number

          if (newValue > currentBalanceNumber) {
            newValue = currentBalanceNumber;
          }

          const updatedItems = returnItemData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: newValue };
            }
            return item;
          });

          setReturnItemData(updatedItems);
          setValue(newValue); // Ensure the displayed value is updated correctly
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue) && +newValue <= +currentBalance) {
            setValue(newValue);
          } else if (+newValue > +currentBalance) {
            setValue(currentBalance.toString());
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="amount"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                ref={setRef(`amount_${rowData.row.id}`)}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '10%' } },
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'return_reason_id',
      header: 'Причина возврата',
      cell: rowData => {
        return (
          <div>
            <Form.Group className="md-4">
              <div className="react-select-container">
                <Form.Group>
                  <Form.Select
                    value={rowData?.row?.original?.return_reason_id}
                    onChange={(e: any) => {
                      const selectedOption = returnReasonOption.find(
                        (option: any) => option.value === e.target.value
                      );
                      if (selectedOption) {
                        const updatedItems = returnItemData.map(
                          (item: any, i: any) => {
                            if (i === +rowData?.row?.id) {
                              return {
                                ...item,
                                return_reason_id: selectedOption.value
                              };
                            }
                            return item;
                          }
                        );
                        setReturnItemData(updatedItems);
                      }
                    }}
                  >
                    <option className="d-none" value=""></option>
                    {returnReasonOption?.map((option: any) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="option"
                      >
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: returnItemData,
    columns: newReturningDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default NewReturningTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntitySettingsService = {
  getList: (params: any) => request.get('/legal-entity-settings', { params }),
  getStatusById: (id: any) => request.get(`/legal-entity-settings/${id}`),
  createStatus: (data: any) => request.post('/legal-entity-settings', data),
  deleteStatus: (id: any) => request.delete(`/legal-entity-settings/${id}`),
  updateStatus: (id: any, data: any) =>
    request.put(`/legal-entity-settings/${id}`, data)
};

export default legalEntitySettingsService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { Form } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarcode } from '@fortawesome/free-solid-svg-icons';

import productSearchService from 'service/productSearch';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import scalesService from 'service/scales';
import { showAlert } from 'store/alert/alert.thunk';

export type SearchType = {
  product_name: string;
  barcode: string;
  units: string;
  price: string;
  guid: string;
};

const AddProductScalesTable = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState<any>([]);
  const [getBarcode, setGetBarcode] = useState<any>([]);

  const dispatch: Dispatch<any> = useDispatch();

  // Create a ref for the first scale_code input
  const scaleCodeInputRef = useRef<HTMLInputElement>(null);

  const { data } = useQuery(
    ['GET_PRODUCTS_SCALES', searchValue, getBarcode],
    async () => {
      const res = await productSearchService
        .getList({
          limit: 10,
          offset: 0,
          name: searchValue,
          barcode: getBarcode
        })
        .then((res: any) => {
          return res;
        });

      return res.products;
    },
    {
      enabled: searchValue.length > 2 || !!getBarcode
    }
  );

  useEffect(() => {
    if (searchValue === '' && !getBarcode) {
      setSearchedData([]);
    } else if (data) {
      const resData =
        data?.map((items: any) => {
          return {
            product_name:
              data?.is_product === true
                ? items?.name
                : items?.nomenclature?.name,
            barcode:
              data?.is_product === true
                ? items?.barcode
                : items?.nomenclature?.barcode,
            units: items?.nomenclature?.measure_unit?.name?.ru,
            price: items?.price ?? 0,
            scale_code: items?.scale_code ?? 0,
            guid: items?.id
          };
        }) ?? [];

      if (resData.length > 0) {
        setSearchedData(resData);
        // Focus the first scale_code input when results come in

        if (getBarcode) {
          setTimeout(() => {
            scaleCodeInputRef.current?.focus();
          }, 300); // Adding a small delay to ensure the DOM is updated
        }
      }
    }
  }, [data, setSearchedData, searchValue, getBarcode]);

  const searchTableColumns: ColumnDef<SearchType>[] = [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'price',
      header: 'Цена продажи',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'scale_code',
      header: 'Штрих-код весовой',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const updatedProductId = rowData?.row?.original?.guid;

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const newValue = +value;

          const updatedItems = searchedData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: newValue };
            }
            return item;
          });

          setSearchedData(updatedItems);
          setValue(newValue); // Ensure the displayed value is updated correctly

          // Update condition to allow 0 as a valid value
          if (
            updatedProductId !== '' &&
            newValue !== undefined &&
            newValue !== null
          ) {
            scalesService
              .updateScales(updatedProductId, {
                scale_code: newValue // No need for +newValue, as it's already converted
              })
              .then(res => {
                if (res) {
                  dispatch(
                    showAlert({
                      title: 'Штрих-код весовой успешно изменён!',
                      type: 'success'
                    })
                  );
                }
              })
              .catch(error => {
                // Handle the error: set the value to 0 in updatedItems
                const updatedErrorItems = updatedItems.map(
                  (item: any, i: any) => {
                    if (i === +index) {
                      return { ...item, scale_code: 0 };
                    }
                    return item;
                  }
                );

                setSearchedData(updatedErrorItems); // Update the state with the modified data

                if (error?.response?.status === 404) {
                  dispatch(
                    showAlert({
                      title: 'Штрих-код весовой существует!'
                    })
                  );
                }
              });
          }
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            setValue(newValue);
          }
        };

        return (
          <div style={{ width: '150px' }}>
            <Form.Group>
              <Form.Control
                type="text"
                name="scale_code"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                // onKeyDown={e => handleGetProduct(e)}
                ref={rowData.row.index === 0 ? scaleCodeInputRef : null}
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: searchedData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="d-flex gap-2">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Выведите товар"
              onFocus={e => e.target.select()}
              onChange={e => setSearchValue(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Выведите товар
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faSearch}
            className="text-900 fs-9 form-icon"
          />
        </div>

        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Выведите штрих-код"
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Выведите штрих-код
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>
      </div>

      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </AdvanceTableProvider>
  );
};

export default AddProductScalesTable;

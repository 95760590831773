/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import Button from 'components/base/Button';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  can_update: string;
  guid: string;
};

interface SettelementHistoryProps {
  selectedCustomerId?: any;
}
const SettelementCustomerHistory = ({
  selectedCustomerId
}: SettelementHistoryProps) => {
  const { data, refetch } = useQuery(
    ['GET_HISTORY_ID'],
    () => {
      if (selectedCustomerId) {
        const res = customerEntityService
          .getCustomerSettlementById(selectedCustomerId, {
            offset: 0,
            limit: 20
          })
          .then((res: any) => res?.debit_credit_settlements);
        return res;
      }
    },
    { enabled: !!selectedCustomerId }
  );

  const historyData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.ru,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          can_update: items?.can_update,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleAccept = (id: any) => {
    if (id !== '') {
      customerEntityService
        .updateCustomerSettlement(id, {
          status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
        })
        .then((res: any) => {
          if (res) {
            refetch();
          }
        });
    }
  };

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          {rowData?.row?.original?.can_update ? (
            <Button
              variant="primary"
              onClick={() => handleAccept(rowData?.row?.original?.guid)}
            >
              Подтвердить
            </Button>
          ) : (
            ''
          )}
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: 'Дебет',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: 'Кредит',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: 'Остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: historyData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default SettelementCustomerHistory;

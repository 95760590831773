import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tableSlug: {}
};

export const { actions: tableSettingsActions, reducer: tableSettingsReducer } =
  createSlice({
    name: 'tableSettings',
    initialState,
    reducers: {
      addSettings: (state, { payload }) => {
        const { pathname, checkedItems } = payload;
        state.tableSlug[pathname] = [...checkedItems];
      }
    }
  });

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';

import NdsTable from './NdsTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { ndsTableColumns } from './NdsTable';
import { NDSType, ndsBreadcrumbItems } from 'data/nds';
import ndsService from 'service/nds';

const Nds = () => {
  //Get NDS
  const { data } = useQuery(['GET_NDS'], () => {
    const res = ndsService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.vats);
    return res;
  });

  const ndsData: NDSType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          country: items?.country?.name?.ru,
          nds: items?.value,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const table = useAdvanceTable({
    data: ndsData,
    columns: ndsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={ndsBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">НДС</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Поиск НДС"
                  onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <NdsTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Nds;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const providerService = {
  getList: (params: any) => request.get('/dc/provider', { params }),
  getProviderSettlementById: (id: string, params: any) =>
    request.get(`/dc/settlement/${id}`, { params }),
  getListCheck: (params: any) => request.get('/dc/provider/check', { params }),

  createProviderSettlement: (data: any) =>
    request.post('/dc/provider/settlement', data)
};

export default providerService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';

import { shiftIdActions } from 'store/shiftId/shiftId.slice';
import cashboxService from 'service/cashbox';
import Button from 'components/base/Button';
import cashService from 'service/cash';

interface CreateCashBoxProps {
  setOpenModal: (show: boolean) => void;
}

const CreateCashBox = ({ setOpenModal }: CreateCashBoxProps) => {
  const dispatch = useDispatch();
  const shiftId = useSelector((state: any) => state?.shiftId?.shiftId);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [cashOption, setCashOption] = useState<any>([]);
  const hasFetchedData = useRef(false);

  const fetchData = () => {
    if (shiftId === '') return setLoading(false);

    cashboxService
      .getCashboxById(shiftId)
      .then((res: any) => {
        const computed = {
          remainder_cash: res?.legal_entity_cash?.name
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  //GET Cash
  useQuery(['GET_CASH'], async () => {
    await cashService
      .getList({ offset: 0, limit: 100, status: true, only_closed: true })
      .then((res: any) => {
        const options = res?.legal_entity_cashes?.map((option: any) => ({
          value: option.id,
          label: option.name,
          remainder_amount: option?.remainder_amount
        }));
        setCashOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (shiftId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const computed = {
      legal_entity_cash_id: data?.cash_id,
      started_amount: +data?.remainder ?? 0,
      added_amount: +data?.sum
    };

    cashboxService.createCashbox(computed).then((res: any) => {
      if (res) {
        dispatch(shiftIdActions.setShiftId(res?.id));
        setLoading(false);
        setOpenModal(false);
      }
    });
  };

  const update = (data: any) => {
    setLoading(true);

    const computed = {
      remainder_amount: +data?.remainder
    };

    cashboxService.updateCashbox(shiftId, computed).then((res: any) => {
      if (res) {
        dispatch(shiftIdActions.setShiftId(''));
        setLoading(false);
        setOpenModal(false);
      }
    });
  };

  useEffect(() => {
    if (shiftId !== '' && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [shiftId]);

  return (
    <div>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        {shiftId === '' && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="cash_id"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select
                        {...field}
                        onChange={(e: any) => {
                          const selectedOption = cashOption.find(
                            (option: any) => option.value === e.target.value
                          );
                          if (selectedOption) {
                            setValue(
                              'remainder',
                              selectedOption.remainder_amount ?? 0
                            );
                          }
                          field.onChange(e);
                        }}
                        isInvalid={!!errors.cash_id}
                      >
                        <option className="d-none" value=""></option>
                        {cashOption?.map((option: any) => (
                          <option
                            key={option.value}
                            value={option.value}
                            className="option"
                          >
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">Касса</label>
                      {errors?.cash_id && (
                        <span
                          style={{
                            marginTop: '2px',
                            color: 'red',
                            fontSize: '12px'
                          }}
                        >
                          Обязательное поле
                        </span>
                      )}
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}

        {shiftId !== '' && (
          <Row className="mb-2">
            <Col>
              <Controller
                name="remainder_cash"
                control={control}
                render={({ field }) => (
                  <Form.Floating className="md-4">
                    <Form.Control
                      {...field}
                      disabled={true}
                      type="text"
                      placeholder={`Касса`}
                    />
                    <label htmlFor="floatingInputCustom">Касса</label>
                  </Form.Floating>
                )}
              />
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Controller
              name="remainder"
              control={control}
              rules={{ required: shiftId !== '' ? true : false }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    disabled={shiftId === '' ? true : false}
                    type="number"
                    placeholder={`Остаток`}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.remainder && shiftId !== ''}
                  />
                  <label htmlFor="floatingInputCustom">Остаток</label>
                  {errors.remainder && shiftId !== '' && (
                    <Form.Control.Feedback type="invalid">
                      Обязательное поле
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        {shiftId === '' && (
          <Row className="mb-3">
            <Col>
              <Controller
                name="sum"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Form.Floating className="md-4">
                    <Form.Control
                      {...field}
                      type="number"
                      placeholder={`Сумма `}
                      onFocus={e => e.target.select()}
                      isInvalid={!!errors.sum}
                    />
                    <label htmlFor="floatingInputCustom">Сумма </label>
                    {errors.sum && (
                      <Form.Control.Feedback type="invalid">
                        Обязательное поле
                      </Form.Control.Feedback>
                    )}
                  </Form.Floating>
                )}
              />
            </Col>
          </Row>
        )}

        <Button
          style={{ height: '48px', width: '100%' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {shiftId === '' ? 'Открыть Смену' : 'Закрыть Смену'}
        </Button>
      </Form>
    </div>
  );
};

export default CreateCashBox;

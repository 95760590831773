/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const inventoryItemService = {
  getList: (params: any) => request.get('/inventory-item', { params }),
  getInventoryItemById: (id: any, params: any) =>
    request.get(`/inventory-item/${id}`, { params }),
  createInventoryItem: (data: any) => request.post('/inventory-item', data),
  deleteInventoryItem: (id: any) => request.delete(`/inventory-item/${id}`),
  updateInventoryItem: (id: any, data: any) =>
    request.put(`/inventory-item/${id}`, data)
};

export default inventoryItemService;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { comingBreadcrumbItems } from 'data/movement';
import ComingFilterMovements from './ComingFilterMovements';
import ComingFilterMovementsTable from './ComingFilterMovementsTable';
import './styles.scss';

const ComingOutMovements = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [optionStatusId, setOptionStatusId] = useState('');
  return (
    <div>
      <PageBreadcrumb items={comingBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Входящий</h2>

        <ComingFilterMovements
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setOptionStatusId={setOptionStatusId}
        />
        <ComingFilterMovementsTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          optionStatusId={optionStatusId}
        />
      </div>
    </div>
  );
};

export default ComingOutMovements;

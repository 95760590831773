import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const paymentsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Отчеты',
    url: '/reports/purchase/invoice'
  },
  {
    label: 'Продажа',
    url: '/reports/sale/payments'
  },

  {
    label: 'По платежам',
    active: true
  }
];

const ByPayment = () => {
  return (
    <div>
      <PageBreadcrumb items={paymentsBreadcrumbItems} />
      <div>
        <h2 className="mb-5">По платежам</h2>
      </div>
    </div>
  );
};

export default ByPayment;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
// import FeatherIcon from 'feather-icons-react';

import Button from 'components/base/Button';
import RateTable from './RateTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { RateType, rateBreadcrumbItems } from 'data/rate';
import CreateRate from './CreateRate';
import legalEntityCurrencyRateService from 'service/legalEntityCurrencyRate';

const Rate = () => {
  const [openRate, setOpenRate] = useState(false);
  const [selectedRateId, setSelectedRateId] = useState('');

  //Get Legal entity
  const { data, refetch } = useQuery(['GET_LEGAL_ENTITY_CURRENCY_RATE'], () => {
    const res = legalEntityCurrencyRateService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entity_currency_rates);
    return res;
  });

  const legalData: RateType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          base: items?.from_currency_rate,
          main_currency: items?.from_currency?.name?.ru,
          convertible: items?.to_currency_rate,
          units: items?.to_currency?.name?.ru,
          /* prettier-ignore */
          create_date: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  // const handleDelete = (id: any) => {
  //   legalEntityCurrencyRateService.deleteRate(id).finally(() => {
  //     refetch();
  //     setSelectedRateId('');
  //   });
  // };

  const rateTableColumns: ColumnDef<RateType>[] = [
    // {
    //   id: 'action',
    //   cell: rowData => (
    //     <div className="d-flex justify-content-center gap-2">
    //       <Button
    //         variant="hover"
    //         className="p-2"
    //         onClick={() => {
    //           setOpenRate(true);
    //           setSelectedRateId(rowData?.row?.original?.guid);
    //         }}
    //       >
    //         <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
    //       </Button>
    //       {/* <Button
    //         variant="hover"
    //         className="p-2"
    //         onClick={() => handleDelete(rowData?.row?.original?.guid)}
    //       >
    //         <FeatherIcon
    //           icon="trash-2"
    //           className="cursor-pointer text-danger"
    //           size={20}
    //         />
    //       </Button> */}
    //     </div>
    //   ),
    //   meta: {
    //     headerProps: { style: { width: '5%' } },
    //     cellProps: { className: 'text-center' }
    //   }
    // },
    {
      accessorKey: 'base',
      header: 'Единица основой валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'main_currency',
      header: 'Основная валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'convertible',
      header: 'Конвертируемая валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица конвертируемая валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'create_date',
      header: 'Дата создания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: legalData,
    columns: rateTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={rateBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Курсы валюты</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder="Поиск Курсы валюты"
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button variant="primary" onClick={() => setOpenRate(true)}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Новый
                </Button>
                {/* <Button variant="primary">Применить</Button> */}
              </div>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <RateTable />
          </div>

          <Modal
            show={openRate}
            onHide={() => {
              setOpenRate(false);
              setSelectedRateId('');
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Новый
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateRate
                setOpenRate={setOpenRate}
                selectedRateId={selectedRateId}
                refetch={refetch}
                setSelectedRateId={setSelectedRateId}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Rate;

import { PropsWithChildren } from 'react';
import Alerts from 'components/alerts';

const AlertsProvider = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Alerts />
      {children}
    </>
  );
};

export default AlertsProvider;

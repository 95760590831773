/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const productGroupService = {
  getProductGroup: (params: any) => request.get('/group-product', { params }),
  createProductGroup: (data: any) => request.post('/group-product', data),
  deleteProductGroup: (id: any) => request.delete(`/group-product/${id}`)
};

export default productGroupService;

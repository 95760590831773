/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const accountsService = {
  getList: (params: any) => request.get('/guidebook/account', { params }),
  getAccountById: (id: any) => request.get(`/guidebook/account/${id}`),
  createAccount: (data: any) => request.post('/guidebook/account', data),
  deleteAccount: (id: any) => request.delete(`/guidebook/account/${id}`),
  updateAccount: (id: any, data: any) =>
    request.put(`/guidebook/account/${id}`, data)
};

export default accountsService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
// prettier-ignore
const usePageSearchParams = (): [
  URLSearchParams,
  (newSearchParams: any) => void
] => {
  // prettier-ignore
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(window.location.search)
  );

  const updateSearchParams = (newSearchParams: any) => {
    const params = new URLSearchParams(newSearchParams);
    setSearchParams(params);
    // Update the URL without refreshing the page
    window.history.replaceState({}, '', `?${params.toString()}`);
  };

  useEffect(() => {
    const handlePopState = () => {
      setSearchParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return [searchParams, updateSearchParams];
};

export default usePageSearchParams;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import reportInvoiceService from 'service/reportInvoice';

export type IncomeData = {
  product_name: string;
  barcode: string;
  warehouse: string;
  currency: string;
  unit: string;
  supplier: string;
  category: string;
  purchase_price: string;
  quantity: string;
  price_cost: string;
  paid: string;
  gross_income: string;
  date: string;
};

export const incomeDataTableColumns: ColumnDef<IncomeData>[] = [
  {
    accessorKey: 'order_number',
    header: '№',
    cell: rowData => {
      const orderNumber = rowData.row.index + 1; // Calculate the order number based on the row index
      return <div>{orderNumber}</div>;
    },
    meta: {
      headerProps: { style: { width: '5%' } },
      cellProps: { className: 'text-900 p-2' }
    }
  },
  {
    accessorKey: 'product_name',
    header: 'Наименование товара',
    meta: {
      cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
    }
  },
  {
    accessorKey: 'barcode',
    header: 'Штрих-код',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'warehouse',
    header: 'Склад',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'unit',
    header: 'Единица измерения',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'supplier',
    header: 'Поставщик',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'category',
    header: 'Категория',
    meta: {
      cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
    }
  },
  {
    accessorKey: 'purchase_price',
    header: 'Закупочная цена',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'quantity',
    header: 'Кол.во',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'price_cost',
    header: 'Цена продажи',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'paid',
    header: 'Оплачена',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'gross_income',
    header: 'Валовый доход',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'date',
    header: 'Дата создания',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface IncomeTableProps {
  generateTableData?: any;
  setGeneratedTableData?: any;
  getName?: any;
  getBarcode?: any;
  getFilterData?: any;
}

const IncomeTable = ({
  generateTableData,
  setGeneratedTableData,
  getName,
  getBarcode,
  getFilterData
}: IncomeTableProps) => {
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  // Initialize today's date in milliseconds
  const todayStart = new Date().setHours(0, 0, 0, 0);
  const todayEnd = new Date().setHours(0, 0, 0, 0);

  const { data, refetch } = useQuery(
    ['GET_REPORTS_INCOME', getName, getBarcode],
    async () => {
      const res = await reportInvoiceService
        .getReportIncomeList({
          offset: 0,
          limit: 100,
          currency_id: Object.keys(getFilterData).length === 0 && currencyId,
          date_from: Object.keys(getFilterData).length === 0 && todayStart,
          date_to:
            Object.keys(getFilterData).length === 0 && todayEnd + 86400000,
          name: getName,
          barcode: getBarcode,
          ...getFilterData
        })
        .then((res: any) => res?.list);
      return res;
    },
    {
      enabled:
        getName.length === 0 ||
        getName.length > 2 ||
        getBarcode.length === 0 ||
        getBarcode.length > 2 ||
        !!currencyId ||
        !!getFilterData
    }
  );

  const reportData: IncomeData[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        const packageQuantity = items?.product?.package_quantity || 1;
        const quantity = items?.quantity || 0;
        const fullPackages = Math.floor(quantity / packageQuantity);
        const remainingQuantity = quantity % packageQuantity;
        const quantityDisplay = `${fullPackages}/${remainingQuantity}`;

        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          warehouse: items?.product?.ware_house?.name,
          unit: items?.product?.nomenclature?.measure_unit?.name?.ru,
          currency: items?.currency?.name?.ru,
          buyyer: items?.user?.first_name,
          category: items?.product?.nomenclature?.category?.name?.ru,
          purchase_price: items?.price_in,
          paid: items?.payment_amount,
          discrepancy: items?.payment_difference,
          price_cost: items?.price,
          gross_income: items?.income_amount ?? 0,
          total_payment_price: items?.total_payment_amount,
          payment_procedure: items?.payment_order?.name?.ru,
          supplier: items?.legal_entity?.name,
          quantity:
            items?.product?.nomenclature?.measure_unit_id ===
            '443bfff1-61e0-4057-8583-d040dc5a0454'
              ? quantityDisplay
              : quantity,
          /* prettier-ignore */
          date: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', '')
        };
      }) ?? [];

    if (resData) {
      setGeneratedTableData(resData);
    }

    return resData;
  }, [data]);

  console.log(reportData);

  const table = useAdvanceTable({
    data: generateTableData,
    columns: incomeDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [getName, getBarcode]);
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default IncomeTable;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
  token: {},
  user: {},
  legal_entity: {},
  send_otp_id: {},
  phone_number: {}
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.token = payload.token;
      state.isAuth = true;
      state.user = payload.user;
      state.legal_entity = payload.legal_entity;
      state.send_otp_id = payload.send_otp_id;
      state.phone_number = payload.phone_number;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    logout: () => initialState
  }
});

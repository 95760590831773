import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Icon,
  UilCube,
  UilChartPie,
  UilMoneyWithdrawal
} from '@iconscout/react-unicons';
import {
  faEdit,
  faChartSimple,
  faCalculator,
  faCogs,
  faCalendarXmark
} from '@fortawesome/free-solid-svg-icons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'Главная страница',
        icon: 'home',
        path: '/',
        pathName: 'dashboard',
        active: true
      }
    ]
  },
  {
    label: 'legal',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'Юридические лица',
        icon: 'briefcase',
        path: '/legal',
        pathName: 'legal-entites',
        active: true
      }
    ]
  },
  {
    label: 'clients',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Управление',
        iconSet: 'font-awesome',
        icon: faCogs,
        active: true,
        flat: true,
        pages: [
          {
            name: 'Пользователи',
            path: '/clients',
            pathName: 'clients',
            active: true
          },
          {
            name: 'Роли',
            path: '/roles',
            pathName: 'roles',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'directories',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Справочники',
        icon: 'book-open',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Склад',
            path: '/directories/stock',
            pathName: 'stock-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'Валюта',
            path: '/directories/currency',
            pathName: 'currency-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'Курсы валюты',
            path: '/directories/rate',
            pathName: 'rate-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'Тип оплаты',
            path: '/directories/payment',
            pathName: 'payment-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'НДС',
            path: '/directories/nds',
            pathName: 'nds-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          {
            name: 'Кошелек',
            path: '/directories/wallet',
            pathName: 'wallet-directories',
            topNavIcon: 'shopping-cart',
            active: true
          },
          // {
          //   name: 'Счета',
          //   path: '/directories/accounts',
          //   pathName: 'accounts-directories',
          //   topNavIcon: 'clipboard',
          //   active: true
          // },
          {
            name: 'Финансовый счет',
            path: '/directories/articles',
            pathName: 'articles-directories',
            active: true
          },

          {
            name: 'Касса',
            path: '/directories/cash',
            pathName: 'cash-directories',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'products',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Товары',
        icon: 'database',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Мои товары',
            path: '/my-products',
            pathName: 'myProducts',
            active: true
          },

          {
            name: 'Весы и весовые товары',
            path: '/scales',
            pathName: 'scales',
            active: true
          },
          {
            name: 'Группировка товаров',
            path: '/products',
            pathName: 'products',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'purchase',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Закуп',
        icon: 'shopping-cart',
        path: '/purchase/create',
        pathName: 'create-purchase',
        active: true
      }
    ]
  },

  {
    label: 'returning',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Возвраты',
        icon: 'download-cloud',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Исходящий',
            path: '/returning/outgoing',
            pathName: 'outgoing-returning',
            active: true
          },
          {
            name: 'Входящий ',
            path: '/returning/coming',
            pathName: 'coming-returning',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'bonuses',
    active: true,
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: 'Бонусы',
        icon: 'award',
        path: '/bonuses',
        pathName: 'bonuses',
        active: true
      }
    ]
  },

  {
    label: 'applications',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Заявки',
        icon: 'file-text',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Исходящий',
            path: '/applications/outgoing',
            pathName: 'outgoing-applications',
            active: true
          },
          {
            name: 'Входящий ',
            path: '/applications/coming',
            pathName: 'coming-applications',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'movements',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Перемещения',
        icon: 'move',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Исходящий',
            path: '/movements/outgoing',
            pathName: 'outgoing-movements',
            active: true
          },
          {
            name: 'Входящий ',
            path: '/movements/coming',
            pathName: 'coming-movements',
            active: true
          }
        ]
      }
    ]
  },

  {
    label: 'dtkt',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Дт Кт',
        icon: 'user-check',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Поставщик',
            path: '/dtkt/provider',
            pathName: 'provider-dtkt',
            active: true
          },

          {
            name: 'Взаиморасчет с поставщиком',
            path: '/dtkt/suppliers',
            pathName: 'suppliers-dtkt',
            active: true
          },

          {
            name: 'Поставщик сроки погашения',
            path: '/dtkt/suppliers-maturity-date',
            pathName: 'suppliers-maturity-date-dtkt',
            active: true
          },
          {
            name: 'Заказчик',
            path: '/dtkt/customer',
            pathName: 'customer-dtkt',
            active: true
          },
          {
            name: 'Взаиморасчет с заказчиком',
            path: '/dtkt/buyyers',
            pathName: 'buyyers-dtkt',
            active: true
          },
          {
            name: 'Заказчик сроки погашения',
            path: '/dtkt/customer-maturity-date',
            pathName: 'customer-maturity-date-dtkt',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'inventory',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Инвентаризация',
        icon: 'codesandbox',
        active: true,
        flat: true,
        pages: [
          {
            name: 'Создать инвентаризацию',
            path: '/inventory/create-inventory',
            pathName: 'create-inventory',
            active: true
          },

          {
            name: 'Информация об инвентаризации',
            path: '/inventory/info',
            pathName: 'info-inventory',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'write-off',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Списание',
        iconSet: 'font-awesome',
        icon: faCalendarXmark,
        active: true,
        flat: true,
        pages: [
          {
            name: 'По итог. инвентаризацие',
            path: '/write-off/write-off-inventory',
            pathName: 'write-off-inventory',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'expenses',
    active: true,
    icon: UilMoneyWithdrawal,
    labelDisabled: true,
    pages: [
      {
        name: 'Затраты',
        icon: 'package',
        path: '/expenses',
        pathName: 'expenses',
        active: true
      }
    ]
  },
  {
    label: 'reports',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Отчеты',
        iconSet: 'font-awesome',
        icon: faEdit,
        active: true,
        flat: true,
        pages: [
          {
            name: 'Закуп',
            active: true,
            pages: [
              {
                name: 'Закуп по накладной',
                path: '/reports/purchase/invoice',
                pathName: 'invoice-purchase-reports',
                active: true
              },
              {
                name: 'Закуп по товарам',
                path: '/reports/purchase/goods',
                pathName: 'goods-purchase-reports',
                active: true
              }
            ]
          },
          {
            name: 'Продажа',
            active: true,
            pages: [
              // {
              //   name: 'По платежам',
              //   path: '/reports/sale/payments',
              //   pathName: 'payments-sale-reports',
              //   active: true
              // },
              {
                name: 'Продажа по товарам',
                path: '/reports/sale/product',
                pathName: 'product-sale-reports',
                active: true
              }
            ]
          },
          {
            name: 'Остаток',
            path: '/reports/remainder',
            pathName: 'remainder-reports',
            active: true
          },
          {
            name: 'Валовый доход',
            path: '/reports/income',
            pathName: 'income-reports',
            active: true
          },
          {
            name: 'Касса',
            path: '/reports/cash-info',
            pathName: 'cash-info-reports',
            active: true
          }
          // {
          //   name: 'Срок годности',
          //   pathName: 'term-reports'
          // },
          // {
          //   name: 'Возврат',
          //   pathName: 'return-reports'
          // }
        ]
      }
    ]
  },
  {
    label: 'analytics',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Аналитика',
        iconSet: 'font-awesome',
        icon: faChartSimple,
        active: true,
        flat: true,
        pages: [
          {
            name: 'Переуценка товаров',
            active: true,
            pages: [
              {
                name: 'Переуценка по накладной',
                path: '/analytics/revaluation/revaluation-invoice',
                pathName: 'invoice-revaluation-analytics',
                active: true
              },
              {
                name: 'Переуценка по товарам',
                path: '/analytics/revaluation/revaluation-product',
                pathName: 'product-revaluation-analytics',
                active: true
              }
            ]
          },
          {
            name: 'АВС анализ',
            path: '/analytics/abc',
            pathName: 'analysis-analytics',
            active: true
          },
          {
            name: 'Автозаказ',
            path: '/analytics/autoorder',
            pathName: 'autoorder-analytics',
            active: true
          },
          {
            name: 'Замороженный средство',
            path: '/analytics/frozen-funds',
            pathName: 'frozen-funds-analytics',
            active: true
          },
          {
            name: 'Упущенная прибыль',
            path: '/analytics/profit',
            pathName: 'profit-analytics',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'finance',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'Финансы',
        iconSet: 'font-awesome',
        icon: faCalculator,
        active: true,
        flat: true,
        pages: [
          {
            name: 'ДДС',
            path: '/finance/cashflow',
            pathName: 'cashflow-finance',
            active: true
          },
          {
            name: 'P&L',
            path: '/finance/profit-and-lost',
            pathName: 'profit-and-lost-finance',
            active: true
          }
        ]
      }
    ]
  },
  {
    label: 'settings',
    active: true,
    icon: UilMoneyWithdrawal,
    labelDisabled: true,
    pages: [
      {
        name: 'Настройки ',
        icon: 'settings',
        path: '/settings',
        pathName: 'settings',
        active: true
      }
    ]
  }
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import Button from 'components/base/Button';
import CInputMask from 'components/forms/CInputMask';
import useAuth from 'service/auth/useAuth';
import { authActions } from 'store/auth/auth.slice';

const SignUpPhone = () => {
  const schema = yup.object().shape({
    phone_number: yup
      .string()
      .required('Обязательное поле')
      .matches(
        //eslint-disable-next-line
        /^(\+998)\s\(([1-9][0-9])\)\s([0-9]{3})-([0-9]{2})-([0-9]{2})$/,
        'Введите действительный номер телефона'
      )
  });

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { sendOtp } = useAuth({
    sendOtpProps: {
      onSuccess: (value: any) => {
        const data = getValues();
        const states = {
          send_otp_id: value.data.id,
          phone_number: data?.phone_number?.replace(/\D/g, '')
        };
        dispatch(authActions.login(states));
        navigate('/verify-otp');
      }
    }
  });

  const onSubmit = async (data: any) => {
    const convertedPhone = data?.phone_number?.replace(/\D/g, '');
    setLoading(true);
    try {
      await sendOtp.mutateAsync({
        phone_number: `+${convertedPhone}`
      });
    } catch (error: any) {
      setLoading(false);

      console.log('Send Otp error==>', error);
    }
  };

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div className="text-center mb-7">
        <h3 className="text-1000">Зарегистрироваться</h3>
        <p className="text-700">Напишите номер телефона и зарегистрируйте</p>
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label>Номер телефона</Form.Label>
        <div className="form-icon-container">
          <CInputMask
            name="phone_number"
            //eslint-disable-next-line
            placeholder="+998 (99) 999-99-99"
            //eslint-disable-next-line
            mask="+\9\9\8 (99) 999-99-99"
            maskChar="_"
            control={control}
            icon={faPhone}
            isInvalid={!!errors.phone_number}
            errors={errors}
            focusInput={true}
          />
        </div>
      </Form.Group>

      <Button
        loading={loading}
        type="submit"
        variant="primary"
        className="w-100 mb-2"
      >
        Отправлять
      </Button>

      <div className="text-center">
        <Link to={`/sign-in`} className="fs-9 fw-bold">
          Вернуться к входу
        </Link>
      </div>
    </Form>
  );
};

export default SignUpPhone;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const orderService = {
  getOrderById: (id: any) => request.get(`/order/${id}`),
  createOrder: (data: any) => request.post('/order', data),
  deleteOrder: (id: any) => request.delete(`/order/${id}`),
  updateOrder: (id: any, data: any) => request.put(`/order/${id}`, data)
};

export default orderService;

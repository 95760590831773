/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import NewReturningTableSearch from './NewReturningTableSearch';
import NewReturningTable from './NewReturningTable';
import usePageSearchParams from 'utils/usePageSearchParams';
import returnService from 'service/return';
import returnOutService from 'service/returnOut';
import returnItemService from 'service/returnItemService';
import { showAlert } from 'store/alert/alert.thunk';
import useFocusRefs from 'utils/useFocusRefs';
import legalEntitySettingsService from 'service/legalEntitySettings';
import './styles.scss';

const NewReturning = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();
  const newReturningId = pathname.split('/').pop();
  const senderId = searchParams?.get('sender_id');
  const dispatch: Dispatch<any> = useDispatch();
  const { setRef, focusRef } = useFocusRefs();

  const [returnItemData, setReturnItemData] = useState([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);
  const [isAutoTab, setIsAutoTab] = useState(false);

  useQuery(['GET_SETTINGS'], async () => {
    await legalEntitySettingsService
      .getList({
        offset: 0,
        limit: 20
      })
      .then((res: any) => {
        setIsAutoTab(res?.legal_entity_settings[0].is_auto_tab ?? 0);
      });
  });

  console.log('returnItemData', returnItemData);

  const newReturningBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Возвраты',
      url: '/returning/outgoing'
    },
    {
      label: 'Исходящий',
      url: '/returning/outgoing'
    },
    {
      label: 'Новый',
      active: true
    }
  ];

  const sumOfQuantity = returnItemData.reduce((acc: any, item: any) => {
    const amount = item?.amount === undefined ? 0 : +item.amount;
    return acc + amount;
  }, 0);

  const sumOfPriceWholsale = returnItemData.reduce((acc: any, item: any) => {
    const price = item?.price === undefined ? 0 : +item.price;
    return acc + price;
  }, 0);

  const sumOfOrderPrice = sumOfQuantity * sumOfPriceWholsale;

  const handleSave = () => {
    setSaveLoading(true);

    const invalidItems = returnItemData?.filter(
      (el: any) => !el?.return_reason_id
    );

    if (invalidItems.length > 0) {
      dispatch(
        showAlert({
          title: 'Пожалуйста, укажите причину возврата для товара!'
        })
      );
      setSaveLoading(false);
      return;
    }

    const createData = returnItemData?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      parent_product_id: el?.parent_product_id,
      return_reason_id: el?.return_reason_id,
      price: el?.price ? +el?.price : 0,
      quantity: el?.amount ? +el?.amount : 0,
      package_quantity: el?.package_quantity ? +el?.package_quantity : 0,
      status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557' // static new status id
    }));

    const updateData = {
      return_id: newReturningId,
      return_items: createData
    };

    if (newReturningId !== '') {
      returnItemService.createReturnItem(updateData).then((createres: any) => {
        if (createres) {
          returnService.getReturnById(newReturningId).then((res: any) => {
            if (res) {
              returnOutService
                .updateReturnOut(newReturningId, {
                  ...res,
                  status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557'
                })
                .then((outresponse: any) => {
                  if (outresponse) {
                    dispatch(
                      showAlert({
                        title: 'Новая заявка сохранен!',
                        type: 'success'
                      })
                    );
                    setSaveLoading(false);
                  }
                });
            }
          });
        }
      });
    }
  };

  const handleSend = () => {
    const invalidItems = returnItemData?.filter(
      (el: any) => !el?.return_reason_id
    );

    if (invalidItems.length > 0) {
      dispatch(
        showAlert({
          title: 'Пожалуйста, укажите причину возврата для товара.!'
        })
      );
      setSendLoading(false);
      return;
    }

    const createData = returnItemData?.map((el: any) => ({
      barcode: el?.barcode,
      name: el?.product_name,
      currency_id: el?.currency_id,
      measure_unit_id: el?.measure_unit_id,
      nomenclature_id: el?.nomenclature_id,
      product_id: el?.product_id,
      parent_product_id: el?.parent_product_id,
      price: el?.price ? +el?.price : 0,
      quantity: el?.amount ? +el?.amount : 0,
      return_reason_id: el?.return_reason_id,
      package_quantity: el?.package_quantity ? +el?.package_quantity : 0,
      status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b' // static new status id
    }));

    const updateData = {
      return_id: newReturningId,
      return_items: createData
    };

    if (newReturningId !== '' && createData?.length > 0) {
      setSendLoading(true);

      returnItemService.createReturnItem(updateData).then((createres: any) => {
        if (createres) {
          returnService.getReturnById(newReturningId).then((res: any) => {
            if (res) {
              returnOutService
                .updateReturnOut(newReturningId, {
                  ...res,
                  status_id: '2b22cd85-070e-4779-afcb-8f0be603b60b'
                })
                .then((outresponse: any) => {
                  if (outresponse) {
                    setSendLoading(false);
                    dispatch(
                      showAlert({
                        title: 'Новая возврат отправлен!',
                        type: 'success'
                      })
                    );
                    navigate('/returning/outgoing');
                  }
                });
            }
          });
        }
      });
    } else {
      dispatch(
        showAlert({
          title: 'Невозможно отправить возврат.Товар еще не добавлено'
        })
      );
    }
  };

  return (
    <div>
      <PageBreadcrumb items={newReturningBreadcrumbItems} />
      <div>
        <h2>Новый</h2>

        <NewReturningTableSearch
          returnItemData={returnItemData}
          setReturnItemData={setReturnItemData}
          senderId={senderId}
          focusRef={focusRef}
          isAutoTab={isAutoTab}
        />
        <NewReturningTable
          returnItemData={returnItemData}
          newReturningId={newReturningId}
          setReturnItemData={setReturnItemData}
          setRef={setRef}
        />
      </div>

      <div className="footer-return-info">
        <div className="box">
          <p className="mb-0 text-bold">Итого на сумму: </p>
          <span>{sumOfOrderPrice ?? 0}</span>
        </div>

        <div className="main-btn-group">
          <Button
            type="button"
            variant="outline-primary"
            onClick={() => handleSave()}
            loading={saveLoading}
          >
            Сохранить
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => handleSend()}
            loading={sendLoading}
          >
            Отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewReturning;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const inventoryService = {
  getList: (params: any) => request.get('/inventory', { params }),
  getInventoryById: (id: any) => request.get(`/inventory/${id}`),
  createInventory: (data: any) => request.post('/inventory', data),
  deleteInventory: (id: any) => request.delete(`/inventory/${id}`),
  updateInventory: (id: any, data: any) => request.put(`/inventory/${id}`, data)
};

export default inventoryService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
// import { useQuery } from 'react-query';
// import { useDispatch } from 'react-redux';
// import { Dispatch } from 'redux';

import Button from 'components/base/Button';
// import { isValidGtin } from 'utils/isValidGtin';
// import returnItemService from 'service/returnItemService';
// import { showAlert } from 'store/alert/alert.thunk';
import OpenSearchReturningTable from './OpenSearchReturningTable';
import './styles.scss';

interface NewReturningTableSearchProps {
  setReturnItemData?: any;
  senderId?: any;
  returnItemData?: any;
  focusRef?: any;
  isAutoTab?: any;
}

const NewReturningTableSearch = ({
  setReturnItemData,
  senderId,
  returnItemData,
  focusRef,
  isAutoTab
}: NewReturningTableSearchProps) => {
  // const dispatch: Dispatch<any> = useDispatch();
  // const inputRef = useRef<HTMLInputElement | any>(null);

  const [openSearch, setOpenSearch] = useState(false);
  // const [searchedValue, setsearchedValue] = useState('');

  // const { data } = useQuery(
  //   ['GET_RETURN_ITEM_PRODUCT', senderId, isValidGtin(searchedValue)],
  //   async () => {
  //     if (senderId && isValidGtin(searchedValue)) {
  //       const res = await returnItemService
  //         .getBySenderId(senderId, {
  //           offset: 0,
  //           limit: 20,
  //           barcode: searchedValue ? searchedValue : undefined
  //         })
  //         .then((res: any) => {
  //           return res?.products;
  //         });
  //       return res;
  //     }
  //   },
  //   {
  //     enabled: !!senderId && !!isValidGtin(searchedValue)
  //   }
  // );

  // const handleGetProduct = useCallback(
  //   async (e: any) => {
  //     if (e?.key !== 'Enter') return;

  //     if (senderId && isValidGtin(searchedValue)) {
  //       try {
  //         const res = await returnItemService
  //           .getBySenderId(senderId, {
  //             offset: 0,
  //             limit: 20,
  //             barcode: searchedValue ? searchedValue : undefined
  //           })
  //           .then((res: any) => res);

  //         const resCurrency = res?.currency?.name?.ru;
  //         const resData = res?.products ?? [];

  //         const createdData = resData?.map((items: any) => ({
  //           product_name: items?.nomenclature?.name,
  //           variation:
  //             items?.nomenclature?.is_variation === true ? 'Да' : 'Нет',
  //           barcode: items?.nomenclature?.barcode,
  //           units: items?.nomenclature?.measure_unit?.name?.ru,
  //           // this dataes need for static rendering dataes
  //           unit: items?.nomenclature?.measure_unit?.name?.ru,
  //           measure_unit_id: items?.nomenclature?.measure_unit?.id,
  //           amount: items?.quantity ?? 1,
  //           full_cost: items?.price_whosale,
  //           nomenclature_id: items?.nomenclature_id,
  //           product_id: items?.id,
  //           product_parent_id: items?.id,
  //           price: items?.price,
  //           price_in: items?.price_in,
  //           price_whosale: items?.price_whosale,
  //           guid: items?.nomenclature?.id,
  //           is_vat: items?.is_vat ? items?.is_vat : false,
  //           currency: resCurrency
  //         }));

  //         if (createdData?.length === 0) {
  //           dispatch(
  //             showAlert({
  //               title: 'Штрих-код не найден!'
  //             })
  //           );
  //           return;
  //         }

  //         if (returnItemData?.length > 0) {
  //           const foundProductIndex = returnItemData?.findIndex((item: any) => {
  //             return item?.barcode === searchedValue;
  //           });
  //           if (foundProductIndex !== -1) {
  //             const updatedProductInvoiceData = [...returnItemData];
  //             updatedProductInvoiceData[foundProductIndex].amount += 1;
  //             setReturnItemData(updatedProductInvoiceData);
  //             return;
  //           } else {
  //             setReturnItemData((prevData: any) => {
  //               const newData = [...createdData, ...prevData];

  //               if (isAutoTab) {
  //                 // when auto tab true focus to quantity
  //                 setTimeout(() => {
  //                   const firstProductIndex = 0;
  //                   focusRef(`amount_${firstProductIndex}`);
  //                 }, 500);
  //               }
  //               return newData;
  //             });
  //           }
  //         } else {
  //           setReturnItemData((prevData: any) => {
  //             const newData = [...createdData, ...prevData];

  //             if (isAutoTab) {
  //               // when auto tab true focus to quantity
  //               setTimeout(() => {
  //                 const firstProductIndex = 0;
  //                 focusRef(`amount_${firstProductIndex}`);
  //               }, 500);
  //             }
  //             return newData;
  //           });
  //         }
  //       } catch (error) {
  //         dispatch(
  //           showAlert({
  //             title: 'Штрих-код не найден!'
  //           })
  //         );
  //       }
  //     }
  //   },
  //   [senderId, searchedValue, data, returnItemData, dispatch]
  // );

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  // }, [inputRef.current]);

  return (
    <div>
      <div className="return-rows">
        {/* <Form.Floating>
          <Form.Control
            ref={inputRef}
            value={searchedValue}
            type="text"
            placeholder="Выведите или отсканируйте штрих-код"
            onChange={e => setsearchedValue(e?.target?.value)}
            onKeyDown={e => handleGetProduct(e)}
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">
            Выведите или отсканируйте штрих-код
          </label>
        </Form.Floating> */}

        <Form.Group className="return-btn">
          <Button
            type="button"
            variant="primary"
            onClick={() => setOpenSearch(true)}
            style={{ height: '48px' }}
          >
            Добавить товар
          </Button>
        </Form.Group>
      </div>

      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Поиск</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OpenSearchReturningTable
            setOpenSearch={setOpenSearch}
            setReturnItemData={setReturnItemData}
            returnItemData={returnItemData}
            senderId={senderId}
            isAutoTab={isAutoTab}
            focusRef={focusRef}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewReturningTableSearch;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const tableSettingsService = {
  getTableSettingsById: (id: any) => request.get(`/field-setting/${id}`),
  updateTableSettings: (id: any, data: any) =>
    request.put(`/field-setting/${id}`, data)
};

export default tableSettingsService;

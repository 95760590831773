/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useSelector } from 'react-redux';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import RemainderFilter from './RemainderFilter';
import RemainderTable from './RemainderTable';

import styles from './styles.module.scss';

export const remainderBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Отчеты',
    url: '/reports/remainder'
  },

  {
    label: 'Остаток',
    active: true
  }
];

const Remainder = () => {
  const currencyName = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency?.name?.ru
  );

  const [generateTableData, setGeneratedTableData] = useState([]);
  const [savedValues, setSavedValues] = useState([]);
  const [getName, setGetName] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [getFilterData, setGetFilterData] = useState({});
  const [selectedCurrencyName, setCurrencyName] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalIncome, setTotalincome] = useState(0);
  const [totalSale, setTotalSale] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div>
      <PageBreadcrumb items={remainderBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Остаток</h2>

        <RemainderFilter
          setGeneratedTableData={setGeneratedTableData}
          savedValues={savedValues}
          setSavedValues={setSavedValues}
          setGetName={setGetName}
          setGetBarcode={setGetBarcode}
          setGetFilterData={setGetFilterData}
          getName={getName}
          getBarcode={getBarcode}
          setCurrencyName={setCurrencyName}
          setTotalQuantity={setTotalQuantity}
          setTotalincome={setTotalincome}
          setTotalSale={setTotalSale}
          currentPage={currentPage}
          setPageCount={setPageCount}
        />
        <RemainderTable
          generateTableData={generateTableData}
          setGeneratedTableData={setGeneratedTableData}
          getName={getName}
          getBarcode={getBarcode}
          getFilterData={getFilterData}
          savedValues={savedValues}
          setTotalQuantity={setTotalQuantity}
          setTotalincome={setTotalincome}
          setTotalSale={setTotalSale}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pageCount}
          setPageCount={setPageCount}
        />

        <div className={styles.footerRemain}>
          <div className={styles.box}>
            <p className="mb-0" style={{ fontWeight: '800' }}>
              Итого Кол.во:
            </p>
            <span style={{ fontWeight: '800' }}>
              {totalQuantity
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(totalQuantity)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className={styles.box}>
            <p className="mb-0" style={{ fontWeight: '800' }}>
              Итого сумма поступлений: (
              {selectedCurrencyName !== ''
                ? selectedCurrencyName
                : currencyName}
              )
            </p>
            <span style={{ fontWeight: '800' }}>
              {totalIncome
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(totalIncome)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className={styles.box}>
            <p className="mb-0" style={{ fontWeight: '800' }}>
              Итого сумма продажи: (
              {selectedCurrencyName !== ''
                ? selectedCurrencyName
                : currencyName}
              )
            </p>
            <span style={{ fontWeight: '800' }}>
              {totalSale
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(totalSale)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Remainder;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const bonusService = {
  getList: (params: any) => request.get('/bonus', { params }),
  getBonusById: (id: any) => request.get(`/bonus/${id}`),
  createBonus: (data: any) => request.post('/bonus', data),
  deleteBonus: (id: any) => request.delete(`/bonus/${id}`),
  updateBonus: (id: any, data: any) => request.put(`/bonus/${id}`, data)
};

export default bonusService;

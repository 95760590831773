/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returnService = {
  getReturnById: (id: any) => request.get(`/return/${id}`),
  createReturn: (data: any) => request.post('/return', data),
  deleteReturn: (id: any) => request.delete(`/return/${id}`),
  updateReturn: (id: any, data: any) => request.put(`/return/${id}`, data)
};

export default returnService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';

import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';

const WalletTable = () => {
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(`/directories/wallet/add/${row?.original?.guid}`);
  };
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        navigateTo={navigateTo}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default WalletTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const paymentOrderService = {
  getList: (params: any) => request.get('/guidebook/payment-order', { params }),
  getPaymentOrderById: (id: any) =>
    request.get(`/guidebook/payment-order/${id}`),
  createPaymentOrder: (data: any) =>
    request.post('/guidebook/payment-order', data),
  deletePaymentOrder: (id: any) =>
    request.delete(`/guidebook/payment-order/${id}`),
  updatePaymentOrder: (id: any, data: any) =>
    request.put(`/guidebook/payment-order/${id}`, data)
};

export default paymentOrderService;

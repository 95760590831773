/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { NewReturningData } from 'data/newreturning';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import returnOutService from 'service/returnOut';
import returnService from 'service/return';
import Button from 'components/base/Button';
import SupperModal from 'components/modals/SupperModal';

interface NewReturningDataTableProps {
  setOpenFolder: (show: boolean) => void;
}
const NewReturningDataTable = ({
  setOpenFolder
}: NewReturningDataTableProps) => {
  const navigate = useNavigate();
  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const { data, refetch } = useQuery(['GET_RETURN_OUT'], async () => {
    const res = await returnOutService
      .getList({
        offset: 0,
        limit: 100,
        status_id: 'd9f0bc1f-5245-428a-800b-d51aadde2557'
      })
      .then((res: any) => {
        return res?.returns;
      });
    return res;
  });

  const tableData: NewReturningData[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          orders: items?.return_number,
          sender: legalEntityName,
          recipient: items?.legal_entity?.name,
          currency: items?.currency?.name?.ru,
          date_create: new Date(items?.date_create)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),
          recipient_id: items?.recipient_id,
          guid: items?.id
        };
      }) ?? [];

    return resultData;
  }, [data]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const newApplicationsTableColumns: ColumnDef<NewReturningData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <Row>
            <Col className="d-flex gap-2">
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  navigate(
                    `/returning/outgoing/filter/newreturning/${rowData?.row?.original?.guid}?sender_id=${rowData?.row?.original?.recipient_id}`
                  );
                  setOpenFolder(false);
                }}
              >
                <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
              </Button>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => handleDelete(rowData?.row?.original?.guid)}
              >
                <FeatherIcon
                  icon="trash-2"
                  className="cursor-pointer text-danger"
                  size={20}
                />
              </Button>
            </Col>
          </Row>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-end' }
      }
    },

    {
      accessorKey: 'orders',
      header: '№ заказа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sender',
      header: 'Отправитель',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'recipient',
      header: 'Получатель',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: 'Дата создания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: tableData,
    columns: newApplicationsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      returnService.deleteReturn(selectedInvoiceId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <AdvanceTableProvider {...table}>
      <div>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Товар"
        bodyText="Вы действительно хотите удалить товара?"
      />
    </AdvanceTableProvider>
  );
};

export default NewReturningDataTable;

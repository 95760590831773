/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntityStatusService = {
  getList: (params: any) =>
    request.get('/guidebook/legal-entity-status', { params }),
  getStatusById: (id: any) =>
    request.get(`/guidebook/legal-entity-status/${id}`),
  createStatus: (data: any) =>
    request.post('/guidebook/legal-entity-status', data),
  deleteStatus: (id: any) =>
    request.delete(`/guidebook/legal-entity-status/${id}`),
  updateStatus: (id: any, data: any) =>
    request.put(`/guidebook/legal-entity-status/${id}`, data)
};

export default legalEntityStatusService;

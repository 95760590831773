/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const invoiceService = {
  getList: (params: any) => request.get('/invoice', { params }),
  getInvoiceById: (id: any) => request.get(`/invoice/${id}`),
  createInvoice: (data: any) => request.post('/invoice', data),
  deleteInvoice: (id: any) => request.delete(`/invoice/${id}`),
  updateInvoice: (id: any, data: any) => request.put(`/invoice/${id}`, data)
};

export default invoiceService;

import { Pagination } from '@mui/material';

interface AdvanceTableCPaginationProps {
  setCurrentPage?: (page: number) => void;
  count?: number;
  page?: number;
}

const customStyle = {
  '& .MuiButtonBase-root': {
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
    color: '#252C32',
    '&.Mui-selected': {
      color: '#fff',
      borderRadius: '0.375rem',
      backgroundColor: '#8200BF',
      '&:hover': {
        backgroundColor: '#8200BF'
      }
    },
    '&:hover': {
      backgroundColor: '#e3e6ed'
    }
  }
};

const AdvanceTableCPagination = ({
  setCurrentPage = () => {},
  count = 1,
  page = 1
}: AdvanceTableCPaginationProps) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px',
        paddingBottom: '16px',
        paddingLeft: '12px'
      }}
    >
      <div style={{ display: 'flex' }}></div>
      <Pagination
        sx={customStyle}
        shape="rounded"
        onChange={(e, val) => setCurrentPage(val)}
        count={count}
        page={page}
      />
    </div>
  );
};

export default AdvanceTableCPagination;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import returnItemService from 'service/returnItemService';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';

export type SearchDataType = {
  id: string;
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
  total_price: string;
  remainder: string;
  date_order: string;
};

export const searchTableColumns: ColumnDef<SearchDataType>[] = [
  {
    accessorKey: 'product_name',
    header: 'Наименование товара',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'variation',
    header: 'Вариация',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'barcode',
    header: 'Штрих-код',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'units',
    header: 'Единица измерения',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'total_price',
    header: 'Цена закуп',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'remainder',
    header: 'Остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'date_order',
    header: 'Дата закупа',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface OpenSearchReturningTableProps {
  setOpenSearch: (show: boolean) => void;
  returnItemData?: any;
  setReturnItemData?: any;
  senderId?: any;
  focusRef?: any;
  isAutoTab?: any;
}
const OpenSearchReturningTable = ({
  setOpenSearch,
  returnItemData,
  setReturnItemData,
  senderId,
  isAutoTab,
  focusRef
}: OpenSearchReturningTableProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');

  const { data } = useQuery(
    ['GET_PRODUCT', searchValue, senderId, getBarcode],
    () => {
      if (senderId !== '') {
        const res = returnItemService
          .getBySenderId(senderId, {
            offset: 0,
            limit: 100,
            name: searchValue,
            barcode: getBarcode
          })
          .then((res: any) => {
            return res;
          });

        return res;
      }
    },
    {
      enabled:
        !!senderId && (!searchValue || searchValue.length > 2 || !getBarcode)
    }
  );

  const searchData: SearchDataType[] = useMemo(() => {
    return (
      data?.products?.map((items: any) => {
        return {
          product_name:
            data?.is_product === true ? items?.name : items?.nomenclature?.name,
          barcode:
            data?.is_product === true
              ? items?.barcode
              : items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          total_price: items?.price_in,
          remainder: items?.quantity ?? 0,
          date_order: new Date(items?.date_created)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),

          // this dataes need for static rendering dataes
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ? items?.quantity : 1,
          current_balance: items?.quantity ? items?.quantity : 1,
          currency_id: items?.currency_id,
          package_quantity: items?.package_quantity ?? 0,
          nomenclature_id: items?.nomenclature_id,
          parent_product_id: items?.parent_product_id,
          product_id: items?.id,
          price: items?.price_in,
          is_vat: items?.is_vat ? items?.is_vat : false
        };
      }) ?? []
    );
  }, [data, searchValue, getBarcode]);

  const handleSelections = () => {
    const selectedDates = table
      .getSelectedRowModel()
      .rows.map(row => row.original);

    const updatedProductInvoiceData = [...returnItemData]; // Make a copy of the current state

    selectedDates.forEach(selectedDate => {
      const foundProductIndex = updatedProductInvoiceData.findIndex(
        item => item?.barcode === selectedDate.barcode
      );
      if (foundProductIndex !== -1) {
        updatedProductInvoiceData[foundProductIndex].amount += 1;
      } else {
        updatedProductInvoiceData.push(selectedDate);
      }
    });

    setReturnItemData(updatedProductInvoiceData);
    if (isAutoTab) {
      // when auto tab true focus to quantity
      setTimeout(() => {
        const firstProductIndex = 0;
        focusRef(`amount_${firstProductIndex}`);
      }, 500);
    }
    setOpenSearch(false);
  };

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <div className="mb-4 d-flex gap-2">
            <div className="form-icon-container">
              <Form.Floating>
                <Form.Control
                  type="text"
                  placeholder="Отсканируйте штрих-код"
                  onFocus={e => e.target.select()}
                  onChange={e => setGetBarcode(e.target.value)}
                  className="form-icon-input"
                  autoComplete="off"
                />
                <label
                  htmlFor="floatingInputCustom"
                  className="form-icon-label text-700"
                >
                  Отсканируйте штрих-код
                </label>
              </Form.Floating>
              <FontAwesomeIcon
                icon={faBarcode}
                className="text-900 fs-9 form-icon"
              />
            </div>
            <SearchBox
              placeholder="Выведите наименования товара для поиска"
              onChange={e => setSearchValue(e?.target?.value)}
            />
          </div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
          <div className="d-flex justify-content-end">
            <Button
              className="mt-4"
              variant="primary"
              onClick={() => handleSelections()}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default OpenSearchReturningTable;

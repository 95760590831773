/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import axios from 'axios';

const useCountryUnicode = () => {
  const [unicode, setUnicode] = useState('UZ');

  const getCountry = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response: any) => {
        setUnicode(response?.data?.country);
      })
      .catch((err: any) => {
        console.log('err --> ', err);
      });
  };

  useEffect(() => {
    getCountry();
  }, []);

  return unicode;
};

export default useCountryUnicode;

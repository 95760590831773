/* eslint-disable @typescript-eslint/no-explicit-any */
import CountUp from 'react-countup';
import { LegacyRef } from 'react';
import { useQuery } from 'react-query';

import paymentIcon from 'assets/img/icons/payment-icon.svg';
import contractIcon from 'assets/img/icons/contract-icon.svg';
import dashboardService from 'service/dashboard';
import styles from './styles.module.scss';

interface PaymentAndTransactionProps {
  selectedCurrencyId?: any;
  dateFrom?: any;
  dateTo?: any;
}

const PaymentAndTransaction = ({
  selectedCurrencyId,
  dateFrom,
  dateTo
}: PaymentAndTransactionProps) => {
  //Get RATING
  const { data } = useQuery(
    ['GET_RATING', dateFrom, dateTo, selectedCurrencyId],
    () => {
      if (selectedCurrencyId && dateFrom) {
        const res = dashboardService
          .getRating({
            offset: 0,
            limit: 10,
            date_from: dateFrom,
            date_to: dateTo,
            currency_id: selectedCurrencyId
          })
          .then((res: any) => res);
        return res;
      }
    },
    { enabled: !!dateFrom || !!dateTo || !!selectedCurrencyId }
  );

  const paymentAmount = data?.payments?.total_amount;
  const transactionAmount = data?.transactions?.total_amount;

  return (
    <div className={styles.PTInfo}>
      <div className={styles.PTBox}>
        <div className={styles.topInfo}>
          <div className={styles.paymenInfo}>
            <p className={styles.topTitle}>Платежи</p>
            <CountUp
              end={paymentAmount ?? 0}
              duration={2.75}
              suffix={
                selectedCurrencyId === 'd69b53bd-da93-4db6-9108-c31210ca5c8f'
                  ? ` сум`
                  : ' $'
              }
              separator=" "
              delay={0}
            >
              {({ countUpRef }) => (
                <div>
                  <span
                    className={styles.topCount}
                    ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                  ></span>
                </div>
              )}
            </CountUp>
          </div>
          <img src={paymentIcon} alt="icon" />
        </div>
        {data?.payments?.payment_methods?.map((items: any) => (
          <div key={items} className={styles.bottomInfo}>
            <ul className={styles.bTitles}>
              <li>{items?.payment_method?.name?.ru}</li>
            </ul>

            <ul className={styles.bCounts}>
              <li>
                {items?.amount
                  ? new Intl.NumberFormat('en-US', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                      useGrouping: true
                    })
                      .format(items?.amount)
                      .replace(/,/g, ' ')
                  : '0'}{' '}
                сум
              </li>
            </ul>
          </div>
        ))}
      </div>

      <div className={styles.PTBox}>
        <div className={styles.topInfo}>
          <div className={styles.paymenInfo}>
            <p className={styles.topTitle}>Транзакции</p>
            <CountUp
              end={transactionAmount ?? 0}
              duration={2.75}
              suffix=" штук"
              separator=" "
              delay={0}
            >
              {({ countUpRef }) => (
                <div>
                  <span
                    className={styles.topCount}
                    ref={countUpRef as LegacyRef<HTMLHeadingElement>}
                  ></span>
                </div>
              )}
            </CountUp>
          </div>
          <img src={contractIcon} alt="icon" />
        </div>
        {data?.transactions?.product_kind?.map((items: any) => (
          <div key={items} className={styles.bottomInfo}>
            <ul className={styles.bTitles}>
              <li>{items?.product_kind?.name?.ru}</li>
            </ul>

            <ul className={styles.bCounts}>
              <li>
                {Number.isInteger(items?.amount)
                  ? items?.amount?.toString()
                  : items?.amount?.toFixed(4) ?? 0}{' '}
                штук
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentAndTransaction;

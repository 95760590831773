import { Form, Row, Col } from 'react-bootstrap';
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';

import Button from 'components/base/Button';

interface AddIndividualProps {
  setopenIndividual: (show: boolean) => void;
}

const AddIndividual = ({ setopenIndividual }: AddIndividualProps) => {
  const { register, handleSubmit } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = () => {
    setopenIndividual(false);
  };
  return (
    <div>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          <Col>
            <Form.Group>
              <Form.Label>
                Выведите ID физлица или отсканируйте Qr-код
              </Form.Label>
              <Form.Control
                {...register('scan')}
                type="text"
                placeholder="Выведите ID физлица или отсканируйте Qr-код"
                onFocus={e => e.target.select()}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button className="w-100" variant="primary" type="submit">
          Применить
        </Button>
      </Form>
    </div>
  );
};

export default AddIndividual;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const movingInService = {
  getList: (params: any) => request.get('/in-moving', { params }),
  getMovingInById: (id: any) => request.get(`/in-moving/${id}`),
  createMovingIn: (data: any) => request.post('/in-moving', data),
  deleteMovingIn: (id: any) => request.delete(`/in-moving/${id}`),
  updateMovingIn: (id: any, data: any) => request.put(`/in-moving/${id}`, data)
};

export default movingInService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returnInService = {
  getList: (params: any) => request.get('/in-return', { params }),
  getReturnInById: (id: any) => request.get(`/in-return/${id}`),
  createReturnIn: (data: any) => request.post('/in-return', data),
  deleteReturnIn: (id: any) => request.delete(`/in-return/${id}`),
  updateReturnIn: (id: any, data: any) => request.put(`/in-return/${id}`, data)
};

export default returnInService;

import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import VerifyOtpForm from './VerifyOtpForm';

const VerifyOtp = () => {
  return (
    <AuthSimpleLayout>
      <VerifyOtpForm />
    </AuthSimpleLayout>
  );
};

export default VerifyOtp;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import FrozenRemedyFilter from './FrozenRemedyFilter';
import FrozenRemedyTable from './FrozenRemedyTable';

export const frozenRemedyBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Аналитика',
    url: '/analytics/revaluation/invoice'
  },

  {
    label: 'Замороженный средство',
    active: true
  }
];

const FrozenRemedy = () => {
  const [tableData, setTableData] = useState(false);

  return (
    <div>
      <PageBreadcrumb items={frozenRemedyBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Замороженный средство</h2>

        <FrozenRemedyFilter setTableData={setTableData} />
        <FrozenRemedyTable tableData={tableData} />
      </div>
    </div>
  );
};

export default FrozenRemedy;

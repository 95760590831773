/* eslint-disable @typescript-eslint/no-explicit-any */

import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import reportInvoiceService from 'service/reportInvoice';
import { pageToOffset } from 'utils/pageToOffset';

export type RemainderData = {
  product_name: string;
  barcode: string;
  currency: string;
  unit: string;
  category: string;
  cost: string;
  quantity: string;
  sum: string;
};

interface RemainderTableProps {
  generateTableData?: any;
  setGeneratedTableData?: any;
  getName?: any;
  getBarcode?: any;
  getFilterData?: any;
  savedValues?: any;
  setTotalQuantity?: any;
  setTotalincome?: any;
  setTotalSale?: any;
  currentPage?: any;
  setCurrentPage?: any;
  pageCount?: any;
  setPageCount?: any;
}

const RemainderTable = ({
  generateTableData,
  setGeneratedTableData,
  getName,
  getBarcode,
  getFilterData,
  savedValues,
  setTotalQuantity,
  setTotalincome,
  setTotalSale,
  currentPage,
  setCurrentPage,
  pageCount,
  setPageCount
}: RemainderTableProps) => {
  const currencyId = useSelector(
    (state: any) => state?.auth?.legal_entity?.currency_id
  );

  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_REPORTS_REMAINING', getName, getBarcode, currentPage],
    async () => {
      const res = await reportInvoiceService
        .getReportRemainingList({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          name: getName,
          barcode: getBarcode,
          currency_id: Object.keys(getFilterData).length === 0 && currencyId,
          is_group: savedValues?.length === 0 ? true : undefined,
          is_negative: savedValues?.length === 0 ? true : undefined,
          ...getFilterData
        })
        .then((res: any) => {
          setTotalQuantity(res?.total_quantity);
          setTotalincome(res?.total_income_amount);
          setTotalSale(res?.total_sale_amount);
          setPageCount(Math.ceil(res?.count / 10));

          return res?.products;
        });
      return res;
    },
    {
      enabled:
        getName.length === 0 ||
        getName.length > 2 ||
        getBarcode.length === 0 ||
        getBarcode.length > 2 ||
        !!currencyId ||
        !!getFilterData ||
        !!currentPage
    }
  );

  const loading = isLoading || isFetching;

  useEffect(() => {
    if (data) {
      const resData =
        data?.map((items: any) => {
          const packageQuantity = items?.package_quantity || 1;
          const quantity = String(items?.quantity ?? 0) || '0';

          let integerPart = 0;
          let numerator = 0;
          let denominator = 1;

          if (quantity?.includes('.')) {
            [numerator, denominator] = quantity.split('.').map(Number);
            integerPart = numerator; // Whole number part
            denominator = +`0.${denominator}`; // Remainder of the division
          } else {
            integerPart = +quantity;
          }

          const quantityDisplay = quantity?.includes('.')
            ? `${integerPart}/${Math?.round(+denominator * packageQuantity)}`
            : integerPart;

          return {
            product_name: items?.name,
            barcode: items?.barcode,
            unit: items?.nomenclature?.measure_unit?.name?.ru,
            currency: items?.currency?.name?.ru,
            category: items?.nomenclature?.category?.name?.ru,
            quantity:
              items?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
                ? quantityDisplay
                : items?.quantity ?? 0,

            quantity_in: items?.quantity ?? 0,
            package_quantity: items?.package_quantity,
            measure_unit_id: items?.items?.measure_unit_id,
            price: items?.price ?? 0,
            price_in: items?.price_in ?? 0,
            cost: items?.price,
            cost_in: items?.price_in,
            sum:
              items?.quantity && items?.price
                ? items?.quantity * items?.price
                : 0
          };
        }) ?? [];

      if (resData) {
        setGeneratedTableData(resData);
      }
    }
  }, [data, setGeneratedTableData]);

  const remainderDataTableColumns: ColumnDef<RemainderData>[] = [
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1 + (currentPage - 1) * 10; // Calculate the order number based on the row index
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'category',
      header: 'Категория',
      meta: {
        cellProps: { className: 'text-900', style: { whiteSpace: 'nowrap' } }
      }
    },
    {
      accessorKey: 'quantity',
      header: 'Кол.во',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cost_in',
      header: 'Цена поступ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'cost',
      header: 'Цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sum',
      header: 'На сумму',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateTableData,
    columns: remainderDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [getName, getBarcode]);
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          isLoading={loading}
        />
        <AdvanceTableCPagination
          count={pageCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </AdvanceTableProvider>
  );
};

export default RemainderTable;

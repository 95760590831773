/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntityArticleService = {
  getList: (params: any) => request.get('/legal-entity-article', { params }),
  getById: (id: any) => request.get(`/legal-entity-article/${id}`),
  create: (data: any) => request.post('/legal-entity-article', data),
  update: (id: any, data: any) =>
    request.put(`/legal-entity-article/${id}`, data),
  delete: (id: any) => request.delete(`/legal-entity-article/${id}`)
};

export default legalEntityArticleService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const movingItemService = {
  getList: (params: any) => request.get('/moving-item', { params }),
  getBySenderId: (id: any, params: any) =>
    request.get(`product/order-item/${id}`, { params }),
  getProduct: (params: any) => request.get('/product', { params }),
  getMovingItemOutById: (id: any, params: any) =>
    request.get(`/moving-item/out/${id}`, { params }),
  createMovingItem: (data: any) => request.post('/moving-item', data),
  getMovingItemInById: (id: any, params: any) =>
    request.get(`/moving-item/in/${id}`, { params }),
  updateMovingItemOut: (id: any, data: any) =>
    request.put(`/moving-item/out/${id}`, data),
  updateMovingItemIn: (id: any, data: any) =>
    request.put(`/moving-item/in/${id}`, data)
};

export default movingItemService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const timezoneService = {
  getList: (params: any) => request.get('/guidebook/timezone', { params }),
  getTimeZoneById: (id: any) => request.get(`/guidebook/timezone/${id}`),
  createTimeZone: (data: any) => request.post('/guidebook/timezone', data),
  deleteTimeZone: (id: any) => request.delete(`/guidebook/timezone/${id}`),
  updateTimeZone: (id: any, data: any) =>
    request.put(`/guidebook/timezone/${id}`, data)
};

export default timezoneService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export type AutoOrder = {
  product_name: string;
  barcode: string;
  standard_balance: string;
  coef: string;
  current_balance: string;
  auto_order: string;
};

export const autoOrderTableColumns: ColumnDef<AutoOrder>[] = [
  {
    accessorKey: 'product_name',
    header: 'Наименование товара',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'barcode',
    header: 'Штрих-код',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'standard_balance',
    header: 'Нормативный остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'coef',
    header: 'Коэф. Сезонности',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'current_balance',
    header: 'Текущий остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'auto_order',
    header: 'Автозаказ',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface AutoOrderTableProps {
  tableData?: any;
}

const AutoOrderTable = ({ tableData }: AutoOrderTableProps) => {
  const autoOrderData: AutoOrder[] = useMemo(() => {
    return (
      tableData?.items?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          standard_balance: items?.to_date_range ?? 0,
          coef: items?.seasonality_factor ?? 0,
          current_balance: items?.current_quantity ?? 0,
          auto_order: items?.prediction_quantity ?? 0
        };
      }) ?? []
    );
  }, [tableData]);

  const table = useAdvanceTable({
    data: autoOrderData,
    columns: autoOrderTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });
  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default AutoOrderTable;

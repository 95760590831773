/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import productGroupService from 'service/productGroup';
import { showAlert } from 'store/alert/alert.thunk';

interface ImportedDataTableProps {
  setOpenImportModal: (show: boolean) => void;
  setImportData?: any;
  importData?: any;
  refetch?: any;
}

export type importType = {
  parent_barcode: string;
  child_barcode: string;
};

const ImportedDataTable = ({
  setOpenImportModal,
  setImportData,
  importData,
  refetch
}: ImportedDataTableProps) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [loading, setLoading] = useState(false);

  const generateData: importType[] = useMemo(() => {
    return (
      importData?.map((items: any) => {
        return {
          parent_barcode: items?.parent_barcode,
          child_barcode: items?.child_barcode
        };
      }) ?? []
    );
  }, [importData]);

  const importDataTableColumns: ColumnDef<importType>[] = [
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1;
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'parent_barcode',
      header: 'Parent штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'child_barcode',
      header: 'Child штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: importDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSubmit = () => {
    const invalidEntries = importData?.filter((item: importType) => {
      return importData.some(
        (data: importType) =>
          data.parent_barcode === item.child_barcode &&
          data.child_barcode === item.child_barcode
      );
    });

    if (invalidEntries?.length === 0) {
      dispatch(
        showAlert({
          title:
            'Ошибка: Некорректная группировка штрих-кодов!. Parent штрих-код должна быть в Child штрихкоде'
        })
      );
      return;
    }

    if (importData) {
      setLoading(true);
      productGroupService
        .createProductGroup({ group_products: importData })
        .then(res => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Товар успешно добавлен для группировки!',
                type: 'success'
              })
            );
            refetch();
            setImportData([]);
            setOpenImportModal(false);
            setLoading(false);
          }
        });
    }
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>

      <div className="d-flex justify-content-end mt-2 border-top">
        <Button
          style={{ height: '48px', marginTop: '20px' }}
          variant="primary"
          onClick={() => handleSubmit()}
          loading={loading}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Добавить товар
        </Button>
      </div>
    </div>
  );
};

export default ImportedDataTable;

import { ColumnDef } from '@tanstack/react-table';

import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { NDSType } from 'data/nds';

export const ndsTableColumns: ColumnDef<NDSType>[] = [
  {
    accessorKey: 'country',
    header: 'Страна',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'nds',
    header: 'Значения Ндс',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояние',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

const NdsTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default NdsTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const ndsService = {
  getList: (params: any) => request.get('/guidebook/vat', { params }),
  getNdsById: (id: any) => request.get(`/guidebook/vat/${id}`),
  createNds: (data: any) => request.post('/guidebook/vat', data),
  deleteNds: (id: any) => request.delete(`/guidebook/vat/${id}`),
  updateNds: (id: any, data: any) => request.put(`/guidebook/vat/${id}`, data)
};

export default ndsService;

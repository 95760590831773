/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/base/Button';
import legalEntityService from 'service/legalEntity';
import { showAlert } from 'store/alert/alert.thunk';
import orderService from 'service/order';
import NewApplicationDataTable from './NewApplicationDataTable';

const CreateNewApplication = () => {
  const { control, getValues } = useForm();
  const dispatch: Dispatch<any> = useDispatch();
  const cashboxId = useSelector((state: any) => state?.shiftId?.shiftId);
  const navigate = useNavigate();

  const [legalEntityOption, setLegalEntityOption] = useState([]);
  const [openFolder, setOpenFolder] = useState(false);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));

        setLegalEntityOption(options);
      });
  });

  const handleSave = () => {
    const data = getValues();

    if (!cashboxId) {
      dispatch(showAlert({ title: 'Смена еще не открыта' }));
    }

    if (data?.sender !== '' && cashboxId) {
      orderService
        .createOrder({
          sender_id: data?.sender,
          cashbox_id: cashboxId
        })
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Новая возврат успешно добавлена',
                type: 'success'
              })
            );
            navigate(
              `/applications/outgoing/filter/newapplications/${res?.id}?sender_id=${res?.sender_id}`
            );
          }
        });
    }
  };

  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Form.Group className="md-4">
        <div className="react-select-container">
          <Controller
            name="sender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Form.Floating>
                <Form.Select {...field}>
                  <option className="d-none" value=""></option>
                  {legalEntityOption?.map((option: any) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="option"
                    >
                      {option.label}
                    </option>
                  ))}
                </Form.Select>

                <label htmlFor="floatingInputCustom">Отправитель</label>
              </Form.Floating>
            )}
          />
        </div>
      </Form.Group>
      <Form.Group style={{ display: 'flex', gap: '10px', marginTop: 'auto' }}>
        <Button
          variant="outline-primary"
          type="button"
          style={{ height: '48px', width: '100%' }}
          onClick={() => setOpenFolder(true)}
        >
          <FontAwesomeIcon icon={faFolderOpen} className="me-2" />
          Открыть
        </Button>
        <Button
          variant="primary"
          type="button"
          style={{ height: '48px', width: '100%' }}
          onClick={() => handleSave()}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Создать
        </Button>
      </Form.Group>

      <Modal
        show={openFolder}
        onHide={() => setOpenFolder(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Выбрать возврат
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewApplicationDataTable setOpenFolder={setOpenFolder} />
        </Modal.Body>
      </Modal>
    </Form>
  );
};

export default CreateNewApplication;

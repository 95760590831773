/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import authService from './authService';

interface Props {
  loginProps?: any;
  refreshProps?: any;
  sendOtpProps?: any;
  verifyOtpProps?: any;
  registerProps?: any;
}

const useAuth = ({
  loginProps,
  refreshProps,
  sendOtpProps,
  verifyOtpProps,
  registerProps
}: Props) => {
  const login = useMutation(authService.loginFn, loginProps);
  const refresh = useMutation(authService.refreshTokenFn, refreshProps);
  const sendOtp = useMutation(authService?.sendOtpFn, sendOtpProps);
  const verifyOtp = useMutation(authService.verifyOtpFn, verifyOtpProps);
  const register = useMutation(authService.registerFn, registerProps);
  return {
    login,
    refresh,
    sendOtp,
    verifyOtp,
    register
  };
};

export default useAuth;

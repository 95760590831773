/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const movingService = {
  getMovingById: (id: any) => request.get(`/moving/${id}`),
  createMoving: (data: any) => request.post('/moving', data),
  deleteMoving: (id: any) => request.delete(`/moving/${id}`),
  updateMoving: (id: any, data: any) => request.put(`/moving/${id}`, data)
};

export default movingService;

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const newMovementsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Перемещения',
    url: '/movements/outgoing'
  },
  {
    label: 'Исходящий',
    url: '/movements/outgoing'
  },
  {
    label: 'Новая ',
    active: true
  }
];

export type NewMovementsData = {
  orders: string;
  sender: string;
  recipient: string;
  date_create: string;
  guid: string;
  recipient_id: string;
};

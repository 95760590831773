/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import scalesService from 'service/scales';

interface UpdateScalesProps {
  selectedScaleId?: any;
  setSelectedScaleId?: any;
  refetch?: any;
  setOpenModal: (show: boolean) => void;
}

const UpdateScales = ({
  selectedScaleId,
  setSelectedScaleId,
  refetch,
  setOpenModal
}: UpdateScalesProps) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  //Get by id
  const fetchData = () => {
    if (selectedScaleId?.guid === '') return setLoading(false);

    const computed = {
      scale_code: selectedScaleId?.scale_code,
      barcode: selectedScaleId?.barcode,
      name: selectedScaleId?.name
    };

    reset(computed);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef?.current?.select();
      }, 500);
    }
  }, [inputRef.current]);

  const onSubmit: SubmitHandler<FieldValues> = data => {
    setLoading(true);

    scalesService
      .updateScales(selectedScaleId?.guid, {
        scale_code: +data?.scale_code
      })
      .then(res => {
        if (res) {
          setSelectedScaleId({});
          setOpenModal(false);
          refetch();
          dispatch(
            showAlert({
              title: 'Товар успешно изменён!',
              type: 'success'
            })
          );
        }
      })
      .catch(error => {
        if (error?.response?.status === 404) {
          setLoading(false);
          dispatch(
            showAlert({
              title: 'Штрих-код весовой существует!'
            })
          );
        }
      });
  };

  useEffect(() => {
    if (selectedScaleId?.guid !== '') {
      fetchData();
    }
  }, [selectedScaleId]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-3">
          <Col>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    disabled={true}
                    type="text"
                    placeholder="Наименование товара"
                    onFocus={e => e.target.select()}
                  />
                  <label htmlFor="floatingInputCustom">
                    Наименование товара
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Controller
              name="barcode"
              control={control}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    disabled={true}
                    type="text"
                    placeholder="Штрих-код"
                    onFocus={e => e.target.select()}
                  />
                  <label htmlFor="floatingInputCustom">Штрих-код</label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Controller
              name="scale_code"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    placeholder="Штрих-код весовой"
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.scale_code}
                  />
                  <label htmlFor="floatingInputCustom">Штрих-код весовой</label>
                  {errors.scale_code && (
                    <Form.Control.Feedback type="invalid">
                      Обязательное поле
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          Изменить
        </Button>
      </Form>
    </>
  );
};

export default UpdateScales;

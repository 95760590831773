/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const orderOutService = {
  getList: (params: any) => request.get('/out-order', { params }),
  getOrderOutById: (id: any) => request.get(`/out-order/${id}`),
  createOrderOut: (data: any) => request.post('/out-order', data),
  deleteOrderOut: (id: any) => request.delete(`/out-order/${id}`),
  updateOrderOut: (id: any, data: any) => request.put(`/out-order/${id}`, data)
};

export default orderOutService;

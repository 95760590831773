/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const bonusItemService = {
  getList: (params: any) => request.get('/bonus-item', { params }),
  getBonusItemById: (id: any) => request.get(`/bonus-item/${id}`),
  createBonusItem: (data: any) => request.post('/bonus-item', data),
  deleteBonusItem: (id: any) => request.delete(`/bonus-item/${id}`),
  updateBonusItem: (id: any, data: any) =>
    request.put(`/bonus-item/${id}`, data)
};

export default bonusItemService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import requestAuth from 'utils/requestAuth';

const usersStatusService = {
  getList: (params: any) => requestAuth.get('/user-status', { params }),
  getById: (id: string) => requestAuth.get(`/user-status/${id}`),
  create: (data: any) => requestAuth.post('/user-status', data),
  update: (data: any) => requestAuth.put(`/user-status`, data)
};

export default usersStatusService;

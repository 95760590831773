import { ColumnDef } from '@tanstack/react-table';

import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { AddAmount } from 'data/addamount';

export const addAmountTableColumns: ColumnDef<AddAmount>[] = [
  {
    accessorKey: 'amount',
    header: 'Сумма',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'note',
    header: 'Примечание',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'date',
    header: 'Дата пополнения',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];
const AddAmountTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default AddAmountTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { Coming } from 'data/movement';
import movingInService from 'service/movingIn';

export const comingTableColumns: ColumnDef<Coming>[] = [
  {
    accessorKey: 'orders',
    header: '№ заявки',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'recipient',
    header: 'Получатель',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'sender',
    header: 'Отправитель',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'status',
    header: 'Состояния',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'create_date',
    header: 'Дата создания',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface ComingFilterMovementsTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionStatusId?: any;
}

const ComingFilterMovementsTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionStatusId
}: ComingFilterMovementsTableProps) => {
  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/movements/coming/movements-coming-result/${row?.original?.guid}`
    );
  };
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  const { data, refetch } = useQuery(
    ['GET_MOVING_IN_LIST'],
    async () => {
      const res = await movingInService
        .getList({
          offset: 0,
          limit: 100,
          status_id: optionStatusId ? optionStatusId : undefined,
          sender_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.movings);
      return res;
    },
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!optionSenderId || !!optionStatusId
    }
  );

  const comingData: Coming[] = useMemo(() => {
    const resData =
      data?.map((items: any) => {
        return {
          name: items?.name,
          orders: items?.moving_number,
          status: items?.status?.name?.ru,
          sender: items?.legal_entity?.name,
          currency: items?.currency?.name?.ru,
          recipient: legalEntityName,
          /* prettier-ignore */
          create_date: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          can_update: items?.can_update,
          maturity_date: items?.maturity_date ?? null,
          guid: items?.id
        };
      }) ?? [];

    return resData;
  }, [data]);

  const table = useAdvanceTable({
    data: comingData,
    columns: comingTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionStatusId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default ComingFilterMovementsTable;

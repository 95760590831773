/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  creditor_id: string;
  currency_id: string;
};

export const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
  {
    accessorKey: 'product_provider',
    header: 'Наименование поставщика ',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'debt',
    header: 'Дебет',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'credit',
    header: 'Кредит',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'remainder',
    header: 'Остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface SettelementTableProps {
  suppliersData?: any;
}

const SettelementTable = ({ suppliersData }: SettelementTableProps) => {
  const navigate = useNavigate();

  const navigateTo = (row: any) => {
    navigate(
      `/dtkt/suppliers/suppliers-result/${row?.original?.creditor_id}?currency_id=${row?.original?.currency_id}`
    );
  };

  const settelementData: SettelementData[] = useMemo(() => {
    const resultData =
      suppliersData?.map((items: any) => {
        return {
          product_provider: items?.creditor?.name,
          creditor_id: items?.creditor_id,
          currency_id: items?.currency_id,
          currency: items?.currency?.name?.ru,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0)
        };
      }) ?? [];

    return resultData;
  }, [suppliersData]);

  const table = useAdvanceTable({
    data: settelementData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default SettelementTable;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigationType } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { invoceBreadcrumbItems } from 'data/invoce';
import PrintingFilter from './filters/printing';
import InvoiceTable from './InvoiceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Button from 'components/base/Button';
import productSearchService from 'service/productSearch';
import { InvoiceType } from 'data/invoce';
import { showAlert } from 'store/alert/alert.thunk';
import invoiceService from 'service/invoice';
import useFocusRefs from 'utils/useFocusRefs';
import SupperModal from 'components/modals/SupperModal';
import './styles.scss';

const PurchaseInvoce = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const newInvoiceId = pathname.split('/').pop();

  const { setRef, focusRef } = useFocusRefs();

  const [productInvoiceData, setProductInvoiceData] = useState<InvoiceType[]>(
    []
  );
  const [modalCreate, setModalCreate] = useState(false);
  const [isVatPayerValue, setIsVatPayerValue] = useState(false);
  const [ndsData, setNdsData] = useState('');
  const [ndsPrice, setNdsPrice] = useState('');
  const [ndsPriceWholsale, setNdsPriceWholsale] = useState('');

  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saleToMinus, setSaleToMinus] = useState(false);
  const [isForced, setIsForced] = useState(false);

  const dispatch: Dispatch<any> = useDispatch();
  const navType = useNavigationType();
  const barcodeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (navType === 'PUSH' || navType === 'REPLACE') {
      setProductInvoiceData([]);
    }
  }, [navType]);

  console.log('productInvoiceData===>', productInvoiceData);

  const handleRemoveProductItem = (indexToRemove: number) => {
    setProductInvoiceData(
      productInvoiceData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };

  const invoiceTableColumns: ColumnDef<InvoiceType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleRemoveProductItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={18}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1; // Calculate the order number based on the row index
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-1 text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Товар',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '';

        const [value, setValue] = useState(amount);
        const [inputWidth, setInputWidth] = useState('auto');

        useEffect(() => {
          const span = document.createElement('span');
          span.style.visibility = 'hidden';
          span.style.whiteSpace = 'pre';
          span.style.fontSize = '14px'; // Ensure this matches your input's font size
          span.style.fontFamily = 'Arial'; // Ensure this matches your input's font family
          span.innerText = value;

          document.body.appendChild(span);
          const width = span.offsetWidth + 38; // Add some padding
          setInputWidth(`${width}px`);
          document.body.removeChild(span);
        }, [value]);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: value };
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          setValue(value);
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="name"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                style={{ width: inputWidth }}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },

    {
      accessorKey: 'barcode_user',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Ед.Изм',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },

    {
      accessorKey: 'current_balance',
      header: 'Тек.Ост',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },

    {
      accessorKey: 'quantity',
      header: 'Кол.',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        const measureUnitKindId = rowData?.row?.original.measure_unit_kind_id; // check to vesavoy id
        const packageQuantity = rowData?.row?.original?.amount;

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: value };
            }
            return item;
          });
          setProductInvoiceData(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;

          // Regex to match negative numbers, decimals, and fractions
          const numberPattern = /^-?\d*\.?\d*$/;
          const remainderPattern = /^-?\d*\/?\d*$/;

          const packageQuantityNumber = Number(packageQuantity);

          if (measureUnitKindId === '3c7cee55-ad41-4ee3-a409-a7bb5f428b36') {
            if (remainderPattern.test(newValue)) {
              const [numerator, denominator] = newValue.split('/').map(Number);
              console.log('numerator', numerator);
              // Ensure the fraction's denominator does not exceed packageQuantity
              if (
                denominator > packageQuantityNumber ||
                denominator === packageQuantityNumber
              ) {
                return; // Do nothing if the denominator is invalid
              }

              setValue(newValue); // Set value if fraction is valid
            }
          } else {
            // Handle normal numbers
            if (numberPattern.test(newValue)) {
              setValue(newValue);
            }
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { name, value } = e.target as HTMLInputElement;
            updateProductInvoiceData(name, value, rowData.row?.id);
            focusRef(
              measureUnitKindId === '3c7cee55-ad41-4ee3-a409-a7bb5f428b36'
                ? `amount_${rowData.row.id}`
                : `package_amount_${rowData.row.id}`
            );
          }
        };
        return (
          <div style={{ width: '70px' }}>
            <Form.Group>
              <Form.Control
                type="text"
                name="quantity"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => {
                  if (value === '') {
                    setValue('1'); // Reset to default value if left empty
                  }
                  onBlur(e, rowData.row?.id);
                }}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                ref={setRef(`quantity_${rowData.row.id}`)}
                min="1"
                autoComplete="off"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'amount',
      header: 'Кол.Упак',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '1';

        // it is id for check update or no (i check it to static Упаковка "3c7cee55-ad41-4ee3-a409-a7bb5f428b36")
        const measureUnitKindId = rowData?.row?.original.measure_unit_kind_id;

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          value: string,
          index: any
        ) => {
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });
          setProductInvoiceData(updatedItems);
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          updateProductInvoiceData(name, value, index);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            // Allow empty string for intermediate state
            setValue(newValue);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { name, value } = e.target as HTMLInputElement;
            updateProductInvoiceData(name, value, rowData.row?.id);
            focusRef(`package_amount_${rowData.row.id}`);
          }
        };
        return (
          <div>
            {measureUnitKindId === '3c7cee55-ad41-4ee3-a409-a7bb5f428b36' &&
            rowData?.row?.original.is_product === false ? (
              <Form.Group>
                <Form.Control
                  type="text"
                  name="amount"
                  value={value}
                  onChange={handleChange}
                  onBlur={(e: any) => {
                    if (value === '') {
                      setValue('1'); // Reset to default value if left empty
                    }
                    onBlur(e, rowData.row?.id);
                  }}
                  ref={setRef(`amount_${rowData.row.id}`)}
                  onKeyDown={handleKeyDown}
                  onFocus={e => e.target.select()}
                  min="1"
                  autoComplete="off"
                />
              </Form.Group>
            ) : (
              <span className="ms-3">{amount}</span>
            )}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'package_amount',
      header: 'Цена.Поступ',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          sanitizedValue: string,
          index: any
        ) => {
          const numericValue = parseFloat(sanitizedValue);
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (isForced === true) {
              if (i === +index) {
                return {
                  ...item,
                  [name]: numericValue
                  // ['cost']: !costUpdated[index]
                  //   ? (+numericValue * +ndsPriceWholsale) / 100 + numericValue
                  //   : +costUpdated[index],
                  // ['all_cost']: !allCostUpdated[index]
                  //   ? (+numericValue * +ndsPrice) / 100 + numericValue
                  //   : +allCostUpdated[index]
                };
              }
            } else {
              if (i === +index) {
                return {
                  ...item,
                  [name]: numericValue,
                  ['cost']:
                    (+numericValue * +ndsPriceWholsale) / 100 + numericValue,
                  ['all_cost']: (+numericValue * +ndsPrice) / 100 + numericValue
                };
              }
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };

        const sanitizeValue = (inputValue: string): string => {
          // Remove all non-numeric characters from the end
          return inputValue.replace(/[^0-9.]+$/, '');
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          console.log('index===>', index);

          const { name } = e.target;

          const inputValue = e.target.value;

          const sanitizedValue = sanitizeValue(inputValue);
          setValue(sanitizedValue);
          updateProductInvoiceData(name, sanitizedValue, index);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^[0-9.,]*$/.test(newValue)) {
            setValue(newValue);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { name, value } = e.target as HTMLInputElement;
            updateProductInvoiceData(name, value, rowData.row?.id);
            focusRef(`all_cost_${rowData.row.id}`);
          }
        };

        return (
          <div>
            <Form.Group className="position-relative">
              <Form.Control
                type="text"
                name="package_amount"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                ref={setRef(`package_amount_${rowData.row.id}`)}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'cost',
      header: 'Цена.Опт',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          sanitizedValue: string,
          index: any
        ) => {
          const numericValue = parseFloat(sanitizedValue);
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: numericValue };
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };

        const sanitizeValue = (inputValue: string): string => {
          // Remove all non-numeric characters from the end
          return inputValue.replace(/[^0-9.]+$/, '');
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name } = e.target;
          const inputValue = e.target.value;

          const sanitizedValue = sanitizeValue(inputValue);
          setValue(sanitizedValue);
          updateProductInvoiceData(name, sanitizedValue, index);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^[0-9.,]*$/.test(newValue)) {
            setValue(newValue);
          }
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Tab') {
            const { name, value } = e.target as HTMLInputElement;
            updateProductInvoiceData(name, value, rowData.row?.id);
            focusRef(`all_cost_${rowData?.row?.id}`);
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="cost"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                ref={setRef(`cost_${rowData.row.id}`)}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'all_cost',
      header: 'Цена.Роз',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        const [value, setValue] = useState(amount);

        const updateProductInvoiceData = (
          name: string,
          sanitizedValue: string,
          index: any
        ) => {
          const numericValue = parseFloat(sanitizedValue);
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: numericValue };
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };

        const sanitizeValue = (inputValue: string): string => {
          // Remove all non-numeric characters from the end
          return inputValue.replace(/[^0-9.]+$/, '');
        };

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name } = e.target;
          const inputValue = e.target.value;

          const sanitizedValue = sanitizeValue(inputValue);
          setValue(sanitizedValue);

          updateProductInvoiceData(name, sanitizedValue, index);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
          const { name, value: inputValue } = e.target as HTMLInputElement;

          if (e.key === 'Tab' || e.key === 'Enter') {
            const sanitizedValue = sanitizeValue(inputValue);
            updateProductInvoiceData(name, sanitizedValue, rowData.row?.id);

            if (e.key === 'Tab') {
              const currentRowIndex = rowData.row.index;
              const nextRowIndex = currentRowIndex + 1;

              // Check if the current item is the last one
              if (nextRowIndex < productInvoiceData.length) {
                focusRef(`quantity_${nextRowIndex}`);
              } else {
                // If it's the last item, move focus to the first item in the next column
                focusRef(`quantity_0`);
              }
            }

            if (e.key === 'Enter' && barcodeInputRef.current) {
              barcodeInputRef.current.focus();
              barcodeInputRef.current.select();
            }
          }
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^[0-9.,]*$/.test(newValue)) {
            setValue(newValue);
          }
        };

        return (
          <div>
            <Form.Group>
              <Form.Control
                type="text"
                name="all_cost"
                value={value}
                onChange={handleChange}
                onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                onFocus={e => e.target.select()}
                onKeyDown={handleKeyDown}
                ref={setRef(`all_cost_${rowData.row.id}`)}
                autoComplete="off"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },

    {
      accessorKey: 'update_price',
      header: 'Обн.Пред.Цену',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? false;
        const [value, setValue] = useState(amount);

        const handleCheckboxChange = (
          e: React.ChangeEvent<HTMLInputElement>,
          index: any
        ) => {
          const checked = e.target.checked;
          setValue(checked);

          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, update_price: checked };
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };
        return (
          <div>
            <Form.Group className="printing-check">
              <Form.Check
                type="switch"
                checked={value}
                onChange={e => handleCheckboxChange(e, rowData.row?.id)}
                className="cursor-pointer"
              />
            </Form.Group>
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 text-center' }
      }
    },
    {
      accessorKey: 'weght_code',
      header: 'Вес.Код',
      cell: rowData => {
        const amount: any = rowData.getValue() ?? '0';

        // it is id for check update or no (i check it to static Весовой 2604393f-4d7a-439d-9ee4-831756ad873b)
        const measureUnitKindId = rowData?.row?.original.measure_unit_kind_id;

        const [value, setValue] = useState(amount);

        const onBlur = (e: React.ChangeEvent<HTMLInputElement>, index: any) => {
          const { name, value } = e.target;
          const updatedItems = productInvoiceData.map((item: any, i: any) => {
            if (i === +index) {
              return { ...item, [name]: +value };
            }
            return item;
          });

          setProductInvoiceData(updatedItems);
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = e.target.value;
          if (/^\d*$/.test(newValue)) {
            // Allow empty string for intermediate state
            setValue(newValue);
          }
        };

        return (
          <div>
            {measureUnitKindId === '2604393f-4d7a-439d-9ee4-831756ad873b' ? (
              <Form.Group>
                <Form.Control
                  type="text"
                  name="weght_code"
                  value={value}
                  onChange={handleChange}
                  onBlur={(e: any) => onBlur(e, rowData.row?.id)}
                  onFocus={e => e.target.select()}
                />
              </Form.Group>
            ) : (
              <span className="ms-3">{amount}</span>
            )}
          </div>
        );
      },
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'articul',
      header: 'Артикул',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'ikpu',
      header: 'Код ИКПУ',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'party_number',
      header: 'Партий.номер',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'with_nds',
      header: 'С НДС',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'category',
      header: 'Категория',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'variation',
      header: 'Вариация',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    },
    {
      accessorKey: 'barcode_original',
      header: 'Штрих-код.Ориг',
      meta: {
        cellProps: { className: 'text-900 p-1' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: productInvoiceData,
    columns: invoiceTableColumns,
    pageSize: 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSave = async () => {
    const getheredData = productInvoiceData?.map((item: any) => {
      const quantity = item?.quantity || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1; // Default denominator to 1 if no fraction

      const quantityStr = String(quantity);
      // Check if quantity contains '/'
      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      // Package quantity
      const packageQuantity = +item?.amount || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      console.log('adjustedQuantity', adjustedQuantity);

      return {
        name: item?.name.toString(),
        is_variation: item?.variation === 'ДА' ? true : false,
        barcode: item?.barcode_user,
        scale_code: (item?.weght_code && +item?.weght_code) ?? 0,
        quantity:
          item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        package_quantity: (item?.amount && +item?.amount) ?? 0,
        price_in: (item?.package_amount && +item?.package_amount) ?? 0,
        price_whosale: (item?.cost && +item?.cost) ?? 0,
        price: (item?.all_cost && +item?.all_cost) ?? 0,
        nomenclature_id: item?.guid,
        update_price_in: item?.update_price ?? false,
        update_price: item?.update_price ?? false,
        is_vat: isVatPayerValue,
        vat: +ndsData ?? 0,
        status_id: '5107ebe4-ae15-4707-b268-438d5366add1',
        from_excel: item?.from_excel,
        category_id: String(item?.category_id),
        measure_unit_id: String(item?.measure_unit_id),
        ikpu: String(item?.ikpu),
        articul: item?.articul,
        partion_number: String(item?.party_number)
      };
    });

    // Check for empty name
    const invalidNameItems = getheredData
      .map((item, index) => ({ ...item, orderNumber: index + 1 }))
      .filter(
        item => typeof item?.name === 'string' && item?.name?.trim() === ''
      );

    if (invalidNameItems.length > 0) {
      const invalidOrderNumbers = invalidNameItems
        .map(item => item.orderNumber)
        .join(', ');
      console.log('Invalid items found:', invalidNameItems);
      dispatch(
        showAlert({
          title: `Имя не должно быть пустым, Номера позиций: ${invalidOrderNumbers}`,
          type: 'error'
        })
      );
      return;
    }

    // Check for barcodes with a value of 0 and ensure category_id and measure_unit_id are present
    const invalidItems = getheredData
      .map((item, index) => ({ ...item, orderNumber: index + 1 }))
      .filter(
        item =>
          item.barcode === '0' && (!item.category_id || !item.measure_unit_id)
      );

    if (invalidItems.length > 0) {
      const invalidOrderNumbers = invalidItems
        .map(item => item.orderNumber)
        .join(', ');
      console.log('Invalid items found:', invalidItems);
      dispatch(
        showAlert({
          title: `Обнаружены элементы со штрих-кодом 0, отсутствующим идентификатором категории или идентификатором единицы измерения! Номера позиций: ${invalidOrderNumbers}`,
          type: 'error'
        })
      );
      return;
    }

    // Find duplicates by barcode, excluding barcodes with value 0
    // const barcodeCounts = getheredData.reduce(
    //   (
    //     acc: Record<number, { count: number; orderNumbers: number[] }>,
    //     item,
    //     index
    //   ) => {
    //     if (item.barcode !== 0) {
    //       if (!acc[item.barcode]) {
    //         acc[item.barcode] = { count: 0, orderNumbers: [] };
    //       }
    //       acc[item.barcode].count += 1;
    //       acc[item.barcode].orderNumbers.push(index + 1);
    //     }
    //     return acc;
    //   },
    //   {}
    // );

    // const duplicates = Object.entries(barcodeCounts)
    //   .filter(([, data]) => data.count > 1)
    //   .flatMap(([, data]) => data.orderNumbers);

    // if (duplicates.length > 0) {
    //   console.log('Duplicates found:', duplicates);
    //   /* prettier-ignore */
    //   dispatch(
    //     showAlert({
    //       title: `Обнаружены повторяющиеся штрих-коды! Номера позиций: ${duplicates.join(', ')}`,
    //       type: 'error'
    //     })
    //   );
    //   return;
    // }

    const chunkSize = 150;
    const chunks = [];

    for (let i = 0; i < getheredData.length; i += chunkSize) {
      chunks.push(getheredData.slice(i, i + chunkSize));
    }

    const savedData = {
      invoice_id: newInvoiceId,
      language: 'ru'
    };

    try {
      dispatch(showAlert({ title: 'Сохранение данных...', type: 'info' }));

      if (chunks.length === 0) {
        chunks.push([]);
      }

      for (let i = 0; i < chunks.length; i++) {
        setLoading(true);
        const dataToSend = {
          ...savedData,
          products: chunks[i],
          ...(i === 0 && { first_part: true }) // Add first_part: true for the first request
        };
        await productSearchService.createProduct(dataToSend);
      }

      dispatch(
        showAlert({ title: 'Закуп успешно сохранен!', type: 'success' })
      );
      setLoading(false);

      barcodeInputRef.current?.focus();
    } catch (error) {
      dispatch(showAlert({ title: 'Ошибка при сохранении!', type: 'error' }));
      setLoading(false);
    }
  };

  const handleFinish = async () => {
    // Conditionally check for zero fields based on saleToMinus
    // const zeroProductsData = saleToMinus
    //   ? []
    //   : productInvoiceData
    //       ?.map((product: any, index) => ({
    //         hasZero:
    //           product?.quantity === 0 ||
    //           product?.all_cost === 0 ||
    //           product?.cost === 0 ||
    //           product?.package_amount === 0,
    //         index: index + 1
    //       }))
    //       .filter(product => product.hasZero);

    // Check if there are any such products
    // if (!saleToMinus && zeroProductsData.length > 0) {
    //   const zeroProductOrderNumbers = zeroProductsData
    //     .map(p => p.index)
    //     .join(', ');
    //   dispatch(
    //     showAlert({
    //       title: `Количество и цены товаров должно быть больше нуля для завершения закупа. Проблема в позициях: ${zeroProductOrderNumbers}`,
    //       type: 'error'
    //     })
    //   );
    //   setConfirmToDelete(false);
    //   return;
    // }

    if (productInvoiceData?.length === 0) {
      dispatch(
        showAlert({
          title: 'Товар еще не добавлен'
        })
      );
      setConfirmToDelete(false);
      return;
    }

    const getheredData = productInvoiceData?.map((item: any) => {
      const quantity = item?.quantity || '0';

      let integerPart = 0;
      let numerator = 0;
      let denominator = 1; // Default denominator to 1 if no fraction

      const quantityStr = String(quantity);
      // Check if quantity contains '/'
      if (quantityStr?.includes('/')) {
        [numerator, denominator] = quantityStr.split('/').map(Number);

        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantityStr;
      }

      // Package quantity
      const packageQuantity = +item?.amount || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;

      // Calculate adjusted quantity
      const adjustedQuantity = quantityStr?.includes('/')
        ? `${integerPart}.${Math.round(fractionalProportion)}`
        : integerPart;

      return {
        name: item?.name.toString(),
        is_variation: item?.variation === 'ДА' ? true : false,
        barcode: item?.barcode_user,
        scale_code: (item?.weght_code && +item?.weght_code) ?? 0,
        quantity:
          item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
            ? +adjustedQuantity
            : +quantity,
        package_quantity: (item?.amount && +item?.amount) ?? 0,
        price_in: (item?.package_amount && +item?.package_amount) ?? 0,
        update_price_in: item?.update_price ?? false,
        update_price: item?.update_price ?? false,
        price_whosale: (item?.cost && +item?.cost) ?? 0,
        price: (item?.all_cost && +item?.all_cost) ?? 0,
        nomenclature_id: item?.guid,
        is_vat: isVatPayerValue,
        vat: +ndsData ?? 0,
        status_id: '1107ebe4-ae15-4707-b268-438d5366add5',
        from_excel: item?.from_excel,
        category_id: String(item?.category_id),
        measure_unit_id: String(item?.measure_unit_id),
        ikpu: String(item?.ikpu),
        articul: item?.articul,
        partion_number: String(item?.party_number)
      };
    });

    // Check for empty name
    const invalidNameItems = getheredData
      .map((item, index) => ({ ...item, orderNumber: index + 1 }))
      .filter(
        item => typeof item?.name === 'string' && item?.name?.trim() === ''
      );

    if (invalidNameItems.length > 0) {
      const invalidOrderNumbers = invalidNameItems
        .map(item => item.orderNumber)
        .join(', ');
      console.log('Invalid items found:', invalidNameItems);
      dispatch(
        showAlert({
          title: `Имя не должно быть пустым, Номера позиций: ${invalidOrderNumbers}`,
          type: 'error'
        })
      );
      setConfirmToDelete(false);
      return;
    }

    // Check for barcodes with a value of 0 and ensure category_id and measure_unit_id are present
    const invalidItems = getheredData
      .map((item, index) => ({ ...item, orderNumber: index + 1 }))
      .filter(
        item =>
          item.barcode === '0' && (!item.category_id || !item.measure_unit_id)
      );

    if (invalidItems.length > 0) {
      const invalidOrderNumbers = invalidItems
        .map(item => item.orderNumber)
        .join(', ');
      console.log('Invalid items found:', invalidItems);
      dispatch(
        showAlert({
          title: `Обнаружены элементы со штрих-кодом 0, отсутствующим идентификатором категории или идентификатором единицы измерения! Номера позиций: ${invalidOrderNumbers}`,
          type: 'error'
        })
      );
      setConfirmToDelete(false);
      return;
    }

    // Find duplicates by barcode, excluding barcodes with value 0
    // const barcodeCounts = getheredData.reduce(
    //   (
    //     acc: Record<number, { count: number; orderNumbers: number[] }>,
    //     item,
    //     index
    //   ) => {
    //     if (item.barcode !== 0) {
    //       if (!acc[item.barcode]) {
    //         acc[item.barcode] = { count: 0, orderNumbers: [] };
    //       }
    //       acc[item.barcode].count += 1;
    //       acc[item.barcode].orderNumbers.push(index + 1);
    //     }
    //     return acc;
    //   },
    //   {}
    // );

    // const duplicates = Object.entries(barcodeCounts)
    //   .filter(([, data]) => data.count > 1)
    //   .flatMap(([, data]) => data.orderNumbers);

    // if (duplicates.length > 0) {
    //   console.log('Duplicates found:', duplicates);
    //   /* prettier-ignore */
    //   dispatch( showAlert({ title: `Обнаружены повторяющиеся штрих-коды! Номера позиций: ${duplicates.join(', ')}`,
    //       type: 'error'
    //     })
    //   );
    //   setConfirmToDelete(false);
    //   return;
    // }

    // Check if any all_cost is greater than package_amount
    const highCostItems = getheredData
      .map((item: any, index) => ({ ...item, orderNumber: index + 1 }))
      .filter(item => item.price < item.price_in);

    console.log('getheredData', getheredData);

    if (highCostItems.length > 0) {
      const highCostOrderNumbers = highCostItems
        .map(item => item.orderNumber)
        .join(', ');

      const confirmMessage = `Билл завершен товар которая  цена розничная товара меньше цена поступления!. Номера позиций: ${highCostOrderNumbers}`;

      dispatch(
        showAlert({
          title: confirmMessage,
          type: 'warning'
        })
      );
      // setConfirmToDelete(false);
      // return;
    }

    const chunkSize = 150;
    const chunks = [];

    for (let i = 0; i < getheredData.length; i += chunkSize) {
      chunks.push(getheredData.slice(i, i + chunkSize));
    }

    const savedData = {
      invoice_id: newInvoiceId,
      language: 'ru'
    };

    if (
      productInvoiceData?.length > 0
      // && zeroProductsData.length === 0
    ) {
      setLoading(true);

      try {
        dispatch(showAlert({ title: 'Завершения данных...', type: 'info' }));

        for (const [index, chunk] of chunks.entries()) {
          const dataToSend = {
            ...savedData,
            products: chunk,
            first_part: index === 0
          };
          await productSearchService.createProduct(dataToSend);
        }

        setOpenSupperModal(false);

        await invoiceService.updateInvoice(newInvoiceId, { status: true });

        navigate('/purchase/create');
        dispatch(
          showAlert({
            title: 'Закуп успешно завершен!',
            type: 'success'
          })
        );
        setLoading(false);

        barcodeInputRef.current?.focus();
      } catch (error) {
        dispatch(showAlert({ title: 'Ошибка при Завершения!', type: 'error' }));
        setLoading(false);
      }
    }
  };

  const sumOfAllCost = productInvoiceData.reduce((acc: any, item: any) => {
    const price = +item?.all_cost || 0;
    const quantity = String(item?.quantity) || '0';

    let integerPart = 0;
    let numerator = 0;
    let denominator = 1; // Default denominator to 1 if no fraction

    // Check if quantity contains '/'
    // eslint-disable-next-line
    if (quantity?.includes('/')) {
      // eslint-disable-next-line
      [numerator, denominator] = quantity?.split('/').map(Number);
      integerPart = numerator; // Whole number part
      // eslint-disable-next-line
      denominator = denominator; // Remainder of the division
    } else {
      integerPart = +quantity;
    }

    // Package quantity
    const packageQuantity = +item?.amount || 1;
    // Calculate the fractional proportion
    const fractionalProportion = (denominator * 100) / packageQuantity;
    // Calculate adjusted quantity
    const adjustedQuantity = quantity?.includes('/')
      ? `${integerPart}.${fractionalProportion}`
      : integerPart;

    const allCost =
      item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
        ? price * +adjustedQuantity
        : price * item?.quantity;
    return acc + allCost;
  }, 0);

  const sumOfPackageAmount = productInvoiceData.reduce(
    (acc: any, item: any) => {
      const priceIn = +item?.package_amount || 0;
      const quantity = String(item?.quantity) || '0';
      let integerPart = 0;
      let numerator = 0;
      let denominator = 1; // Default denominator to 1 if no fraction

      // Check if quantity contains '/'
      if (quantity?.includes('/')) {
        [numerator, denominator] = quantity.split('/').map(Number);
        integerPart = numerator; // Whole number part
        // eslint-disable-next-line
        denominator = denominator; // Remainder of the division
      } else {
        integerPart = +quantity;
      }

      // Package quantity
      const packageQuantity = +item?.amount || 1;
      // Calculate the fractional proportion
      const fractionalProportion = (denominator * 100) / packageQuantity;
      // Calculate adjusted quantity
      const adjustedQuantity = quantity?.includes('/')
        ? `${integerPart}.${fractionalProportion}`
        : integerPart;

      const allCost =
        item?.measure_unit_id === '443bfff1-61e0-4057-8583-d040dc5a0454'
          ? priceIn * +adjustedQuantity
          : priceIn * item?.quantity;
      return acc + allCost;
    },
    0
  );

  useEffect(() => {
    if (confirmToDelete) {
      handleFinish();
    }
  }, [confirmToDelete]);

  return (
    <div>
      <PageBreadcrumb items={invoceBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Новый</h2>

        <PrintingFilter
          newInvoiceId={newInvoiceId}
          productInvoiceData={productInvoiceData}
          modalCreate={modalCreate}
          setModalCreate={setModalCreate}
          isVatPayerValue={isVatPayerValue}
          setIsVatPayerValue={setIsVatPayerValue}
          ndsData={ndsData}
          setNdsData={setNdsData}
          setNdsPrice={setNdsPrice}
          ndsPrice={ndsPrice}
          setNdsPriceWholsale={setNdsPriceWholsale}
          ndsPriceWholsale={ndsPriceWholsale}
          setProductInvoiceData={setProductInvoiceData}
          barcodeInputRef={barcodeInputRef}
          focusRef={focusRef}
          setSaleToMinus={setSaleToMinus}
          saleToMinus={saleToMinus}
          setIsForced={setIsForced}
        />
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <InvoiceTable />
            {loading && (
              <div className="loading-table">
                <span>
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: '#8200BF', width: '3rem', height: '3rem' }}
                  ></Spinner>
                </span>
              </div>
            )}
          </div>
        </AdvanceTableProvider>

        <SupperModal
          show={openSupperModal}
          setShow={setOpenSupperModal}
          setConfirm={setConfirmToDelete}
          title="Закуп по накладной"
          bodyText="Вы хотите завершить закуп по накладной?"
        />

        <div className="footer-purchase">
          <div className="box">
            <p className="mb-0 text-bold">Итого сумма поступления</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfPackageAmount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfPackageAmount)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">Итого сумма продажи</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfAllCost
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfAllCost)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">Итого разница</p>
            <span style={{ fontWeight: '800' }}>
              {sumOfAllCost && sumOfPackageAmount
                ? new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 4,
                    useGrouping: true
                  })
                    .format(sumOfAllCost - sumOfPackageAmount)
                    .replace(/,/g, ' ')
                : 0}
            </span>
          </div>

          <div className="d-flex gap-2">
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => handleSave()}
              loading={loading}
            >
              Сохранить
            </Button>
            <Button
              variant="primary"
              onClick={() => setOpenSupperModal(true)}
              loading={loading}
            >
              Завершить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseInvoce;

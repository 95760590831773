/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import { useMemo, useState, useEffect } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import ExpensesFilter from './ExpensesFilter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import expensesService from 'service/expenses';
import './styles.scss';

export type ExpensesData = {
  contacts: string;
  source: string;
  currency: string;
  cost_type: string;
  cost_amount: string;
  note: string;
  date_create: string;
};

export const expensesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Затраты',
    active: true
  }
];

export const expensesDataTableColumns: ColumnDef<ExpensesData>[] = [
  {
    accessorKey: 'contacts',
    header: 'Контакты',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'source',
    header: 'Источник',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'cost_type',
    header: 'Тип затрат',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'cost_amount',
    header: 'Сумма затрат',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'note',
    header: 'Заметка',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'date_create',
    header: 'Дата создания',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

const Expenses = () => {
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [optionUserId, setOptionUserId] = useState('');
  const [optionArticleId, setOptionArticleId] = useState('');
  const [optionCurrencyId, setOptionCurrencyId] = useState('');
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get EXpenses
  const { data, refetch } = useQuery(
    ['GET_EXPENSES'],
    () => {
      const res = expensesService
        .getList({
          offset: 0,
          limit: 10,
          date_from: dateFrom,
          date_to: adjustedDateTo,
          user_id: optionUserId ? optionUserId : undefined,
          article_id: optionArticleId ? optionArticleId : undefined,
          currency_id: optionCurrencyId ? optionCurrencyId : undefined
        })
        .then((res: any) => res?.expenses);
      return res;
    },
    {
      enabled:
        !!dateFrom ||
        !!adjustedDateTo ||
        !!optionUserId ||
        !!optionArticleId ||
        !!optionCurrencyId
    }
  );

  const expenseData: ExpensesData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          contacts: items?.employee?.phone_number,
          cost_amount: items?.amount ?? 0,
          source: items?.expense_source?.name?.ru,
          currency: items?.currency?.name?.ru,
          cost_type: items?.article?.name?.ru,
          note: items?.note,
          /* prettier-ignore */
          date_create: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', '')
        };
      }) ?? []
    );
  }, [data]);

  const table = useAdvanceTable({
    data: expenseData,
    columns: expensesDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sumOfAllCost = data?.reduce((acc: any, item: any) => {
    const allCost = item?.amount === undefined ? 0 : +item.amount;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionUserId, optionArticleId, optionCurrencyId]);

  return (
    <div>
      <PageBreadcrumb items={expensesBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Затраты</h2>

        <ExpensesFilter
          refetch={refetch}
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionUserId={setOptionUserId}
          setOptionArticleId={setOptionArticleId}
          setOptionCurrencyId={setOptionCurrencyId}
        />

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <div className="footer-expen">
          <div className="box">
            <p className="mb-0 text-bold">Итого сумма недостач:</p>
            <span>{sumOfAllCost}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface PriceListFilterProps {
  handleSearchInputChange?: any;
}

const PriceListFilter = ({ handleSearchInputChange }: PriceListFilterProps) => {
  return (
    <div className="d-flex gap-2">
      <div className="form-icon-container">
        <Form.Floating>
          <Form.Control
            type="text"
            placeholder="Выведите товар"
            onFocus={e => e.target.select()}
            onChange={e => {
              handleSearchInputChange(e);
            }}
            className="form-icon-input"
          />
          <label
            htmlFor="floatingInputCustom"
            className="form-icon-label text-700"
          >
            Выведите товар
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faSearch} className="text-900 fs-9 form-icon" />
      </div>
    </div>
  );
};

export default PriceListFilter;

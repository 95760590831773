import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import SignInForm from './SignInForm';

const SignIn = () => {
  return (
    <AuthSimpleLayout>
      <SignInForm />
    </AuthSimpleLayout>
  );
};

export default SignIn;

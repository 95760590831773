import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const legalEntityBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Юридические лица',
    active: true
  }
];

export type LegalEntityType = {
  physical_name: string;
  type_physical_name: string;
  kinds: string;
  def_currency: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  status: string;
  guid: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import orderOutService from 'service/orderOut';
import { OutgoingType } from 'data/outgoing';

interface OutgoingTableProps {
  dateFrom?: any;
  dateTo?: any;
  optionSenderId?: any;
  optionStatusId?: any;
}

const OutgoingTable = ({
  dateFrom,
  dateTo,
  optionSenderId,
  optionStatusId
}: OutgoingTableProps) => {
  const navigate = useNavigate();

  const userLegalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  const navigateTo = (row: any) => {
    navigate(
      `/applications/outgoing/applications-result/${row?.original?.guid}`
    );
  };
  /* prettier-ignore */
  const adjustedDateTo =
    !dateFrom && !dateTo
      ? undefined
      : dateTo === dateFrom
        ? dateFrom + 86400000
        : dateTo
          ? dateTo + 86400000
          : undefined;

  //Get Order
  const { data, refetch } = useQuery(
    ['GET_ORDER_OUT_MAIN'],
    async () => {
      const res = await orderOutService
        .getList({
          offset: 0,
          limit: 100,
          status_id: optionStatusId ? optionStatusId : undefined,
          recipient_id: optionSenderId ? optionSenderId : undefined,
          date_from: dateFrom,
          date_to: adjustedDateTo
        })
        .then((res: any) => res?.orders);
      return res;
    },
    {
      enabled:
        !!dateFrom || !!adjustedDateTo || !!optionSenderId || !!optionStatusId
    }
  );

  const outgoingData: OutgoingType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          orders: items?.order_number,
          status: items?.status?.name?.ru,
          sender: items?.legal_entity?.name,
          currency: items?.currency?.name?.uz,
          recipient: userLegalEntityName,
          /* prettier-ignore */
          create_date: new Date(items?.date_create)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          can_update: items?.can_update,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const outgoingTableColumns: ColumnDef<OutgoingType>[] = [
    {
      accessorKey: 'orders',
      header: '№ заявки',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sender',
      header: 'Отправитель',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'recipient',
      header: 'Получатель',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояния',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'create_date',
      header: 'Дата создания',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: outgoingData,
    columns: outgoingTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    refetch();
  }, [dateFrom, dateTo, optionSenderId, optionStatusId]);

  return (
    <AdvanceTableProvider {...table}>
      <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          navigateTo={navigateTo}
        />
        <AdvanceTableFooter pagination />
      </div>
    </AdvanceTableProvider>
  );
};

export default OutgoingTable;

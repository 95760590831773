import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const walletBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/directories/stock'
  },
  {
    label: 'Кошелек',
    active: true
  }
];

export type WalletType = {
  name: string;
  currency: string;
  balance: string;
  status: string;
  guid: string;
  currency_id: string;
};

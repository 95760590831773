/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo, useEffect, useRef } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

import useAdvanceTable from 'hooks/useAdvanceTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import productSearchService from 'service/productSearch';
import Button from 'components/base/Button';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

export type SearchProduct = {
  name: string;
  barcode: string;
  unit: string;
  quantity: string;
  price_in: string;
};

interface ChildTableDataProps {
  setOpenTable: (show: boolean) => void;
  reset?: any;
  setSearchedValue: any;
}

const ChildTableData = ({
  setOpenTable,
  reset,
  setSearchedValue
}: ChildTableDataProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [getBarcode, setGetBarcode] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const inputSearchRef = useRef<HTMLInputElement | any>();

  const { data, isFetching, isLoading } = useQuery(
    ['GET_PRODUCT', searchValue, getBarcode, currentPage],
    () => {
      const res = productSearchService
        .getListProduct({
          offset: pageToOffset(currentPage, 10),
          limit: 10,
          is_global: true,
          name: searchValue,
          barcode: getBarcode
        })
        .then((res: any) => {
          setPageCount(Math.ceil(res?.count / 10));
          return res?.products;
        });
      return res;
    },
    {
      enabled:
        !!currentPage || !searchValue || searchValue?.length > 2 || !getBarcode
    }
  );

  const loadingData = isLoading || isFetching;

  const generateData: SearchProduct[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          barcode: items?.barcode,
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          quantity: items?.total_quantity,
          price_in: items?.price_in,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, searchValue]);

  const searchProductTableColumns: ColumnDef<SearchProduct>[] = [
    {
      id: 'action',
      cell: rowData => {
        const createdData = {
          name: rowData?.row?.original?.name,
          barcode: rowData?.row?.original?.barcode
        };

        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-2"
              onClick={() => {
                reset(createdData);
                setSearchedValue(`${rowData?.row?.original?.barcode}`);
                setOpenTable(false);
              }}
            >
              <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'original_barcode',
      header: 'Штрих-код оригинал',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'unit',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: generateData,
    columns: searchProductTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (inputSearchRef.current) {
      inputSearchRef.current.focus();
    }
  }, [inputSearchRef]);

  return (
    <div>
      <div className="mb-4 d-flex gap-2">
        <div className="form-icon-container">
          <Form.Floating>
            <Form.Control
              type="text"
              placeholder="Отсканируйте штрих-код"
              onFocus={e => e.target.select()}
              onChange={e => setGetBarcode(e.target.value)}
              className="form-icon-input"
              autoComplete="off"
              ref={inputSearchRef}
            />
            <label
              htmlFor="floatingInputCustom"
              className="form-icon-label text-700"
            >
              Отсканируйте штрих-код
            </label>
          </Form.Floating>
          <FontAwesomeIcon
            icon={faBarcode}
            className="text-900 fs-9 form-icon"
          />
        </div>
        <SearchBox
          placeholder="Выведите наименования товара для поиска"
          onChange={e => setSearchValue(e?.target?.value)}
        />
      </div>
      <AdvanceTableProvider {...table}>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table fs-9' }}
          isLoading={loadingData}
        />
        <AdvanceTableCPagination
          count={pageCount}
          page={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </AdvanceTableProvider>
    </div>
  );
};

export default ChildTableData;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const walletService = {
  getList: (params: any) => request.get('/wallet', { params }),
  getWalletById: (id: any) => request.get(`/wallet/${id}`),
  createWallet: (data: any) => request.post('/wallet', data),
  deleteWallet: (id: any) => request.delete(`/wallet/${id}`),
  updateWallet: (id: any, data: any) => request.put(`/wallet/${id}`, data),

  getWalletSettlementById: (id: any) => request.get(`/wallet/settlement/${id}`),
  createWalletSettlement: (data: any) =>
    request.post('/wallet/settlement', data)
};

export default walletService;

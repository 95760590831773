/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/base/Button';
import SearchProductInventory from './SearchProductInventory';
import { isValidGtin } from 'utils/isValidGtin';
import productSearchService from 'service/productSearch';
import { showAlert } from 'store/alert/alert.thunk';
import inventoryItemService from 'service/inventoryItem';
import usePageSearchParams from 'utils/usePageSearchParams';
import './styles.scss';

interface SelectionProductProps {
  setOpenProduct: (show: boolean) => void;
  refetchInventoryItem?: any;
  tableData?: any;
  setTableData?: any;
}

const SelectionProduct = ({
  setOpenProduct,
  refetchInventoryItem,
  tableData,
  setTableData
}: SelectionProductProps) => {
  const { pathname } = useLocation();
  const inventoryId = pathname.split('/').pop();
  const [searchParams] = usePageSearchParams();
  const warehouseId = searchParams.get('warehouse_id');

  const { register, getValues, reset, control, watch } = useForm();
  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();
  const inputBarcodeRef = useRef<HTMLInputElement | any>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchedValue, setSearchedValue] = useState<any>('');
  const [disabledField, setDisabledField] = useState(false);
  const [focusToInput, setFocusToInput] = useState(false);
  const [updatedBalance, setUpdatedActualBalance] = useState('');
  const [disabledSearchinput, setDisabledSearchInput] = useState(false);

  const handleGetProduct = (e: any) => {
    if (e.key === 'Enter') {
      if (!isValidGtin(searchedValue)) {
        dispatch(showAlert({ title: 'Штрих-код не найден' }));
        reset({
          product_name: '',
          current_balance: '',
          current_inventory_balance: ''
        });
        setDisabledField(false);
      }

      if (
        searchedValue !== '' &&
        isValidGtin(searchedValue) &&
        warehouseId !== ''
      ) {
        setDisabledSearchInput(true);
        productSearchService
          .getListProduct({
            offset: 0,
            limit: 20,
            warehouse_id: warehouseId,
            barcode: searchedValue ? searchedValue : undefined
          })
          .then((res: any) => {
            console.log('Res', res);
            if (res?.products) {
              const computed = {
                product_name: res?.products?.[0]?.name,
                current_balance: res?.products?.[0]?.total_quantity,
                nomenclature_id: res?.products?.[0]?.nomenclature_id,
                current_inventory_balance:
                  tableData[res?.products?.[0]?.barcode]?.quantity_fact ?? 0,
                currency_id: res?.products?.[0]?.currency_id,
                price_in: res?.products?.[0]?.price_in,
                product_id: res?.products?.[0]?.id,
                measure_unit_kind: res?.nomenclature?.measure_unit_kind_id
              };

              if (inputRef.current) {
                inputRef?.current.focus();
                inputRef?.current.select();
              }
              reset(computed);
              setDisabledField(true);
              setDisabledSearchInput(false);
            } else {
              dispatch(showAlert({ title: 'Штрих-код не найден' }));
              reset({
                product_name: '',
                current_balance: '',
                current_inventory_balance: ''
              });
              setDisabledField(false);
              setDisabledSearchInput(false);
            }
          });
      }
    }
  };

  const onSubmit = () => {
    const data = getValues();

    // Parse the current and updated balance to ensure proper numeric comparison
    const currentInventoryBalance =
      parseFloat(data?.current_inventory_balance) || 0;
    const updatedBalanceNumber = parseFloat(updatedBalance) || 0;

    if (currentInventoryBalance + updatedBalanceNumber < 0) {
      dispatch(
        showAlert({
          title: 'Количества не должен быть меньше нуля'
        })
      );
      return; // Exit early to prevent further processing
    }

    const createdData = {
      quantity: +data?.current_balance,
      quantity_actual: currentInventoryBalance + updatedBalanceNumber,
      nomenclature_id: data.nomenclature_id,
      currency_id: data.currency_id,
      price_in: data.price_in,
      product_id: data.product_id,
      status_id: 'ba94f8a8-3a42-4d1e-a351-0e5349e3f512' //new status
    };

    const existingProduct = tableData?.[+searchedValue];

    if (existingProduct) {
      inventoryItemService
        .updateInventoryItem(tableData[+searchedValue].guid, {
          quantity_actual: createdData?.quantity_actual
        })
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({
                title: 'Продукт успешно сохранен !',
                type: 'success'
              })
            );
            setTableData((prevTableData: any) => ({
              ...prevTableData,
              [+searchedValue]: {
                guid: tableData[+searchedValue].guid,
                quantity_fact: createdData?.quantity_actual
              }
            }));

            reset({
              scan: '',
              product_name: '',
              current_balance: '',
              current_inventory_balance: '',
              actual_balance: ''
            });
            setDisabledField(false);
            setSearchedValue('');
            setUpdatedActualBalance('');
          }
        });
    } else {
      /* prettier-ignore */
      inventoryItemService
        .createInventoryItem({
          inventory_id: inventoryId,
          inventory_items: [createdData]
        })
        .then((res: any) => {
          if (res) {
            dispatch(
              showAlert({ title: 'Продукт успешно добавлен!', type: 'success' })
            );
            setTableData((prevTableData: any) => ({
              ...prevTableData,
              [+searchedValue]: {
                guid: res?.inventory_items[0]?.id,
                quantity_fact: createdData?.quantity_actual
              }
            }));

            reset({
              scan: '',
              product_name: '',
              current_balance: '',
              current_inventory_balance: '',
              actual_balance: ''
            });
            setDisabledField(false);
            setSearchedValue('');
            setUpdatedActualBalance("");
          }
        });
    }
  };

  const handleClose = () => {
    setOpenProduct(false);
    refetchInventoryItem();
  };

  const handleBalanceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (watch('measure_unit_kind') === '2604393f-4d7a-439d-9ee4-831756ad873b') {
      newValue = newValue.replace(',', '.');
      if (/^-?\d*\.?\d*$/.test(newValue)) {
        setUpdatedActualBalance(newValue);
      }
    } else {
      if (/^-?\d*$/.test(newValue)) {
        setUpdatedActualBalance(newValue);
      }
    }
  };

  useEffect(() => {
    if (inputBarcodeRef.current && searchedValue === '') {
      inputBarcodeRef.current.focus();
    }
  }, [inputBarcodeRef, searchedValue]);

  useEffect(() => {
    if (inputRef.current && focusToInput) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [focusToInput]);

  return (
    <>
      <Form
        noValidate
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        <Row className="mb-2">
          <Col className="modal-rows">
            <Controller
              name="scan"
              control={control}
              render={({ field }) => (
                <Form.Floating className="first-item">
                  <Form.Control
                    {...field}
                    ref={inputBarcodeRef}
                    type="text"
                    value={searchedValue}
                    placeholder="Отсканируйте штрих-код"
                    onChange={e => setSearchedValue(e?.target?.value)}
                    onKeyDown={e => handleGetProduct(e)}
                    onFocus={e => {
                      e.target.select();
                    }}
                    autoComplete="off"
                    disabled={disabledSearchinput}
                  />
                  <label htmlFor="floatingInputCustom">
                    Отсканируйте штрих-код
                  </label>
                </Form.Floating>
              )}
            />

            <Button
              variant="primary"
              type="button"
              onClick={() => {
                setOpenSearch(true);
                setFocusToInput(false);
              }}
            >
              Поиск
            </Button>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('product_name')}
                type="text"
                placeholder="Наименование товара"
                disabled={disabledField}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">Наименование товара</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('current_balance')}
                type="text"
                placeholder="Текущий остаток"
                disabled={disabledField}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">Текущий остаток</label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Floating>
              <Form.Control
                {...register('current_inventory_balance')}
                type="text"
                placeholder="Текущий инвентаризованый остаток"
                disabled={disabledField}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">
                Текущий инвентаризованый остаток
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Controller
              name="actual_balance"
              control={control}
              render={({ field }) => (
                <Form.Floating>
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    value={updatedBalance}
                    onChange={handleBalanceChange}
                    placeholder="Фактический остаток"
                    onFocus={e => e.target.select()}
                    autoComplete="off"
                    onKeyDown={e => {
                      if (e?.key === 'Enter') {
                        onSubmit();
                      }
                    }}
                  />
                  <label htmlFor="floatingInputCustom">
                    Фактический остаток
                  </label>
                </Form.Floating>
              )}
            />
          </Col>
        </Row>

        <Form.Group className="d-flex gap-2 mt-auto">
          <Button
            style={{ height: '48px' }}
            className="w-100"
            type="button"
            variant="outline-secondary"
            onClick={() => handleClose()}
          >
            Завершить выбора товара
          </Button>
          <Button
            style={{ height: '48px' }}
            className="w-100"
            variant="primary"
            type="button"
            onClick={() => onSubmit()}
          >
            Добавить товар
          </Button>
        </Form.Group>
      </Form>

      <Modal
        show={openSearch}
        onHide={() => setOpenSearch(false)}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Поиск товара</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchProductInventory
            setOpenSearch={setOpenSearch}
            setDisabledField={setDisabledField}
            reset={reset}
            setSearchedValue={setSearchedValue}
            tableData={tableData}
            setFocusToInput={setFocusToInput}
            warehouseId={warehouseId}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectionProduct;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import { useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import usePageSearchParams from 'utils/usePageSearchParams';
import SettelementCustomerHistory from './SettelementCustomerHistory';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import customerEntityService from 'service/charterer';
import Button from 'components/base/Button';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export type SettelementData = {
  product_provider: string;
  currency: string;
  debt: string;
  credit: string;
  remainder: string;
  status: string;
  guid: string;
};

const SettelementCustomerResultTable = () => {
  const [openSettelementHistory, setOpenSettelementHistory] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  const { pathname } = useLocation();
  const [searchParams] = usePageSearchParams();

  const debitor_id = pathname.split('/').pop();
  const currency_id = searchParams.get('currency_id');

  const { data } = useQuery(
    ['GET_PROVIDER_RESULT'],
    () => {
      if (debitor_id && currency_id) {
        const res = customerEntityService
          .getListCharterer({
            offset: 0,
            limit: 100,
            debitor_id: debitor_id,
            currency_id: currency_id
          })
          .then((res: any) => res?.debit_credit_list);
        return res;
      }
    },
    { enabled: !!debitor_id && !!currency_id }
  );

  const resultData: SettelementData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_provider: items?.debitor?.name,
          debt: items?.amount_debit ?? 0,
          credit: items?.amount_credit ?? 0,
          remainder:
            (parseFloat(items?.amount_debit) || 0) -
            (parseFloat(items?.amount_credit) || 0),
          status: items?.status?.name?.ru,
          currency: items?.currency?.name?.ru,
          creditor_id: items?.creditor_id,
          debitor_id: items?.debitor_id,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Дт Кт',
      url: '/dtkt/provider'
    },
    {
      label: 'Взаиморасчет с поставщиком',
      url: '/dtkt/buyyers'
    },
    {
      label: data && data[0]?.debitor?.name,
      active: true
    }
  ];

  const settelementDataTableColumns: ColumnDef<SettelementData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => {
              setOpenSettelementHistory(true);
              setSelectedCustomerId(rowData?.row?.original?.guid);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faClockRotateLeft}
              transform={{ size: 20 }}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'product_provider',
      header: 'Наименование поставщика ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'debt',
      header: 'Дебет',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'credit',
      header: 'Кредит',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'remainder',
      header: 'Остаток',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: resultData,
    columns: settelementDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">{data && data[0]?.debitor?.name}</h2>
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Modal
            show={openSettelementHistory}
            onHide={() => {
              setOpenSettelementHistory(false);
              setSelectedCustomerId('');
            }}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            keyboard={false}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                История взаиморасчет
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SettelementCustomerHistory
                selectedCustomerId={selectedCustomerId}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default SettelementCustomerResultTable;

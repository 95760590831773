import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const currencyBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/directories/stock'
  },
  {
    label: 'Валюта',
    active: true
  }
];

export type CurrencyType = {
  name: string;
  code: string;
  number_code: string;
  banknot: string;
  country: string;
  main_currenct: string;
  status: string;
  guid: string;
};

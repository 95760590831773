/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import AccountTable from './AccountTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import CreateAccount from './CreateAccount';
import accountsService from 'service/account';
import SupperModal from 'components/modals/SupperModal';

export const accountsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Счета',
    active: true
  }
];

export type AccountsType = {
  name: string;
  status: string;
  guid: string;
};

const Account = () => {
  const [openAccount, setOpenAccount] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  //Get Account
  const { data, refetch } = useQuery(['GET_ACCOUNT'], () => {
    const res = accountsService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.accounts);
    return res;
  });

  const accountData: AccountsType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.ru,
          status: items?.status === true ? 'Активный' : 'Заблокирован',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const accountsTableColumns: ColumnDef<AccountsType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            variant="hover"
            className="p-2"
            onClick={() => {
              setOpenAccount(true);
              setSelectedAccountId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            variant="hover"
            className="p-2"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Наименование счета',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: accountData,
    columns: accountsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      accountsService.deleteAccount(selectedInvoiceId).finally(() => {
        refetch();
        setSelectedAccountId('');
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={accountsBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">Счета</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder="Search accounts"
                onChange={handleSearchInputChange}
              />

              <Button variant="primary" onClick={() => setOpenAccount(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Добавить
              </Button>
            </div>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AccountTable />
          </div>

          <SupperModal
            show={openSupperModal}
            setShow={setOpenSupperModal}
            setConfirm={setConfirmToDelete}
            title="Счета"
            bodyText="Вы действительно хотите удалить счета?"
          />

          <Offcanvas
            show={openAccount}
            onHide={() => {
              setOpenAccount(false);
              setSelectedAccountId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                Новый
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateAccount
                setOpenAccount={setOpenAccount}
                selectedAccountId={selectedAccountId}
                refetch={refetch}
                setSelectedAccountId={setSelectedAccountId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Account;

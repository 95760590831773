/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from 'react-query';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AddProvider from './create/AddProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import providerEntityService from 'service/providerEntity';
// import SupperModal from 'components/modals/SupperModal';

export type ProviderData = {
  individual: string;
  activity: string;
  country: string;
  region: string;
  area: string;
  address: string;
  phone: string;
  guid: string;
  is_custom: boolean;
};

export const providerBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/provider'
  },
  {
    label: 'Поставщик',
    active: true
  }
];

const ProviderPage = () => {
  const [openProvider, setOpenProvider] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState('');
  // const [openSupperModal, setOpenSupperModal] = useState(false);
  // const [confirmToDelete, setConfirmToDelete] = useState(false);
  // const [selectedId, setSelectedId] = useState<string | null>(null);

  //Get Provider
  const { data, refetch } = useQuery(['GET_CUSTOM_PROVIDER'], () => {
    const res = providerEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.legal_entities);
    return res;
  });

  // const handleDelete = (id: string) => {
  //   setSelectedId(id);
  //   setOpenSupperModal(true);
  // };

  const providerEntityData: ProviderData[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          individual: items?.name,
          activity: items?.activity_kind?.name?.ru,
          country: items?.country?.name?.ru,
          region: items?.region?.name?.ru,
          area: items?.district?.name?.ru,
          address: items?.address,
          phone: items?.phone_number,
          is_custom: items?.is_custom,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data]);

  const providerDataTableColumns: ColumnDef<ProviderData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <>
            {rowData?.row?.original?.is_custom ? (
              <div className="d-flex gap-2">
                <Button
                  variant="hover"
                  className="p-2"
                  onClick={() => {
                    setSelectedProviderId(rowData?.row?.original?.guid),
                      setOpenProvider(true);
                  }}
                >
                  <FeatherIcon
                    icon="edit-2"
                    className="cursor-pointer"
                    size={20}
                  />
                </Button>
                {/* <Button
                  variant="hover"
                  className="p-2"
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDelete(rowData?.row?.original?.guid);
                  }}
                >
                  <FeatherIcon
                    icon="trash-2"
                    className="cursor-pointer text-danger"
                    size={20}
                  />
                </Button> */}
              </div>
            ) : (
              ''
            )}
          </>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'individual',
      header: 'Физическое лицо',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'activity',
      header: 'Вид деятельности',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country',
      header: 'Страна',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'region',
      header: 'Регион',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: 'Район',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'address',
      header: 'Адрес',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'phone',
      header: 'Телефон',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: providerEntityData,
    columns: providerDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  // useEffect(() => {
  //   if (selectedId && confirmToDelete) {
  //     providerEntityService.delete(selectedId).finally(() => {
  //       refetch();
  //       setSelectedProviderId('');
  //       setOpenSupperModal(false);
  //       setConfirmToDelete(false);
  //       setSelectedId(null);
  //     });
  //   }
  // }, [confirmToDelete, selectedId, refetch]);

  return (
    <div>
      <PageBreadcrumb items={providerBreadcrumbItems} />

      <div className="d-flex justify-content-between align-items-center">
        <h2>Поставщик</h2>
        <Button
          variant="primary"
          style={{ height: '48px' }}
          onClick={() => setOpenProvider(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Новый
        </Button>
      </div>
      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      {/* <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Поставщик"
        bodyText="Вы действительно хотите удалить поставщик?"
      /> */}

      <Offcanvas
        show={openProvider}
        onHide={() => {
          setOpenProvider(false);
          setSelectedProviderId('');
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Новый
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddProvider
            setOpenProvider={setOpenProvider}
            selectedProviderId={selectedProviderId}
            refetch={refetch}
            setSelectedProviderId={setSelectedProviderId}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ProviderPage;

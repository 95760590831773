/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returnReasonService = {
  getList: (params: any) =>
    request.get('/guidebook/product-return-reason', { params }),
  getReturnReasonById: (id: any) =>
    request.get(`/guidebook/product-return-reason/${id}`),
  createReturnReason: (data: any) =>
    request.post('/guidebook/product-return-reason', data),
  deleteReturnReason: (id: any) =>
    request.delete(`/guidebook/product-return-reason/${id}`),
  updateReturnReason: (id: any, data: any) =>
    request.put(`/guidebook/product-return-reason/${id}`, data)
};

export default returnReasonService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTable from 'components/base/AdvanceTable';
import { ComingResult } from 'data/returning';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import returnItemService from 'service/returnItemService';
import returnInService from 'service/returnIn';
import returnService from 'service/return';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import './styles.scss';

export const comingResultTableColumns: ColumnDef<ComingResult>[] = [
  {
    accessorKey: 'product_name',
    header: 'Наименование товара',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'barcode',
    header: 'Штрих-код',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'currency',
    header: 'Валюта',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'units',
    header: 'Единица измерения',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'cost',
    header: 'Цена оптивоя',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'amount',
    header: 'Кол.Заказа',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'history',
    header: 'Итоговая закуп цена',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Состояния',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

const ComingResultReturning = () => {
  const { pathname } = useLocation();
  const returningId = pathname.split('/').pop();
  const dispatch: Dispatch<any> = useDispatch();

  const [canUpdate, setCanUpdate] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [finishLoading, setFinishLoading] = useState(false);

  //Fetch Users By ID
  const { refetch: refetchIn } = useQuery(
    ['GET_RETURN_IN_CAN_UPDATE', returningId],
    async () => {
      if (returningId) {
        const res = await returnInService
          .getList({
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.returns;
          });

        if (res) {
          const updatedRes = res?.find((el: any) => el?.id === returningId);
          setCanUpdate(updatedRes?.can_update ?? false);
        }
      }
    },
    { enabled: !!returningId }
  );

  const { data, refetch } = useQuery(
    ['GET_RETURN_ITEM_IN', returningId],
    () => {
      if (returningId) {
        const res = returnItemService
          .getReturnItemInById(returningId, {
            offset: 0,
            limit: 10
          })
          .then((res: any) => {
            return res?.return_items;
          });
        return res;
      }
    },
    { enabled: !!returningId }
  );

  const comingResultData: ComingResult[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          guid: items?.id,
          nomenclature_id: items?.nomenclature_id,
          recipient_id: items?.recipient_id,
          product_name: items?.product_short_name,
          product_id: items?.product_id,
          parent_product_id: items?.parent_product_id,
          barcode: items?.barcode,
          currency: items?.currency?.name?.ru,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity,
          status: items?.status?.name?.ru,
          cost: items?.price_in ?? 0,
          remainder: items?.total_quantity ?? 0,
          /* prettier-ignore */
          history:
            items?.price_in && items?.quantity ? +items.price_in * +items.quantity: 0
        };
      }) ?? [];

    return resultData;
  }, [data]);

  const buyyersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Возвраты',
      url: '/returning/outgoing'
    },
    {
      label: 'Входящий',
      url: '/returning/coming'
    },
    {
      label: '№ ЗАЯВКИ',
      active: true
    }
  ];

  const table = useAdvanceTable({
    data: comingResultData,
    columns: comingResultTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sum = comingResultData.reduce((acc: any, item: any) => {
    const allCost = item?.history === undefined ? 0 : +item?.history;
    return acc + allCost;
  }, 0);

  const handleSave = () => {
    setSaveLoading(true);
    returnService.getReturnById(returningId).then((res: any) => {
      if (res) {
        returnInService
          ?.updateReturnIn(returningId, {
            ...res,
            status_id: '00525476-8e64-40bc-9dd2-083583c95b30'
          })
          .then((inresponse: any) => {
            if (inresponse) {
              dispatch(
                showAlert({
                  title: 'Отвечен успешно!',
                  type: 'success'
                })
              );
              refetch();
              refetchIn();
            }
          });
      }
    });
  };

  const handleFinished = () => {
    setFinishLoading(true);
    if (returningId) {
      returnService.getReturnById(returningId).then((res: any) => {
        if (res) {
          returnInService
            ?.updateReturnIn(returningId, {
              ...res,
              status_id: '4d22cd85-070e-4779-afcb-8f0be603b60d'
            })
            .then((inresponse: any) => {
              if (inresponse) {
                dispatch(
                  showAlert({
                    title: 'Отклонен успешно!',
                    type: 'success'
                  })
                );
                refetch();
                refetchIn();
              }
            });
        }
      });
    }
  };

  return (
    <div>
      <PageBreadcrumb items={buyyersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">№ ЗАЯВКИ</h2>

        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>
        <div className="footer-ret-coming">
          <div className="box">
            <p className="mb-0 text-bold">Итого на сумму</p>
            <span>{sum ?? 0}</span>
          </div>

          <div className="main-btn-group">
            <Button
              onClick={() => handleSave()}
              disabled={!canUpdate}
              variant="primary"
              loading={saveLoading}
            >
              Ответить
            </Button>
            <Button
              onClick={() => handleFinished()}
              disabled={!canUpdate}
              variant="danger"
              loading={finishLoading}
            >
              Отклонить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingResultReturning;

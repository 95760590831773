/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const orderStatusService = {
  getList: (params: any) => request.get('/guidebook/order-status', { params }),
  getOrderStatusById: (id: any) => request.get(`/guidebook/order-status/${id}`),
  createOrderStauts: (data: any) =>
    request.post('/guidebook/order-status', data),
  deleteOrderStatus: (id: any) =>
    request.delete(`/guidebook/order-status/${id}`),
  updateOrderStatus: (id: any, data: any) =>
    request.put(`/guidebook/order-status/${id}`, data)
};

export default orderStatusService;

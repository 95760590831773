/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import inventoryItemService from 'service/inventoryItem';
import SearchBox from 'components/common/SearchBox';

export type CreateInventoryData = {
  product_name: string;
  barcode: string;
  units: string;
  currency: string;
  cost: string;
  accounts_number: string;
  quantity_fact: string;
  shortage: string;
  surplus: string;
  deficiency_amount: string;
  surplus_amount: string;
  status: string;
  guid: string;
};

const InfoResultTable = () => {
  const { pathname } = useLocation();
  const info_id = pathname.split('/').pop();

  const [searchValue, setSearchValue] = useState('');

  const { data, refetch } = useQuery(
    ['GET_INVENTORY_ITEM'],
    () => {
      if (info_id !== '') {
        const res = inventoryItemService
          .getInventoryItemById(info_id, {
            offset: 0,
            limit: 10,
            name: searchValue
          })
          .then((res: any) => res?.inventory_items);

        return res;
      }
    },
    {
      enabled: !!info_id
    }
  );

  const inventoryItemData: CreateInventoryData[] = useMemo(() => {
    const res =
      data?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          cost: items?.price_in,
          accounts_number: items?.quantity ?? 0,
          quantity_fact: items?.quantity_actual ?? 0,
          shortage: items?.shortage ?? 0,

          surplus: items?.excess ?? 0,
          deficiency_amount: items?.shortage ?? 0,
          surplus_amount: items?.excess_total ?? 0,
          status: items?.status?.name?.ru,
          guid: items?.id,
          product_id: items?.product_id
        };
      }) ?? [];

    return res;
  }, [data, info_id]);

  const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Инвентаризация',
      url: '/inventory/create'
    },
    {
      label: 'Информация об инвентаризации',
      url: '/inventory/info'
    },
    {
      label: (data && data?.name) ?? '',
      active: true
    }
  ];

  const createInventoryDataTableColumns: ColumnDef<CreateInventoryData>[] = [
    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: 'Цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accounts_number',
      header: 'Кол-во учёт',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: 'Кол-во факт',

      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shortage',
      header: 'Недостача',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus',
      header: 'Излишка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'deficiency_amount',
      header: 'Сумма недостатка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus_amount',
      header: 'Сумма излишки',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryItemData,
    columns: createInventoryDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const sumOfAllSurplus = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.surplus_amount === undefined ? 0 : +item.surplus_amount;
    return acc + allCost;
  }, 0);

  const sumOfAllDeficiency = inventoryItemData.reduce((acc: any, item: any) => {
    const allCost =
      item?.deficiency_amount === undefined ? 0 : +item.deficiency_amount;
    return acc + allCost;
  }, 0);

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />

      <div>
        <h2 className="mb-5">№1</h2>

        <SearchBox
          placeholder="Выведите наименования товара для поиска"
          onChange={e => setSearchValue(e?.target?.value)}
        />
        <AdvanceTableProvider {...table}>
          <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>
        </AdvanceTableProvider>

        <div className="footer-inv-create">
          <div className="box">
            <p className="mb-0 text-bold">Итого сумма недостач: </p>
            <span>{sumOfAllDeficiency ?? 0}</span>
          </div>

          <div className="box">
            <p className="mb-0 text-bold">Итого сумма излишек: </p>
            <span>{sumOfAllSurplus ?? 0}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoResultTable;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AccordingToInvoiceFilter from './filter/AccordingToInvoiceFilter';
import AccordingToInvoiceTable from './table/AccordingToInvoiceTable';

export const incomeBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Аналитика',
    url: '/analytics/revaluation/revaluation-invoice'
  },

  {
    label: 'Переуценка товаров',
    url: '/analytics/revaluation/revaluation-invoice'
  },

  {
    label: 'Переуценка по накладной',
    active: true
  }
];

const AccordingToInvoice = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [optionWarehouseId, setOptionWarehouseId] = useState('');

  return (
    <div>
      <PageBreadcrumb items={incomeBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Переуценка по накладной</h2>

        <AccordingToInvoiceFilter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setOptionWarehouseId={setOptionWarehouseId}
        />
        <AccordingToInvoiceTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          optionWarehouseId={optionWarehouseId}
        />
      </div>
    </div>
  );
};

export default AccordingToInvoice;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import categoryService from 'service/category';
import countryService from 'service/country';
import unitService from 'service/units';
import productKindService from 'service/productKind';
import nomenclatureService from 'service/nomenclature';
import genBarcodeService from 'service/generate';
import brandService from 'service/brand';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import SpecificationInfo from './specification';
import VariationInfo from './variation';
import UpdataNonVariationTable from './variation/UpdataNonVariationTable';
import productSearchService from 'service/productSearch';

interface NomenclatureProps {
  setModalCreate: (show: boolean) => void;
  searchedValue?: any;
  productInvoiceData?: any;
  setProductInvoiceData?: any;
  isAutoTab?: any;
  focusRef?: any;
}

export type CharacterType = {
  character_name: string;
  values: string;
  specification_id: string;
  id: string;
};

export type VariationType = {
  product: string;
  var_barcode: string;
  vendor_code: string;
  party_number: string;
  code: string;
  var_data: any;
};

const SwitchControl = ({ field, label }: { field: any; label: any }) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className="cursor-pointer"
      checked={field.value}
      onChange={e => field.onChange(e.target.checked)}
    />
  );
};

const Nomenclature = ({
  setModalCreate,
  searchedValue,
  productInvoiceData,
  setProductInvoiceData,
  isAutoTab,
  focusRef
}: NomenclatureProps) => {
  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  const [loading, setLoading] = useState(false);
  const [categoryOption, setCategoryOption] = useState<any>([]);
  const [firstSubCategoryOption, setFirstSubCategoryOption] = useState<any>([]);
  const [secondSubCategoryOption, setSecondSubCategoryOption] = useState<any>(
    []
  );
  const [thirdSubCategoryOption, setThirdSubCategoryOption] = useState<any>([]);
  const [countryOption, setCountryOption] = useState([]);
  const [measureUnitOption, setMeasureUnitOption] = useState<any>([]);
  const [productKindOption, setProductKindOption] = useState<any>([]);
  const [brandOption, setBrandOption] = useState([]);
  const [openCharacter, setOpenCharacter] = useState(false);
  const [openVariation, setOpenVariation] = useState(false);
  const [openUpdateVariation, setOpenUpdateVariataion] = useState(false);
  const [selectedUpdateVariationId, setSelectedUpdateVariationId] =
    useState('');
  const [characterFormData, setCharacterFormData] = useState<CharacterType[]>(
    []
  );
  const [variationFormData, setVariationFormData] = useState<VariationType[]>(
    []
  );

  const [selectedMeasureUnitKindId, setSelectedMeasureUnitKindId] =
    useState('');
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [firstSubCategoryId, setFirstSubCategoryId] = useState('');
  const [secondSubCategoryId, setSecondSubCategoryId] = useState('');

  const handleRemoveItem = (indexToRemove: number) => {
    setVariationFormData(
      variationFormData.filter((_, index) => index !== indexToRemove)
    );
  };

  console.log('errors', errors);

  //GET Category to options
  useQuery(['GET_CATEGORY'], async () => {
    await categoryService
      .getList({
        offset: 0,
        limit: 100
      })
      .then((res: any) => {
        const options = res?.categories
          ?.filter(
            (items: any) =>
              Object.keys(items.parent).length === 0 &&
              items.parent.constructor === Object
          )
          .map((option: any) => ({
            value: option.id,
            label: option.name?.ru
          }));
        setCategoryOption(options);
      });
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.ru
      }));
      setCountryOption(options);
    });
  });

  //GET Measure Unit to options
  useQuery(['GET_MEASURE_UNIT'], async () => {
    await unitService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.measure_units?.map((option: any) => ({
        value: option.id,
        label: option.name?.ru,
        measure_unit_kind_id: option?.measure_unit_kind_id
      }));
      setMeasureUnitOption(options);
    });
  });

  //GET Product kind to options
  useQuery(['GET_PRODUCT_KIND'], async () => {
    await productKindService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.product_kinds?.map((option: any) => ({
          value: option.id,
          label: option.name?.ru
        }));
        setProductKindOption(options);
      });
  });

  //GET Brand to options
  useQuery(['GET_BRAND'], async () => {
    await brandService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.custom_brands?.map((option: any) => ({
        value: option.id,
        label: option.name?.ru
      }));
      setBrandOption(options);
    });
  });

  //sub category 1
  useQuery(
    ['GET_CATEGORY_1', parentCategoryId],
    async () => {
      if (parentCategoryId !== '') {
        await categoryService
          .getList({ offset: 0, limit: 100, parent_id: parentCategoryId })
          .then((res: any) => {
            const options = res?.categories?.map((option: any) => ({
              value: option.id,
              label: option.name?.ru
            }));
            setFirstSubCategoryOption(options);
          });
      }
    },
    { enabled: !!parentCategoryId }
  );

  //sub category 2
  useQuery(
    ['GET_CATEGORY_2', firstSubCategoryId],
    async () => {
      if (firstSubCategoryId !== '') {
        await categoryService
          .getList({ offset: 0, limit: 100, parent_id: firstSubCategoryId })
          .then((res: any) => {
            const options = res?.categories?.map((option: any) => ({
              value: option.id,
              label: option.name?.ru
            }));
            setSecondSubCategoryOption(options);
          });
      }
    },
    { enabled: !!firstSubCategoryId }
  );

  //sub category 3
  useQuery(
    ['GET_CATEGORY_3', secondSubCategoryId],
    async () => {
      if (secondSubCategoryId !== '') {
        await categoryService
          .getList({ offset: 0, limit: 100, parent_id: secondSubCategoryId })
          .then((res: any) => {
            const options = res?.categories?.map((option: any) => ({
              value: option.id,
              label: option.name?.ru
            }));
            setThirdSubCategoryOption(options);
          });
      }
    },
    { enabled: !!secondSubCategoryId }
  );

  //Variation

  const variationTableColumns: ColumnDef<VariationType>[] = [
    {
      accessorKey: 'product',
      header: 'Товар',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'var_barcode',
      header: 'Штрих-коде',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'vendor_code',
      header: 'Артикул',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'party_number',
      header: 'Партийный номер',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'code',
      header: 'КОД ИКПУ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-2">
          <Button
            variant="hover"
            onClick={() => {
              setOpenUpdateVariataion(true);
              setSelectedUpdateVariationId(rowData?.row?.id);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            variant="hover"
            onClick={() => handleRemoveItem(+rowData?.row?.id)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const tableVariation = useAdvanceTable({
    data: variationFormData,
    columns: variationTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const isVariation = watch('is_variation');

  const onSubmit = (data: any) => {
    // const data = getValues();
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    function prepareCategoryData(data: any) {
      if (data.third_sub_category_id) {
        return data.third_sub_category_id;
      } else if (data.second_sub_category_id) {
        return data.second_sub_category_id;
      } else if (data.first_sub_category_id) {
        return data.first_sub_category_id;
      } else {
        return data.category_id;
      }
    }

    const selectedCategoryId = prepareCategoryData(data);

    const specificationData = characterFormData?.map(el => {
      return {
        specification_id: el?.specification_id,
        value: el?.values
      };
    });

    const variationArray = variationFormData?.map((item: any) => ({
      barcode: item?.var_barcode,
      article: item?.vendor_code,
      part_number: item?.party_number,
      ikpu: item?.code,
      variations: item?.var_data?.map((variation: any) => ({
        value: variation?.var_value,
        variation_id: variation?.variation_id
      }))
    }));

    const createdDataWithOutVariation = {
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id,
      barcode: data?.barcode,
      vendor_code: data?.vendor_code,
      part_number: data?.part_number,
      name: data?.name,
      short_name: data?.short_name === '' ? watch('name') : data?.short_name,
      custom_brand_id: data?.custom_brand_id,
      measure_unit_id: data?.measure_unit_id,
      measure_unit_kind_id: data?.measure_unit_kind_id,
      country_id: data?.country_id,
      ikpu: `${data?.ikpu}`,
      category_id: selectedCategoryId,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      package_quantity:
        data?.package_quantity !== '' ? +data?.package_quantity : 1,
      specification:
        characterFormData.length > 0 ? specificationData : undefined
    };

    const createdDataWithVariation = {
      is_variation: data?.is_variation,
      product_kind_id: data?.product_kind_id,
      name: data?.name,
      short_name: data?.short_name === '' ? watch('name') : data?.short_name,
      custom_brand_id: data?.custom_brand_id,
      measure_unit_id: data?.measure_unit_id,
      measure_unit_kind_id: data?.measure_unit_kind_id,
      country_id: data?.country_id,
      category_id: selectedCategoryId,
      is_moderated: true,
      synonyms: data?.synonyms,
      description: data?.description,
      is_18plus: data?.is_18plus,
      variation: variationArray,
      specification:
        characterFormData.length > 0 ? specificationData : undefined
    };

    nomenclatureService
      .createNomenclature(
        !isVariation ? createdDataWithOutVariation : createdDataWithVariation
      )
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Номенклатура успешно добавлен!',
              type: 'success'
            })
          );

          productSearchService
            .getList({
              offset: 0,
              limit: 10,
              barcode: data?.barcode,
              is_global: true
            })
            .then((res: any) => {
              const generatedNomenClatureToProduct = res?.products?.map(
                (items: any) => {
                  return {
                    name:
                      res?.is_product === true
                        ? items?.name
                        : items?.nomenclature?.name,
                    variation: items?.nomenclature?.is_variation ? 'ДА' : 'НЕТ',
                    barcode_user:
                      res?.is_product === true
                        ? items?.barcode
                        : items?.nomenclature?.barcode,
                    barcode_original: items?.nomenclature?.barcode_original,
                    weght_code: items?.scale_code ?? 0,
                    units: items?.nomenclature?.measure_unit?.name?.ru,
                    measure_unit_kind_id:
                      items?.nomenclature?.measure_unit?.measure_unit_kind?.id,
                    quantity: items?.quantity ? items?.quantity : 0,
                    amount: items?.nomenclature?.package_quantity ?? 0,
                    update_price: items?.update_price ?? false,
                    package_amount: items?.price_in ?? 0,
                    cost: items?.price_whosale ?? 0,
                    all_cost: items?.price ?? 0,
                    current_balance: items?.total_quantity ?? 0,
                    package_quantity:
                      data?.package_quantity !== ''
                        ? +data?.package_quantity
                        : 1,
                    guid: items?.nomenclature?.id
                  };
                }
              );

              setProductInvoiceData([
                generatedNomenClatureToProduct?.[0],
                ...productInvoiceData
              ]);
              if (isAutoTab) {
                setTimeout(() => {
                  const firstProductIndex = 0;
                  focusRef(`quantity_${firstProductIndex}`);
                }, 500);
              }
            });
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setModalCreate(false);
        setLoading(false);
      });
  };

  //Generate barcode
  const handleGenerate = () => {
    setLoading(true);
    genBarcodeService
      .getGenBarcode({ offset: 0, limit: 100 })
      .then((res: any) => {
        setValue('barcode', res?.barcode);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [inputRef.current]);

  useEffect(() => {
    const defaultCurrency = measureUnitOption.find(
      (option: any) => option.value === '0830abaa-87e7-49cc-a3cf-f8a49834e34d' // shtuka
    );
    const defaultProduct = productKindOption.find(
      (option: any) => option.value === '8c74214c-869f-42f9-90a9-b1e3811d4ace' // tovar
    );
    if (defaultCurrency) {
      setValue('measure_unit_id', defaultCurrency.value);
      setValue('measure_unit_kind_id', defaultCurrency.measure_unit_kind_id);
    }

    if (defaultProduct) {
      setValue('product_kind_id', defaultProduct.value);
    }
  }, [measureUnitOption]);

  return (
    <div>
      <Form className="bg-white rounded" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          <Col>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_variation"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label="Вариативный товар" />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        {!isVariation && (
          <Row className="mb-2">
            <Col style={{ display: 'flex', gap: '20px' }}>
              <Form.Floating className="md-4 flex-1">
                <Form.Control
                  defaultValue={searchedValue}
                  {...register(`barcode`)}
                  type="text"
                  placeholder={`Штрих-код`}
                  onFocus={e => e.target.select()}
                  onKeyPress={e => {
                    const charCode = e.charCode;
                    const char = String.fromCharCode(charCode);
                    const regex = /^[a-zA-Z0-9]*$/;
                    if (!regex.test(char)) {
                      e.preventDefault();
                    }
                  }}
                />
                <label htmlFor="floatingInputCustom">Штрих-код</label>
              </Form.Floating>

              <Button variant="primary" onClick={() => handleGenerate()}>
                Сгенерировать
              </Button>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...field}
                    ref={inputRef}
                    type="text"
                    onBlur={e => {
                      field.onBlur();
                      field.onChange(e.target.value.trim());
                    }}
                    placeholder={`Полный наименование товара`}
                    onFocus={e => e.target.select()}
                    isInvalid={!!errors.name}
                    autoComplete="off"
                  />
                  <label htmlFor="floatingInputCustom">
                    Полный наименование товара{' '}
                    <span className="text-danger">*</span>
                  </label>
                  {errors?.name && (
                    <Form.Control.Feedback type="invalid">
                      Обязательное поле
                    </Form.Control.Feedback>
                  )}
                </Form.Floating>
              )}
            />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                defaultValue={watch('name')}
                {...register(`short_name`)}
                type="text"
                placeholder={`Короткий наименование товара`}
                onFocus={e => e.target.select()}
                autoComplete="off"
              />
              <label htmlFor="floatingInputCustom">
                Короткий наименование товара
              </label>
            </Form.Floating>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="d-flex gap-2">
              <div className="react-select-container flex-1">
                <Controller
                  name="measure_unit_id"
                  control={control}
                  rules={{
                    required: true
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating
                      onChange={(e: any) => {
                        const selectedOption = measureUnitOption.find(
                          (option: any) => option.value === e.target.value
                        );

                        if (selectedOption) {
                          setSelectedMeasureUnitKindId(
                            selectedOption?.measure_unit_kind_id
                          );
                          setValue('package_quantity', '1');
                          setValue(
                            'measure_unit_kind_id',
                            selectedOption?.measure_unit_kind_id
                          );
                        }
                        field.onChange(e);
                      }}
                    >
                      <Form.Select
                        {...field}
                        isInvalid={!!errors.measure_unit_id}
                      >
                        <option className="d-none" value=""></option>
                        {measureUnitOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">
                        Единица измерения <span className="text-danger">*</span>
                      </label>
                      {errors?.measure_unit_id && (
                        <span
                          style={{
                            marginTop: '2px',
                            color: 'red',
                            fontSize: '12px'
                          }}
                        >
                          Обязательное поле
                        </span>
                      )}
                    </Form.Floating>
                  )}
                />
              </div>

              {selectedMeasureUnitKindId ===
                '3c7cee55-ad41-4ee3-a409-a7bb5f428b36' && (
                <Form.Floating className="md-4">
                  <Form.Control
                    {...register(`package_quantity`)}
                    type="number"
                    placeholder={`Количество упаковки`}
                    onFocus={e => e.target.select()}
                  />
                  <label htmlFor="floatingInputCustom">
                    Количество упаковки
                  </label>
                </Form.Floating>
              )}
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="product_kind_id"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.product_kind_id}
                    >
                      <option className="d-none" value=""></option>
                      {productKindOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      Тип товара <span className="text-danger">*</span>
                    </label>
                    {errors?.product_kind_id && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        Обязательное поле
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Floating className="md-4">
                <Form.Control
                  {...register(`vendor_code`)}
                  type="text"
                  placeholder={`Артикул`}
                  onFocus={e => e.target.select()}
                />
                <label htmlFor="floatingInputCustom">Артикул</label>
              </Form.Floating>
            </Col>
          </Row>
        )}
        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Floating className="md-4">
                <Form.Control
                  {...register(`part_number`)}
                  type="text"
                  placeholder={`Партийный номер`}
                  onFocus={e => e.target.select()}
                />
                <label htmlFor="floatingInputCustom">Партийный номер</label>
              </Form.Floating>
            </Col>
          </Row>
        )}

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="custom_brand_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {brandOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Бренды</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="country_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field}>
                      <option className="d-none" value=""></option>
                      {countryOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Страна</label>
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        {!isVariation && (
          <Row className="mb-2">
            <Col>
              <Form.Floating className="md-4">
                <Form.Control
                  {...register(`ikpu`)}
                  type="number"
                  placeholder={`Код ИКПУ`}
                  onFocus={e => e.target.select()}
                />
                <label htmlFor="floatingInputCustom">Код ИКПУ</label>
              </Form.Floating>
            </Col>
          </Row>
        )}
        <Row className="mb-2">
          <Col>
            <div className="react-select-container">
              <Controller
                name="category_id"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      isInvalid={!!errors.category_id}
                      onChange={(e: any) => {
                        const selectedOption = categoryOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setParentCategoryId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {categoryOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">
                      Категория <span className="text-danger">*</span>
                    </label>
                    {errors?.category_id && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        Обязательное поле
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>
        {parentCategoryId !== '' && firstSubCategoryOption?.length > 0 && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="first_sub_category_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select
                        {...field}
                        onChange={(e: any) => {
                          const selectedOption = firstSubCategoryOption.find(
                            (option: any) => option.value === e.target.value
                          );
                          if (selectedOption) {
                            setFirstSubCategoryId(selectedOption?.value);
                          }
                          field.onChange(e);
                        }}
                      >
                        <option className="d-none" value=""></option>
                        {firstSubCategoryOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">Подкатегория</label>
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        {firstSubCategoryId !== '' && secondSubCategoryOption?.length > 0 && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="second_sub_category_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select
                        {...field}
                        onChange={(e: any) => {
                          const selectedOption = secondSubCategoryOption.find(
                            (option: any) => option.value === e.target.value
                          );
                          if (selectedOption) {
                            setSecondSubCategoryId(selectedOption?.value);
                          }
                          field.onChange(e);
                        }}
                      >
                        <option className="d-none" value=""></option>
                        {secondSubCategoryOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">Подкатегория</label>
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        {secondSubCategoryId !== '' && thirdSubCategoryOption?.length > 0 && (
          <Row className="mb-2">
            <Col>
              <div className="react-select-container">
                <Controller
                  name="third_sub_category_id"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Floating>
                      <Form.Select {...field}>
                        <option className="d-none" value=""></option>
                        {thirdSubCategoryOption?.map((option: any) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>

                      <label htmlFor="floatingInputCustom">Подкатегория</label>
                    </Form.Floating>
                  )}
                />
              </div>
            </Col>
          </Row>
        )}
        {isVariation && (
          <Row className="mb-2">
            <Col className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="button"
                onClick={() => setOpenVariation(true)}
              >
                Добавить Вариацию
              </Button>
            </Col>
          </Row>
        )}
        {isVariation && (
          <AdvanceTableProvider {...tableVariation}>
            <div className="px-4 px-lg-6 mb-4 border-top border-bottom border-300 position-relative">
              <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
              <AdvanceTableFooter pagination />
            </div>
          </AdvanceTableProvider>
        )}
        <Row className="mb-2">
          <Col className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="button"
              onClick={() => setOpenCharacter(true)}
            >
              Добавить Характеристика
            </Button>
          </Col>
        </Row>

        <SpecificationInfo
          setOpenCharacter={setOpenCharacter}
          openCharacter={openCharacter}
          characterFormData={characterFormData}
          setCharacterFormData={setCharacterFormData}
        />

        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register(`synonyms`)}
                type="text"
                placeholder={`Синонимы `}
              />
              <label htmlFor="floatingInputCustom">Синонимы</label>
            </Form.Floating>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Floating className="md-4">
              <Form.Control
                {...register('description')}
                as="textarea"
                rows={3}
                placeholder="Описания"
              />
              <label htmlFor="floatingInputCustom">Описания</label>
            </Form.Floating>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group className="d-flex align-items-center">
              <Controller
                name="is_18plus"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchControl field={field} label="18+ товар" />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="w-100"
          variant="primary"
          type="submit"
          // onClick={() => onSubmit()}
          loading={loading}
        >
          Добавить
        </Button>

        <Modal
          show={openVariation}
          onHide={() => setOpenVariation(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton className="border-top border-500">
            <Modal.Title id="contained-modal-title-vcenter">
              Вариация
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="border-bottom border-500">
            <VariationInfo
              setOpenVariation={setOpenVariation}
              variationFormData={variationFormData}
              setVariationFormData={setVariationFormData}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={openUpdateVariation}
          onHide={() => {
            setOpenUpdateVariataion(false);
            setSelectedUpdateVariationId('');
          }}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
          className="modal-char"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Вариация
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UpdataNonVariationTable
              setOpenUpdateVariataion={setOpenUpdateVariataion}
              variationFormData={variationFormData}
              selectedUpdateVariationId={selectedUpdateVariationId}
              setVariationFormData={setVariationFormData}
            />
          </Modal.Body>
        </Modal>
      </Form>
    </div>
  );
};

export default Nomenclature;

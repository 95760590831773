/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler
} from 'react-hook-form';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';

import styles from './styles.module.scss';
import legalEntityService from 'service/legalEntity';
import Button from 'components/base/Button';
import AutoOrderCategory from './AutoOrderCategory';
import analyticAutoOrderService from 'service/analyticAutoOrder';

interface AutoOrderFilterProps {
  setTableData?: any;
}
const AutoOrderFilter = ({ setTableData }: AutoOrderFilterProps) => {
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategoryData, setSelectedCategoryData] = useState<any>([]);
  const [legalEntityOption, setLegalEntityOption] = useState<any>([]);

  //GET LEgal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getLegalEntityOption({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    console.log('data', data);
    analyticAutoOrderService
      .getList({
        offset: 0,
        limit: 10,
        supplier_id: data?.provider,
        barcode: data?.barcode,
        category_id: selectedCategoryData?.id,
        seasonality_factor: +data?.coef,
        from_date_range: +data?.last_sale_period,
        to_date_range: +data?.balance_regulator
      })
      .then(res => {
        if (res) {
          setTableData(res);
        }
      });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles.orderRows}>
        <Form.Group>
          <div className="position-relative">
            <Controller
              name="provider"
              control={control}
              rules={{
                required: true
              }}
              defaultValue=""
              render={({ field }) => (
                <Form.Floating>
                  <Form.Select {...field} isInvalid={!!errors.provider}>
                    <option className="d-none" value=""></option>
                    {legalEntityOption?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>

                  <label htmlFor="floatingInputCustom">Поставщик</label>
                  {watch('provider') !== '' && (
                    <Button
                      variant="link"
                      className="p-0"
                      style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        top: '32%',
                        right: '25%'
                      }}
                      onClick={() => {
                        setValue('provider', '');
                      }}
                    >
                      <FeatherIcon icon="delete" size={16} />
                    </Button>
                  )}
                  {errors?.provider && (
                    <span
                      style={{
                        marginTop: '2px',
                        color: 'red',
                        fontSize: '12px'
                      }}
                    >
                      Обязательное поле
                    </span>
                  )}
                </Form.Floating>
              )}
            />
          </div>
        </Form.Group>

        <Controller
          name="last_sale_period"
          control={control}
          rules={{
            required: true
          }}
          defaultValue=""
          render={({ field }) => (
            <Form.Floating>
              <Form.Control
                {...field}
                type="text"
                placeholder="Период последних продаж"
                onFocus={e => e.target.select()}
                isInvalid={!!errors.last_sale_period}
              />
              <label htmlFor="floatingInputCustom">
                Период последних продаж
              </label>
              {errors?.last_sale_period && (
                <Form.Control.Feedback type="invalid">
                  Обязательное поле
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          )}
        />

        <Controller
          name="balance_regulator"
          control={control}
          rules={{
            required: true
          }}
          defaultValue=""
          render={({ field }) => (
            <Form.Floating>
              <Form.Control
                {...field}
                type="text"
                placeholder="Нормативный остаток"
                onFocus={e => e.target.select()}
                isInvalid={!!errors.balance_regulator}
              />
              <label htmlFor="floatingInputCustom">Нормативный остаток</label>
              {errors?.balance_regulator && (
                <Form.Control.Feedback type="invalid">
                  Обязательное поле
                </Form.Control.Feedback>
              )}
            </Form.Floating>
          )}
        />

        <Controller
          name="coef"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Form.Floating>
              <Form.Control
                {...field}
                type="text"
                placeholder="Коэф. Сезонности"
                onFocus={e => e.target.select()}
              />
              <label htmlFor="floatingInputCustom">Коэф. Сезонности</label>
            </Form.Floating>
          )}
        />

        <Form.Floating>
          <Form.Control
            {...register('barcode')}
            type="text"
            placeholder="Выведите или отсканируйте штрих-код"
            onFocus={e => e.target.select()}
          />
          <label htmlFor="floatingInputCustom">
            Выведите или отсканируйте штрих-код
          </label>
        </Form.Floating>
        <Form.Group
          className={styles.categoryBox}
          onClick={() => setOpenCategoryModal(true)}
        >
          <span className={styles.title}>
            {selectedCategoryData?.length !== 0
              ? `Категория - ${selectedCategoryData?.label}`
              : 'Категория'}
          </span>
          <FeatherIcon icon="chevron-right" size={16} />
          {selectedCategoryData?.length !== 0 && (
            <Button
              variant="link"
              className="p-0"
              style={{
                position: 'absolute',
                cursor: 'pointer',
                top: '32%',
                right: '18%'
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                setSelectedCategoryData([]);
              }}
            >
              <FeatherIcon icon="delete" size={16} />
            </Button>
          )}
        </Form.Group>

        <Button style={{ height: '48px' }} variant="primary" type="submit">
          Применить
        </Button>

        <Offcanvas
          show={openCategoryModal}
          onHide={() => {
            setOpenCategoryModal(false);
          }}
          placement="end"
          backdrop="static"
          keyboard={false}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
              Категория
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <AutoOrderCategory
              setOpenCategoryModal={setOpenCategoryModal}
              setSelectedCategoryData={setSelectedCategoryData}
            />
          </Offcanvas.Body>
        </Offcanvas>
      </Form>
    </>
  );
};

export default AutoOrderFilter;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const invoiceTypeService = {
  getList: (params: any) => request.get('/guidebook/invoice-type', { params }),
  getInvoiceTypeById: (id: any) => request.get(`/guidebook/invoice-type/${id}`),
  createInvoiceType: (data: any) =>
    request.post('/guidebook/invoice-type', data),
  deleteInvoiceType: (id: any) =>
    request.delete(`/guidebook/invoice-type/${id}`),
  updateInvoiceType: (id: any, data: any) =>
    request.put(`/guidebook/invoice-type/${id}`, data)
};

export default invoiceTypeService;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Filter from './Filter';
import SettelementCutomerTable from './SettelementCutomerTable';

export const customersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/provider'
  },
  {
    label: 'Взаиморасчет с заказчиком',
    active: true
  }
];

const SettelementWithCustomers = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [customersData, setCustomersData] = useState([]);

  return (
    <div>
      <PageBreadcrumb items={customersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Взаиморасчет с заказчиком</h2>

        <Filter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setCustomersData={setCustomersData}
        />
        <SettelementCutomerTable customersData={customersData} />
      </div>
    </div>
  );
};

export default SettelementWithCustomers;

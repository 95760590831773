/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const orderItemService = {
  getList: (params: any) => request.get('/order-item', { params }),
  getOrderItemById: (id: any) => request.get(`/order-item/${id}`),
  getOrderItemInById: (id: any, params: any) =>
    request.get(`/order-item/in/${id}`, { params }),
  getOrderItemOutById: (id: any, params: any) =>
    request.get(`/order-item/out/${id}`, { params }),
  getByLegalEntityId: (id: any, params: any) =>
    request.get(`/order-item/product/${id}`, { params }),
  createOrderItem: (data: any) => request.post('/order-item', data),
  deleteOrderItem: (id: any) => request.delete(`/order-item/${id}`),
  updateOrderItem: (id: any, data: any) =>
    request.put(`/order-item/${id}`, data),
  updateOrderItemOut: (id: any, data: any) =>
    request.put(`/order-item/out/${id}`, data),
  updateOrderItemIn: (id: any, data: any) =>
    request.put(`/order-item/in/${id}`, data)
};

export default orderItemService;

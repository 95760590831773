/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const warehouseService = {
  getList: (params: any) => request.get('/legal-entity-warehouse', { params }),
  getWarehouseById: (id: any) => request.get(`/legal-entity-warehouse/${id}`),
  createWarehouse: (data: any) => request.post('/legal-entity-warehouse', data),
  deleteWarehouse: (id: any) => request.delete(`/legal-entity-warehouse/${id}`),
  updateWarehouse: (id: any, data: any) =>
    request.put(`/legal-entity-warehouse/${id}`, data)
};

export default warehouseService;

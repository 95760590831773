/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Flatpickr from 'react-flatpickr';
import classNames from 'classnames';
import { useState } from 'react';
import { useQuery } from 'react-query';
import FeatherIcon from 'feather-icons-react';

import warehouseService from 'service/warehouse';
import Button from 'components/base/Button';
import usersService from 'service/users';

interface InfoProps {
  dateInventory?: any;
  setDateInventory?: any;
  setOptionWarehouseId?: any;
  setOptionStatusId?: any;
  setInventoryValue?: any;
  inventoryValue?: any;
}

const InfoFilter = ({
  dateInventory,
  setDateInventory,
  setOptionWarehouseId,
  setOptionStatusId,
  setInventoryValue,
  inventoryValue
}: InfoProps) => {
  const { control, watch, setValue, getValues } = useForm();

  console.log('data', getValues());

  const [warehouseOption, setWarehouseOption] = useState<any>([]);
  const [usersOption, setUsersOption] = useState<any>([]);

  //GET Warehouse to options
  useQuery(['GET_WAREHOUSE'], async () => {
    await warehouseService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.ware_houses?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setWarehouseOption(options);
      });
  });

  //GET Users to options
  useQuery(['GET_USERS'], async () => {
    await usersService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.data?.users?.map((option: any) => ({
        value: option.id,
        label: `${option.first_name}  ${option?.last_name}`
      }));
      setUsersOption(options);
    });
  });

  const handleStartDateChange = (date: Date | Date[]) => {
    if (Array.isArray(date)) {
      const startDateInMillis = date[0]?.getTime();
      setDateInventory(startDateInMillis);
    } else {
      const startDateInMillis = date?.getTime();
      setDateInventory(startDateInMillis);
    }
  };

  return (
    <div>
      <Form noValidate>
        <div className="d-flex gap-3">
          <Form.Group>
            <div className="react-select-container ">
              <Controller
                name="stock"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = warehouseOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setOptionWarehouseId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {warehouseOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Склад</label>
                    {watch('stock') !== '' && (
                      <Button
                        variant="link"
                        style={{
                          position: 'absolute',
                          right: '5%',
                          top: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOptionWarehouseId('');
                          setValue('stock', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>

          <Form.Floating>
            <Form.Control
              value={inventoryValue}
              onChange={e => setInventoryValue(e.target.value)}
              type="number"
              placeholder="Номер инвентарь"
              onFocus={e => e.target.select()}
            />
            <label htmlFor="floatingInputCustom">Номер инвентарь</label>
          </Form.Floating>

          <Form.Group className="md-4">
            <Form.Floating>
              <Flatpickr
                className={classNames('form-control')}
                options={{
                  nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                  prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                  locale: {
                    firstDayOfWeek: 1
                  },
                  monthSelectorType: 'static',
                  onDayCreate: (...[, , , dayElem]) => {
                    if (
                      dayElem.dateObj.getDay() === 5 ||
                      dayElem.dateObj.getDay() === 6
                    ) {
                      dayElem.className += ' weekend-days';
                    }
                  },
                  dateFormat: 'M j, Y',
                  disableMobile: true
                }}
                value={dateInventory}
                onChange={handleStartDateChange}
                placeholder="ДАТА НАКЛАДНОЙ"
              />
              <label htmlFor="floatingInputCustom">ДАТА НАКЛАДНОЙ</label>
            </Form.Floating>
          </Form.Group>

          <Form.Group>
            <div className="react-select-container position-relative">
              <Controller
                name="responsible"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating className="users">
                    <Form.Select
                      {...field}
                      onChange={(e: any) => {
                        const selectedOption = usersOption.find(
                          (option: any) => option.value === e.target.value
                        );
                        if (selectedOption) {
                          setOptionStatusId(selectedOption?.value);
                        }
                        field.onChange(e);
                      }}
                    >
                      <option className="d-none" value=""></option>
                      {usersOption?.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                    <label htmlFor="floatingInputCustom">
                      Материально-ответственного лицо
                    </label>
                    {watch('responsible') !== '' && (
                      <Button
                        variant="link"
                        style={{
                          position: 'absolute',
                          right: '5%',
                          top: '15%',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setOptionStatusId('');
                          setValue('responsible', '');
                        }}
                      >
                        <FeatherIcon icon="delete" size={16} />
                      </Button>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Form.Group>
        </div>
      </Form>
    </div>
  );
};

export default InfoFilter;

/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const cashService = {
  getList: (params: any) => request.get('/legal-entity-cash', { params }),
  getCashById: (id: any) => request.get(`/legal-entity-cash/${id}`),
  createCash: (data: any) => request.post('/legal-entity-cash', data),
  deleteCash: (id: any) => request.delete(`/legal-entity-cash/${id}`),
  updateCash: (id: any, data: any) =>
    request.put(`/legal-entity-cash/${id}`, data)
};

export default cashService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import inventoryItemService from 'service/inventoryItem';
import Button from 'components/base/Button';
import WireOffNotInLists from './WireOffNotInLists';
import SupperModal from 'components/modals/SupperModal';
import { showAlert } from 'store/alert/alert.thunk';
import inventoryService from 'service/inventory';

export type CreateInventoryData = {
  product_name: string;
  barcode: string;
  units: string;
  currency: string;
  cost: string;
  accounts_number: string;
  quantity_fact: string;
  shortage: string;
  surplus: string;
  deficiency_amount: string;
  surplus_amount: string;
  status: string;
  guid: string;
};

const WriteOffInventoryResultTable = () => {
  const { pathname } = useLocation();
  const info_id = pathname.split('/').pop();

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [openNotInList, setOpenNotInList] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInventoryId, setSelectedInventoryId] = useState<string | null>(
    null
  );

  const [openFinishSupperModal, setOpenFinishSupperModal] = useState(false);
  const [confirmToFinish, setConfirmToFinish] = useState(false);

  const { data, refetch } = useQuery(
    ['GET_INVENTORY_ITEM'],
    () => {
      if (info_id !== '') {
        const res = inventoryItemService
          .getInventoryItemById(info_id, {
            offset: 0,
            limit: 10,
            search: searchValue,
            write_off: true
          })
          .then((res: any) => res?.inventory_items);

        return res;
      }
    },
    {
      enabled: !!info_id
    }
  );

  const inventoryItemData: CreateInventoryData[] = useMemo(() => {
    const res =
      data?.map((items: any) => {
        return {
          product_name: items?.product?.name,
          barcode: items?.product?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          cost: items?.price_in,
          accounts_number: items?.quantity ?? 0,
          quantity_fact: items?.quantity_actual ?? 0,
          shortage: items?.shortage ?? 0,

          surplus: items?.excess ?? 0,
          deficiency_amount: items?.shortage ?? 0,
          surplus_amount: items?.excess_total ?? 0,
          status: items?.status?.name?.ru,
          guid: items?.id,
          product_id: items?.product_id
        };
      }) ?? [];

    return res;
  }, [data, info_id]);

  const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Списание',
      url: '/write-off/write-off-inventory'
    },
    {
      label: 'По итог инвентаризацие',
      url: '/write-off/write-off-inventory'
    }
  ];

  const handleDelete = (id: any) => {
    setSelectedInventoryId(id);
    setOpenSupperModal(true);
  };

  const createInventoryDataTableColumns: ColumnDef<CreateInventoryData>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Button
            variant="hover"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },

    {
      accessorKey: 'product_name',
      header: 'Наименование товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'units',
      header: 'Единица измерения',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'cost',
      header: 'Цена',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'accounts_number',
      header: 'Кол-во учёт',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'quantity_fact',
      header: 'Кол-во факт',

      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'shortage',
      header: 'Недостача',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus',
      header: 'Излишка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'deficiency_amount',
      header: 'Сумма недостатка',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'surplus_amount',
      header: 'Сумма излишки',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: 'Состояние',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: inventoryItemData,
    columns: createInventoryDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleFinish = () => {
    if (inventoryItemData?.length === 0) {
      dispatch(
        showAlert({
          title: 'Товар еще не добавлен'
        })
      );
      setConfirmToFinish(false);
    }
    /* prettier-ignore */
    if (info_id && inventoryItemData?.length > 0) {
      inventoryService.getInventoryById(info_id).then((res: any) => {
        if (res) {
          const today = new Date();
          /* prettier-ignore */
          inventoryService
            .updateInventory(info_id, {
              ...res,
              status_id: 'af0fe0e3-3357-4b29-a33f-138c89cb75db',
              date_close: today.getTime(),
              write_off: true
            })
            .then((response: any) => {
              if (response) {
                dispatch(
                  showAlert({
                    title: 'Списания инвентаризации успешно завершено!',
                    type: 'success'
                  })
                );
                navigate('/write-off/write-off-inventory');
              }
            });
        }
      });
    }
  };

  useEffect(() => {
    if (searchValue?.length > 2 || searchValue === '') {
      refetch();
    }
  }, [searchValue]);

  useEffect(() => {
    if (confirmToDelete && selectedInventoryId) {
      inventoryItemService
        .deleteInventoryItem(selectedInventoryId)
        .finally(() => {
          refetch();
          setConfirmToDelete(false);
          setSelectedInventoryId(null);
          setOpenSupperModal(false);
        });
    }
  }, [confirmToDelete, selectedInventoryId, refetch]);

  useEffect(() => {
    if (confirmToFinish) {
      handleFinish();
    }
  }, [confirmToFinish]);

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />

      <div className="form-icon-container w-30">
        <Form.Floating>
          <Form.Control
            type="text"
            placeholder="Выведите товар"
            onFocus={e => e.target.select()}
            onChange={e => setSearchValue(e?.target?.value)}
            className="form-icon-input"
          />
          <label
            htmlFor="floatingInputCustom"
            className="form-icon-label text-700"
          >
            Выведите товар
          </label>
        </Form.Floating>
        <FontAwesomeIcon icon={faSearch} className="text-900 fs-9 form-icon" />
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <div className="mt-3 d-flex gap-2 justify-content-end">
        <Button
          type="button"
          variant="primary"
          onClick={() => setOpenNotInList(true)}
        >
          Не добавленные в список
        </Button>
        <Button
          variant="secondary"
          type="button"
          style={{ height: '48px' }}
          onClick={() => setOpenFinishSupperModal(true)}
        >
          Завершить
        </Button>
      </div>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Добавленный товар"
        bodyText="Вы действительно хотите удалить добавленный товара?"
      />

      <SupperModal
        show={openFinishSupperModal}
        setShow={setOpenFinishSupperModal}
        setConfirm={setConfirmToFinish}
        title="Завершить инвертатизацио"
        bodyText="Вы действительно хотите Завершить инвертатизацио?"
      />

      <Modal
        show={openNotInList}
        onHide={() => {
          setOpenNotInList(false);
          refetch();
        }}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Добавить в список путем аннулирования не инвентаризированные товары
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WireOffNotInLists info_id={info_id} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default WriteOffInventoryResultTable;

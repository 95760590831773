/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const returnOutService = {
  getList: (params: any) => request.get('/out-return', { params }),
  getReturnOutById: (id: any) => request.get(`/out-return/${id}`),
  createReturnOut: (data: any) => request.post('/out-return', data),
  deleteReturnOut: (id: any) => request.delete(`/out-return/${id}`),
  updateReturnOut: (id: any, data: any) =>
    request.put(`/out-return/${id}`, data)
};

export default returnOutService;

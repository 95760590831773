import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import InfoTable from './InfoTable';
import InfoFilter from './InfoFilter';

export const infoInventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Инвентаризация',
    url: '/inventory/create'
  },
  {
    label: 'Информация об инвентаризации',
    active: true
  }
];

const InfoInventory = () => {
  const [dateInventory, setDateInventory] = useState(null);
  const [inventoryValue, setInventoryValue] = useState('');
  const [optionWarehouseId, setOptionWarehouseId] = useState('');
  const [optionStatusId, setOptionStatusId] = useState('');

  return (
    <div>
      <PageBreadcrumb items={infoInventoryBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Информация об инвентаризации</h2>

        <InfoFilter
          dateInventory={dateInventory}
          setDateInventory={setDateInventory}
          setOptionWarehouseId={setOptionWarehouseId}
          setOptionStatusId={setOptionStatusId}
          setInventoryValue={setInventoryValue}
          inventoryValue={inventoryValue}
        />
        <InfoTable
          dateInventory={dateInventory}
          inventoryValue={inventoryValue}
          optionWarehouseId={optionWarehouseId}
          optionStatusId={optionStatusId}
        />
      </div>
    </div>
  );
};

export default InfoInventory;

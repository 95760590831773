import AuthSimpleLayout from 'layouts/AuthSimpleLayout';
import RegisterForm from './RegisterForm';

const Register = () => {
  return (
    <AuthSimpleLayout>
      <RegisterForm />
    </AuthSimpleLayout>
  );
};

export default Register;

const QrIcon = ({
  className,
  onClick,
  fill,
  width,
  height
}: {
  className?: string;
  onClick?: () => void;
  fill?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <mask
        id="mask0_11125_7114"
        //   style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_11125_7114)">
        <path
          d="M1 21V16H3V19H6V21H1ZM18 21V19H21V16H23V21H18ZM4 18V6H6V18H4ZM7 18V6H8V18H7ZM10 18V6H12V18H10ZM13 18V6H16V18H13ZM17 18V6H18V18H17ZM19 18V6H20V18H19ZM1 8V3H6V5H3V8H1ZM21 8V5H18V3H23V8H21Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default QrIcon;

import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { outgoingBreadcrumbItems } from 'data/movement';
import OutgoingFilterMovements from './filter/OutgoingFilterMovements';
import OutgoingMovementsTable from './OutgoingMovementsTable';

const OutgoingMovements = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [optionSenderId, setOptionSenderId] = useState('');
  const [optionStatusId, setOptionStatusId] = useState('');
  return (
    <div>
      <PageBreadcrumb items={outgoingBreadcrumbItems} />

      <div>
        <h2 className="mb-5">Исходящий</h2>

        <OutgoingFilterMovements
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setOptionSenderId={setOptionSenderId}
          setOptionStatusId={setOptionStatusId}
        />
        <OutgoingMovementsTable
          dateFrom={dateFrom}
          dateTo={dateTo}
          optionSenderId={optionSenderId}
          optionStatusId={optionStatusId}
        />

        {/* <div className="footer-mov-out">
          <div className="box">
            <p className="mb-0 text-bold">Итого на сумму</p>
            <span>0.0</span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OutgoingMovements;

export function isValidGtin(code: string): boolean {
  console.log('code', code);

  // Check if the code is a number between 1 and 10000 inclusive
  const numericCode = parseInt(code, 10);
  if (numericCode >= 1 && numericCode <= 10000) {
    return true;
  }

  if (code.startsWith('22') && code.length === 8) {
    return false;
  }

  if (!code.trim()) {
    return false;
  }

  if (![8, 12, 13, 14].includes(code.length)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < code.length - 1 /* do not include check char */; i++) {
    if (!/[0-9]/.test(code[i])) {
      return false;
    }

    const cchari = parseInt(code[i], 10);
    // even (from the right) characters get multiplied by 3
    // add the length to align right
    if ((code.length + i) % 2 === 0) {
      sum += cchari * 3;
    } else {
      sum += cchari;
    }
  }

  // validate check char
  const checkChar = code[code.length - 1];
  if (!/[0-9]/.test(checkChar)) {
    return false;
  }

  const checkChari = parseInt(checkChar, 10);
  return checkChari === (10 - (sum % 10)) % 10;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Row, Col } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { NewApplicationsData } from 'data/newapplications';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import orderOutService from 'service/orderOut';
import orderService from 'service/order';
import Button from 'components/base/Button';
import SupperModal from 'components/modals/SupperModal';

interface NewApplicationDataTableProps {
  setOpenFolder: (show: boolean) => void;
}

const NewApplicationDataTable = ({
  setOpenFolder
}: NewApplicationDataTableProps) => {
  const navigate = useNavigate();
  const legalEntityName = useSelector(
    (state: any) => state?.auth?.legal_entity?.name
  );

  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(
    null
  );

  const { data, refetch } = useQuery(['GET_ORDER_OUT'], () => {
    const res = orderOutService
      .getList({
        offset: 0,
        limit: 100,
        status_id: '1a22cd85-070e-4779-afcb-8f0be603b60a' // new status id
      })
      .then((res: any) => {
        return res?.orders;
      });
    return res;
  });

  const tableData: NewApplicationsData[] = useMemo(() => {
    const resultData =
      data?.map((items: any) => {
        return {
          orders: items?.order_number,
          sender: items?.legal_entity?.name,
          recipient: legalEntityName,
          currency: items?.currency?.name?.ru,
          date_create: new Date(items?.date_create)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),
          sender_id: items?.sender_id,
          guid: items?.id
        };
      }) ?? [];

    return resultData;
  }, [data]);

  const handleDelete = (id: string) => {
    setSelectedInvoiceId(id);
    setOpenSupperModal(true);
  };

  const newApplicationsTableColumns: ColumnDef<NewApplicationsData>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <Row>
            <Col className="d-flex gap-2">
              <Button
                variant="hover"
                className="p-2"
                onClick={() => {
                  navigate(
                    `/applications/outgoing/filter/newapplications/${rowData?.row?.original?.guid}?sender_id=${rowData?.row?.original?.sender_id}`
                  );
                  setOpenFolder(false);
                }}
              >
                <FeatherIcon icon="plus" className="cursor-pointer" size={20} />
              </Button>
              <Button
                variant="hover"
                className="p-2"
                onClick={() => handleDelete(rowData?.row?.original?.guid)}
              >
                <FeatherIcon
                  icon="trash-2"
                  className="cursor-pointer text-danger"
                  size={20}
                />
              </Button>
            </Col>
          </Row>
        );
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-end' }
      }
    },
    {
      accessorKey: 'orders',
      header: '№ заказа',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'sender',
      header: 'ОТПРАВИТЕЛЬ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'recipient',
      header: 'Получатель',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'currency',
      header: 'Валюта',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'date_create',
      header: 'Дата создания ',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: tableData,
    columns: newApplicationsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (selectedInvoiceId && confirmToDelete) {
      orderService.deleteOrder(selectedInvoiceId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedInvoiceId(null);
      });
    }
  }, [confirmToDelete, selectedInvoiceId, refetch]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Выбрать возврат"
        bodyText="Вы действительно хотите удалить возврата?"
      />
    </div>
  );
};

export default NewApplicationDataTable;

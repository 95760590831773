import { useState } from 'react';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import UseFilter from './UseFilter';
import SettelementTable from './SettelementTable';

export const suppliersBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Дт Кт',
    url: '/dtkt/provider'
  },
  {
    label: 'Взаиморасчет с поставщиком',
    active: true
  }
];

const SettelementWithSuppliers = () => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [suppliersData, setSuppliersData] = useState([]);

  console.log('test');

  return (
    <div>
      <PageBreadcrumb items={suppliersBreadcrumbItems} />
      <div>
        <h2 className="mb-5">Взаиморасчет с поставщиком</h2>

        <UseFilter
          dateFrom={dateFrom}
          dateTo={dateTo}
          setDateFrom={setDateFrom}
          setDateTo={setDateTo}
          setSuppliersData={setSuppliersData}
        />
        <SettelementTable suppliersData={suppliersData} />
      </div>
    </div>
  );
};

export default SettelementWithSuppliers;

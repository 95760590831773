/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const analyticRevaluationService = {
  getList: (params: any) =>
    request.get('/analytic/revaluation-invoice', { params }),
  getInvoiceItemInById: (params: any) =>
    request.get(`/analytic/revaluation-product`, { params })
};

export default analyticRevaluationService;

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { ChangeEvent, useRef, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Button from 'components/base/Button';
import useAuth from 'service/auth/useAuth';
import { authActions } from 'store/auth/auth.slice';
import { shiftIdActions } from 'store/shiftId/shiftId.slice';

const totalInputLength = 6;

const VerifyOtpForm = () => {
  const [otp, setOtp] = useState('');
  const [timeLeft, setTimeLeft] = useState(60);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const phoneNumber = useSelector((state: any) => state?.auth?.phone_number);
  const sendOtpId = useSelector((state: any) => state?.auth?.send_otp_id);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = e.target;

    if (value) {
      [...value].slice(0, totalInputLength).forEach((char, charIndex) => {
        if (inputRefs.current && inputRefs.current[index + charIndex]) {
          inputRefs.current[index + charIndex]!.value = char;
          inputRefs.current[index + charIndex + 1]?.focus();
        }
      });
    } else {
      inputRefs.current[index]!.value = '';
      inputRefs.current[index - 1]?.focus();
    }

    const updatedOtp = inputRefs.current.reduce(
      (acc, input) => acc + (input?.value || ''),
      ''
    );
    setOtp(updatedOtp);
  };

  const { verifyOtp, sendOtp } = useAuth({
    verifyOtpProps: {
      onSuccess: (value: any) => {
        if (value?.data?.is_exist_user) {
          const states = {
            token: value.data.access_token,
            user: value.data.user,
            legal_entity: value?.data?.legal_entity
          };
          dispatch(authActions.login(states));
          dispatch(
            shiftIdActions.setShiftId(
              value.data.cashbox_id ? value.data.cashbox_id : ''
            )
          );
          navigate('/');
        } else {
          navigate('/register');
        }
      }
    },
    sendOtpProps: {
      onSuccess: (value: any) => {
        const states = {
          send_otp_id: value.data.id,
          phone_number: phoneNumber
        };
        dispatch(authActions.login(states));
        setTimeLeft(60); // Reset the timer to 3 minutes
      }
    }
  });

  const onSubmit = async () => {
    console.log('otp-->', otp);

    if (otp && sendOtpId) {
      try {
        await verifyOtp.mutateAsync({
          id: sendOtpId,
          otp: otp
        });
      } catch (error: any) {
        console.log('Verify otp error ==>', error);
      }
    }
  };

  const resendOtp = async () => {
    console.log('Resend OTP');
    try {
      await sendOtp.mutateAsync({
        phone_number: `+${phoneNumber}`
      });
    } catch (error: any) {
      console.log('Send Otp error==>', error);
    }
  };

  return (
    <div>
      <div className={classNames('pxx-xxl-5')}>
        <div className={classNames('text-center', 'mb-6')}>
          <h4 className="text-1000">Введите код подтверждения</h4>
          <p className="text-700 mb-2">
            На ваш телефон номер — +{phoneNumber} было отправлено смс,
            содержащее 6-значный код подтверждения.
          </p>
          <div className="verification-form">
            <div className="d-flex align-items-center gap-2 mb-3">
              {Array(totalInputLength)
                .fill('')
                .map((_, index) => (
                  <React.Fragment key={index}>
                    <Form.Control
                      ref={(el: HTMLInputElement) => {
                        inputRefs.current[index] = el;
                      }}
                      className="px-2 text-center"
                      type="number"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleChange(e, index)
                      }
                    />
                    {index === 2 && <span>-</span>}
                  </React.Fragment>
                ))}
            </div>
            <Button
              variant="primary"
              className="w-100 mb-2"
              type="submit"
              disabled={otp.length < totalInputLength}
              onClick={() => onSubmit()}
            >
              Проверять
            </Button>

            <div className="text-center">
              {timeLeft === 0 ? (
                <Button
                  variant="link"
                  className="fs-9 fw-bold"
                  onClick={resendOtp}
                >
                  Повторная отправка СМС
                </Button>
              ) : (
                <span className="fs-9 fw-bold">
                  Оставшееся время: {formatTime(timeLeft)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtpForm;

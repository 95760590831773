/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const legalEntitySignService = {
  getList: (params: any) =>
    request.get('/guidebook/legal-entity-sign', { params }),
  getSignById: (id: any) => request.get(`/guidebook/legal-entity-sign/${id}`),
  createSign: (data: any) => request.post('/guidebook/legal-entity-sign', data),
  deleteSign: (id: any) => request.delete(`/guidebook/legal-entity-sign/${id}`),
  updateSign: (id: any, data: any) =>
    request.put(`/guidebook/legal-entity-sign/${id}`, data)
};

export default legalEntitySignService;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shiftId: ''
};

export const { actions: shiftIdActions, reducer: shiftIdReducer } = createSlice(
  {
    name: 'shiftId',
    initialState,
    reducers: {
      setShiftId: (state, action) => {
        state.shiftId = action.payload;
      }
    }
  }
);

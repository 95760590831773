/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Offcanvas } from 'react-bootstrap';

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Button from 'components/base/Button';
import SelectionProduct from './modals/SelectionProduct';
import '../styles.scss';

interface FilterProps {
  refetchInventoryItem?: any;
  tableData?: any;
  setTableData?: any;
}
const Filter = ({
  refetchInventoryItem,
  tableData,
  setTableData
}: FilterProps) => {
  const [openProduct, setOpenProduct] = useState(false);

  return (
    <>
      <Form.Group className={'d-flex justify-content-between'}>
        <h2>Новый</h2>
        <Button
          style={{ height: '48px' }}
          variant="primary"
          onClick={() => setOpenProduct(true)}
        >
          <FontAwesomeIcon icon={faPlus} className="me-2" />
          Добавить
        </Button>
      </Form.Group>

      <Offcanvas
        show={openProduct}
        onHide={() => {
          setOpenProduct(false);
          refetchInventoryItem();
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Выбор товара
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SelectionProduct
            setOpenProduct={setOpenProduct}
            refetchInventoryItem={refetchInventoryItem}
            tableData={tableData}
            setTableData={setTableData}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Filter;

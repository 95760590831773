/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const accessesService = {
  getList: (params: any) =>
    request.get('/guidebook/legal-entity-access', { params }),
  getAccessesById: (id: any) =>
    request.get(`/guidebook/legal-entity-access/${id}`),
  createAccesses: (data: any) =>
    request.post('/guidebook/legal-entity-access', data),
  deleteAccesses: (id: any) =>
    request.delete(`/guidebook/legal-entity-access/${id}`),
  updateAccesses: (id: any, data: any) =>
    request.put(`/guidebook/legal-entity-access/${id}`, data)
};

export default accessesService;

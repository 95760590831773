/* eslint-disable @typescript-eslint/no-explicit-any */
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Offcanvas, Modal } from 'react-bootstrap';
import { useState, useMemo, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import CreateParentProduct from './CreateParentProduct';
import productGroupService from 'service/productGroup';
import SupperModal from 'components/modals/SupperModal';
import QrIcon from 'pages/bonuses/QrIcon';
import ImportedDataTable from './ImportedDataTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableCPagination from 'components/base/AdvanceTableCPagination';
import { pageToOffset } from 'utils/pageToOffset';

export type ProductsType = {
  name: string;
  barcode: string;
};

export const productsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Группировка товаров',
    active: true
  }
];

const Products = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [openModal, setOpenModal] = useState(false);
  const [openSupperModal, setOpenSupperModal] = useState(false);
  const [confirmToDelete, setConfirmToDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [importData, setImportData] = useState<any>([]);
  const [searchInputValue, setSearchInputValue] = useState<any>('');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  //Get Product Search
  const { data, refetch, isFetching, isLoading } = useQuery(
    ['GET_GROUP_PRODUCT', currentPage, searchInputValue],
    async () => {
      const res = await productGroupService
        .getProductGroup({
          limit: 10,
          offset: pageToOffset(currentPage, 10),
          search: searchInputValue
        })
        .then((res: any) => {
          return res;
        });
      return res;
    },
    {
      enabled:
        !!currentPage || !searchInputValue || searchInputValue?.length > 2
    }
  );

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data?.count / 10));
    }
  }, [data]);

  const loading = isLoading || isFetching;

  const productsData: ProductsType[] = useMemo(() => {
    return (
      data?.group_products?.map((items: any) => {
        return {
          barcode: items?.parent_barcode,
          name: items?.parent?.name
        };
      }) ?? []
    );
  }, [data]);

  const handleDelete = (id: string) => {
    setSelectedId(id);
    setOpenSupperModal(true);
  };

  const productsDataTableColumns: ColumnDef<ProductsType>[] = [
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex gap-2">
            <Button
              variant="hover"
              className="p-1"
              onClick={() => {
                navigate(
                  `/products/products-add/${rowData?.row?.original?.barcode}`
                );
              }}
            >
              <QrIcon fill="#8200BF" width="24" height="24" />
            </Button>
            <Button
              variant="hover"
              className="p-2"
              onClick={() => handleDelete(rowData?.row?.original?.barcode)}
            >
              <FeatherIcon
                icon="trash-2"
                className="cursor-pointer text-danger"
                size={20}
              />
            </Button>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'order_number',
      header: '№',
      cell: rowData => {
        const orderNumber = rowData.row.index + 1 + (currentPage - 1) * 10;
        return <div>{orderNumber}</div>;
      },
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-900 p-2' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Названия товара',
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'barcode',
      header: 'Штрих-код',
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: productsData,
    columns: productsDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  useEffect(() => {
    if (confirmToDelete && selectedId) {
      productGroupService.deleteProductGroup(selectedId).finally(() => {
        refetch();
        setOpenSupperModal(false);
        setConfirmToDelete(false);
        setSelectedId(null);
      });
    }
  }, [confirmToDelete, selectedId, refetch]);

  const handleExportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = e => {
        if (e?.target?.result) {
          const data = new Uint8Array(e.target.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          const formattedData = (jsonData as any[][])
            .slice(1)
            .filter((row: any) =>
              row.some(
                (cell: any) =>
                  cell !== null && cell !== undefined && cell !== ''
              )
            )
            .map(([parent, child]) => ({
              parent_barcode: String(parent),
              child_barcode: String(child)
            }));

          if (formattedData?.length > 0) {
            setOpenImportModal(true);
            setImportData(formattedData);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <PageBreadcrumb items={productsBreadcrumbItems} />

      <h2 className="mb-5">Группировка товаров</h2>
      <div className="d-flex justify-content-between align-items-center">
        <SearchBox
          placeholder="Поиск товаров"
          value={searchInputValue}
          onChange={({ target }) => {
            setSearchInputValue(target.value);
          }}
        />

        <div className="d-flex gap-2">
          <Button variant="outline-primary" onClick={handleExportClick}>
            <FeatherIcon icon="download-cloud" className="me-2" size={20} />
            Импорт
          </Button>
          <Button
            style={{ height: '48px' }}
            variant="primary"
            onClick={() => setOpenModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Добавить товар
          </Button>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept=".xlsx, .xls" // Specify the file types you want to allow
          />
        </div>
      </div>

      <AdvanceTableProvider {...table}>
        <div className="mt-4 mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: 'phoenix-table fs-9' }}
            isLoading={loading}
          />
          <AdvanceTableCPagination
            count={pageCount}
            page={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </AdvanceTableProvider>

      <SupperModal
        show={openSupperModal}
        setShow={setOpenSupperModal}
        setConfirm={setConfirmToDelete}
        title="Группировка товаров"
        bodyText="Вы действительно хотите удалить добавленный товар?"
      />

      <Offcanvas
        show={openModal}
        onHide={() => {
          setOpenModal(false), refetch();
        }}
        placement="end"
        backdrop="static"
        keyboard={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
            Добавить товар
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CreateParentProduct />
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openImportModal}
        onHide={() => {
          setOpenImportModal(false), setImportData([]);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Импортированные данные
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImportedDataTable
            setOpenImportModal={setOpenImportModal}
            setImportData={setImportData}
            importData={importData}
            refetch={refetch}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Products;

/* eslint-disable @typescript-eslint/no-explicit-any */
import Barcode from 'react-barcode';
import { useState, useEffect, forwardRef } from 'react';
import moment from 'moment';

import logo from 'assets/img/icons/rizomarketplace-logo.png';
import './styles.scss';

interface PriceListFilterProps {
  activeCard?: any;
  setActiveCard?: any;
  hidePrice?: any;
  hideBarcode?: any;
}

const PriceListCards = forwardRef(
  ({
    activeCard,
    setActiveCard,
    hidePrice,
    hideBarcode
  }: PriceListFilterProps) => {
    const [formattedDate, setFormattedDate] = useState('');

    useEffect(() => {
      const updateDate = () => {
        const now = moment().format('DD.MM.YYYY HH:mm');
        setFormattedDate(now);
      };

      updateDate();
      const interval = setInterval(updateDate, 1000); // Update every second

      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    const handleCardClick = (card: any) => {
      setActiveCard(card);
    };

    return (
      <div className="main-container">
        <div
          /* prettier-ignore */
          className={`card-60-40 ${activeCard === 'card-60-40' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-60-40')}
        >
          <p className="size-title">60 X 40 mm</p>

          <div className="card-body">
            <img src={logo} alt="logo" />

            <div className="body-items">
              <p className="filial">FILIAL STANDART</p>
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '18px' }}></div> : '6 000'}
                </span>

                <div
                  className={
                    hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                  }
                >
                  {hidePrice ? (
                    ''
                  ) : (
                    <>
                      <span className="top">00</span>
                      <span className="bottom">UZS</span>
                    </>
                  )}
                </div>
              </div>

              {hideBarcode ? (
                <div style={{ height: '28px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  width={1.4}
                  height={hidePrice ? 40 : 20}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={12}
                  background="transparent"
                />
              )}

              <span className="time">{formattedDate}</span>
            </div>
          </div>
        </div>

        <div
          /* prettier-ignore */
          className={`card-58-30 ${activeCard === 'card-58-30' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-58-30')}
        >
          <p className="size-title">58 X 30 mm</p>

          <div className="card-body">
            <div className="body-items">
              <p className="filial">FILIAL STANDART</p>
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '12px' }}></div> : '6 000'}
                </span>

                <div
                  className={
                    hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                  }
                >
                  {hidePrice ? (
                    ''
                  ) : (
                    <>
                      <span className="top">00</span>
                      <span className="bottom">UZS</span>
                    </>
                  )}
                </div>
              </div>

              {hideBarcode ? (
                <div style={{ height: '22px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  height={hidePrice ? 40 : 18}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={10}
                  background="transparent"
                />
              )}

              <span className="time">{formattedDate}</span>
            </div>
          </div>
        </div>

        <div
          /* prettier-ignore */
          className={`card-40-30 ${activeCard === 'card-40-30' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-40-30')}
        >
          <p className="size-title">40 X 30 mm</p>

          <div className="card-body">
            <div className="body-items">
              <p className="filial">FILIAL STANDART</p>
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '12px' }}></div> : '6 000'}
                </span>

                <div
                  className={
                    hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                  }
                >
                  {hidePrice ? (
                    ''
                  ) : (
                    <>
                      <span className="top">00</span>
                      <span className="bottom">UZS</span>
                    </>
                  )}
                </div>
              </div>

              {hideBarcode ? (
                <div style={{ height: '24px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  width={1.3}
                  height={hidePrice ? 30 : 18}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={10}
                  background="transparent"
                />
              )}

              <span className="time">{formattedDate}</span>
            </div>
          </div>
        </div>

        <div
          /* prettier-ignore */
          className={`card-30-20 ${activeCard === 'card-30-20' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-30-20')}
        >
          <p className="size-title">30 X 20 mm</p>

          <div className="card-body">
            <div className="body-items">
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '12px' }}></div> : '6 000'}
                </span>

                <div
                  className={
                    hideBarcode ? 'cost-divider-changed' : 'cost-divider'
                  }
                >
                  {hidePrice ? (
                    ''
                  ) : (
                    <>
                      <span className="top">00</span>
                      <span className="bottom">UZS</span>
                    </>
                  )}
                </div>
              </div>

              {hideBarcode ? (
                <div style={{ height: '10px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  width={1}
                  height={hidePrice ? 20 : 10}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={8}
                  background="transparent"
                />
              )}
            </div>
          </div>
        </div>

        <div
          /* prettier-ignore */
          className={`card-line-58-30 ${activeCard === 'card-line-58-30' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-line-58-30')}
        >
          <p className="size-title">58 X 30 mm</p>

          <div className="card-body">
            <div className="body-items">
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '12px' }}></div> : '6 000'}
                </span>
                <span className={hideBarcode ? 'bottom-changed' : 'bottom'}>
                  {hidePrice ? '' : 'UZS'}
                </span>
              </div>

              {hideBarcode ? (
                <div style={{ height: '20px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  height={hidePrice ? 35 : 18}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={10}
                  background="transparent"
                />
              )}

              <span className="time">{formattedDate}</span>
            </div>
          </div>
        </div>

        <div
          /* prettier-ignore */
          className={`card-line-40-30 ${activeCard === 'card-line-40-30' ? 'active' : ''}`}
          onClick={() => handleCardClick('card-line-40-30')}
        >
          <p className="size-title">40 X 30 mm</p>

          <div className="card-body">
            <div className="body-items">
              <p className="title">Orbit Mint Fresh</p>

              <div className="cost-box">
                <span className={hideBarcode ? 'cost-changed' : 'cost'}>
                  {hidePrice ? <div style={{ height: '12px' }}></div> : '6 000'}
                </span>
                <span className={hideBarcode ? 'bottom-changed' : 'bottom'}>
                  {hidePrice ? '' : 'UZS'}
                </span>
              </div>

              {hideBarcode ? (
                <div style={{ height: '26px' }}></div>
              ) : (
                <Barcode
                  value="40099507"
                  width={1.3}
                  height={hidePrice ? 30 : 18}
                  format="CODE128"
                  displayValue={true}
                  font="monospace"
                  fontSize={10}
                  background="transparent"
                />
              )}

              <span className="time">{formattedDate}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PriceListCards;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import Button from 'components/base/Button';
import languageService from 'service/languages';
import { showAlert } from 'store/alert/alert.thunk';
import accountsService from 'service/account';

const options = [
  {
    value: true,
    label: 'Активный'
  },
  {
    value: false,
    label: 'Заблокирован'
  }
];

interface CreateAccountsProps {
  setOpenAccount: (show: boolean) => void;
  selectedAccountId?: any;
  refetch?: any;
  setSelectedAccountId?: any;
}

const CreateAccount = ({
  setOpenAccount,
  selectedAccountId,
  refetch,
  setSelectedAccountId
}: CreateAccountsProps) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //Get by id
  const fetchData = () => {
    if (selectedAccountId === '') return setLoading(false);

    accountsService
      .getAccountById(selectedAccountId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          status: res?.status ?? false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedAccountId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      status: data?.status === 'true' ? true : false
    };

    accountsService
      .createAccount(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Счета успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenAccount(false);
        setLoading(false);
        setSelectedAccountId('');
      });
  };

  const update = (data: any) => {
    setLoading(true);

    console.log('data', data);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedAccountId
    };
    accountsService.updateAccount(selectedAccountId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenAccount(false);
      setSelectedAccountId('');
    });
  };

  useEffect(() => {
    if (selectedAccountId !== '') {
      fetchData();
    }
  }, [langData, selectedAccountId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current, langData]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {langData &&
          langData.map((item: any, index: number) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Controller
                  name={`name_${item.char_code.toLowerCase()}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Floating className="md-4">
                      <Form.Control
                        {...field}
                        ref={index === 0 ? inputRef : undefined}
                        type="text"
                        placeholder={`Наименование счета ${item.char_code}`}
                        onFocus={e => e.target.select()}
                        isInvalid={
                          !!errors[`name_${item.char_code.toLowerCase()}`]
                        }
                      />
                      <label htmlFor="floatingInputCustom">
                        Наименование счета ( {item.char_code} )
                      </label>
                      {errors[`name_${item.char_code.toLowerCase()}`] && (
                        <Form.Control.Feedback type="invalid">
                          Обязательное поле
                        </Form.Control.Feedback>
                      )}
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">Состояние</label>
                    {errors?.status && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        Обязательное поле
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          Добавить
        </Button>
      </Form>
    </>
  );
};

export default CreateAccount;

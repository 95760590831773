/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from 'react-query';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import movingItemService from 'service/movingItem';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';

export type SearchDataType = {
  id: string;
  product_name: string;
  variation: string;
  barcode: string;
  units: string;
  total_price: string;
  remainder: string;
  date_order: string;
};

export const searchTableColumns: ColumnDef<SearchDataType>[] = [
  {
    accessorKey: 'product_name',
    header: 'Наименование товара',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'variation',
    header: 'Вариация',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'barcode',
    header: 'Штрих-код',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'units',
    header: 'Единица измерения',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },

  {
    accessorKey: 'remainder',
    header: 'Остаток',
    meta: {
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'date_order',
    header: 'Дата закупа',
    meta: {
      cellProps: { className: 'text-900' }
    }
  }
];

interface OpenSearchMovingTableProps {
  setOpenSearch: (show: boolean) => void;
  movingItemData?: any;
  setMovingItemData?: any;
  focusRef?: any;
  isAutoTab?: any;
}

const OpenSearchMovingTable = ({
  setOpenSearch,
  movingItemData,
  setMovingItemData,
  isAutoTab,
  focusRef
}: OpenSearchMovingTableProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [isProduct, setIsProduct] = useState(false);

  console.log('searchValue', searchValue);

  const { data } = useQuery(
    ['GET_PRODUCT', searchValue],
    () => {
      const res = movingItemService
        .getProduct({
          offset: 0,
          limit: 100,
          name: searchValue,
          list: true,
          is_excise: true
        })
        .then((res: any) => {
          setIsProduct(res?.is_product ?? false);

          return res?.products;
        });

      return res;
    },
    { enabled: !searchValue || searchValue.length > 2 }
  );

  const searchData: SearchDataType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          product_name:
            isProduct === true ? items?.name : items?.nomenclature?.name,

          barcode:
            isProduct === true ? items?.barcode : items?.nomenclature?.barcode,
          units: items?.nomenclature?.measure_unit?.name?.ru,
          variation: items?.nomenclature?.is_variation === true ? 'Да' : 'Нет',
          total_price: items?.price_whosale ?? 0,
          remainder: items?.quantity,
          date_order: new Date(items?.date_created)
            .toLocaleDateString('en-GB')
            .replace(/\//g, '-'),

          // this dataes need for static rendering dataes
          unit: items?.nomenclature?.measure_unit?.name?.ru,
          measure_unit_id: items?.nomenclature?.measure_unit?.id,
          amount: items?.quantity ? items?.quantity : 1,
          current_balance: items?.quantity ? items?.quantity : 1,
          currency_id: items?.currency_id,
          package_quantity: items?.package_quantity ?? 0,
          nomenclature_id: items?.nomenclature_id,
          product_parent_id: items?.id,
          product_id: items?.id,
          price_in: items?.price_in ?? 0,
          price: items?.price ?? 0,
          price_wholesale: items?.price_whosale ?? 0,
          quantity_fact: items?.quantity_fact ?? 0,
          is_vat: items?.is_vat ? items?.is_vat : false
        };
      }) ?? []
    );
  }, [data, searchValue]);

  const handleSelections = () => {
    const selectedDates = table
      .getSelectedRowModel()
      .rows.map(row => row.original);

    const updatedProductInvoiceData = [...movingItemData]; // Make a copy of the current state

    selectedDates.forEach(selectedDate => {
      const foundProductIndex = updatedProductInvoiceData.findIndex(
        item => item?.barcode === selectedDate.barcode
      );
      if (foundProductIndex !== -1) {
        updatedProductInvoiceData[foundProductIndex].amount += 1;
      } else {
        updatedProductInvoiceData.unshift(selectedDate);
      }
    });

    setMovingItemData(updatedProductInvoiceData);
    if (isAutoTab) {
      // when auto tab true focus to quantity
      setTimeout(() => {
        const firstProductIndex = 0;
        focusRef(`amount_${firstProductIndex}`);
      }, 500);
    }
    setOpenSearch(false);
  };

  const table = useAdvanceTable({
    data: searchData,
    columns: searchTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div>
          <SearchBox
            placeholder="Выведите наименования товара для поиска"
            onChange={e => setSearchValue(e?.target?.value)}
            className="mb-4"
          />
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />

          <div className="d-flex justify-content-end">
            <Button
              className="mt-4"
              variant="primary"
              onClick={() => handleSelections()}
            >
              Выбрать
            </Button>
          </div>
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default OpenSearchMovingTable;

// import FeatherIcon from 'feather-icons-react';
// import NineDotMenu from './NineDotMenu';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import ThemeToggler from 'components/common/ThemeToggler';
// import NotificationDropdownMenu from './NotificationDropdownMenu';
// import { useState } from 'react';
// import DropdownSearchBox from 'components/common/DropdownSearchBox';
// import SearchResult from 'components/common/SearchResult';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Dropdown, Nav, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import ProfileDropdownMenu from './ProfileDropdownMenu';
import Avatar from 'components/base/Avatar';
import avatar from 'assets/img/icons/avatarImg.png';
import Button from 'components/base/Button';
import { shiftIdActions } from 'store/shiftId/shiftId.slice';

import CreateCashBox from './CreateCashBox';

const NavItemsSlim = () => {
  const [openModal, setOpenModal] = useState(false);

  const shiftId = useSelector((state: any) => state?.shiftId?.shiftId);
  const userId = useSelector((state: any) => state?.auth?.user?.id);
  const dispatch = useDispatch();
  const socketRef = useRef<WebSocket | null>(null);
  const reconnectRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const connectWebSocket = useCallback(() => {
    if (!userId || socketRef.current) return;

    const socket = new WebSocket(
      `${process.env.REACT_APP_URL_WEBSOCET}/ws/${userId}`
    );
    socketRef.current = socket;

    socket.addEventListener('open', () => {
      console.log('WebSocket is connected.');
    });

    socket.addEventListener('message', event => {
      try {
        const jsonResponse = JSON.parse(event.data);

        if (jsonResponse.event === 'CASHBOX') {
          const { open, close, id } = jsonResponse.data;
          console.log(
            `Cashbox event received: open=${open}, close=${close}, id=${id}`
          );

          if (open) {
            dispatch(shiftIdActions.setShiftId(id));
            console.log('Cashbox is open.');
          }

          if (close) {
            dispatch(shiftIdActions.setShiftId(''));
            console.log('Cashbox is closed.');
          }
        } else {
          console.log('Unknown event:', jsonResponse.event);
        }
      } catch (error) {
        console.error('Error parsing JSON response:', error);
      }
    });

    socket.addEventListener('close', () => {
      console.log('WebSocket is closed. Reconnecting...');
      socketRef.current = null;
      reconnectRef.current = setTimeout(connectWebSocket, 1000); // Reconnect after 1 second
    });

    socket.addEventListener('error', error => {
      console.error('WebSocket error:', error);
    });
  }, [userId, dispatch]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      if (reconnectRef.current) {
        clearTimeout(reconnectRef.current);
      }
    };
  }, [connectWebSocket]);

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      {/* <Nav.Item>
        <ThemeToggler slim />
      </Nav.Item> */}
      {/* <Nav.Item>
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={12} />
        </Nav.Link>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <FeatherIcon icon="bell" size={12} />
          </Dropdown.Toggle>
          <NotificationDropdownMenu />
        </Dropdown>
      </Nav.Item> */}
      {/* <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link py-0"
            variant=""
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="2" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="2" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="8" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="14" r="2" fill="currentColor"></circle>
              <circle cx="8" cy="2" r="2" fill="currentColor"></circle>
              <circle cx="14" cy="2" r="2" fill="currentColor"></circle>
            </svg>
          </Dropdown.Toggle>
          <NineDotMenu />
        </Dropdown>
      </Nav.Item> */}
      <Nav.Item className="me-3">
        <Button
          variant={shiftId === '' ? 'success' : 'danger'}
          onClick={() => setOpenModal(true)}
        >
          {shiftId === '' ? 'Открыть Смену' : 'Закрыть Смену'}
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0"
            variant=""
          >
            <Avatar src={avatar} size="xl" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {shiftId === '' ? 'Открыть Смену' : 'Закрыть Смену'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCashBox setOpenModal={setOpenModal} />
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15"
      >
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}
          >
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default NavItemsSlim;

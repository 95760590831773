/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const productKindService = {
  getList: (params: any) => request.get('/guidebook/product-kind', { params }),
  getProductKindById: (id: any) => request.get(`/guidebook/product-kind/${id}`),
  createProductKind: (data: any) =>
    request.post('/guidebook/product-kind', data),
  deleteProductKind: (id: any) =>
    request.delete(`/guidebook/product-kind/${id}`),
  updateProductKind: (id: any, data: any) =>
    request.put(`/guidebook/product-kind/${id}`, data)
};

export default productKindService;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  first_name: '',
  last_name: '',
  password: '',
  repeat_password: '',
  languages: '',
  gender: ''
};

export const { actions: registerActions, reducer: registerReducer } =
  createSlice({
    name: 'register',
    initialState,
    reducers: {
      setRegisterData: (state, { payload }) => {
        state.first_name = payload.first_name;
        state.last_name = payload.last_name;
        state.password = payload.password;
        state.repeat_password = payload.repeat_password;
        state.languages = payload.languages;
        state.gender = payload.gender;
      },
      clearRegister: () => initialState
    }
  });
